function LoginSocialMediaService($rootScope, $q, $http, LoginSocialMediaFactory, Facebook, YahooServices, GooglePlus, InstagramService) {
	this.res = {};
	var self = this;

	return {
		facebook_login: function(facebook_email, country_id, promotional_newsletter, newAccount)
		{
     		var authDetail;
			return Facebook.getUser().then(function(response){

				if(facebook_email != null ) {
					response.users_detail.email = facebook_email;
				}
        		authDetail = response.auth_detail;
				jQuery('.process-loading-background').removeClass('hidden');
				return Facebook.getUserPicture(response.users_detail.id).then(function(getPictureResponse) {
					if(response.connected) {
						return LoginSocialMediaFactory.login_social_media(response.users_detail.id, response.users_detail.email, response.users_detail.name, getPictureResponse,'facebook', country_id, promotional_newsletter, newAccount,response.users_detail.token_for_business).then(function(response){
              				if(response.status == 200) {
				                //extend token expiry
				                if (response.data.user == null)
				                	response.data.user = response.data.vendor;
				                // LoginSocialMediaFactory.extendToken(response.data.user.accountId, authDetail.accessToken);

				                if (angular.isDefined(response.data.user.socmed)) {
				                	angular.forEach(response.data.user.socmed.data, function(e){
				                		if (e.name == 'facebook') {
				                			e.accessToken = authDetail.accessToken;
				                		}
				                	})
				                }

                				return response.data.user;
							}
						}, function(error) {
							return error;
						});
					}
					else {
						jQuery('.process-loading-background').addClass('hidden');
						var modalInstance = $modal.open({
							templateUrl: 'modal-error-facebook-authorize.html',
							windowClass: 'modal-contact animated fadeIn zoomIn',
							controller: 'error_facebook_authorize',
						});
						angular.noop();
					}
				});
			}, function(error){
				return error;
			});
		},

		reconnect_facebook : function(accountId){
			return Facebook.getUser().then(function(response){
				// LoginSocialMediaFactory.extendToken(accountId, response.auth_detail.accessToken);
				return response.users_detail;
			});
		},

		google_login: function(google_full_name, country_id, promotional_newsletter, newAccount)
		{
			return GooglePlus.login().then(function(result) {
				if(result.status.signed_in) {
					jQuery('.process-loading-background').removeClass('hidden');
					return GooglePlus.getUser().then(function(user) {
						if(angular.isDefined(google_full_name)) {
							user.name = google_full_name;
						}

						return LoginSocialMediaFactory.login_social_media(user.id, user.email, user.name, user.picture, 'google', country_id, promotional_newsletter, newAccount).then(function(response){
							if(response.status == 200) {
								return response.data.user || response.data.vendor;
							}
						}, function(error) {
							return error;
						});
					});
				}
				else {
					jQuery('.process-loading-background').addClass('hidden');
					var modalInstance = $modal.open({
						templateUrl: 'modal-error-facebook-authorize.html',
						windowClass: 'modal-contact animated fadeIn zoomIn',
						controller: 'error_facebook_authorize',
					});
					angular.noop();
				}
			}, function (error) {
				return error;
			});
		},

		yahoo_login: function(yahoo_email, country_id, promotional_newsletter, newAccount)
		{
			OAuth.initialize('wvciRQM6wgiZSMDsaQ9QhX8zPrc');
			if(angular.isDefined(yahoo_email) && yahoo_email != '') {
				return LoginSocialMediaFactory.login_social_media(self.res.guid, yahoo_email, self.res.nickname, self.res.image.imageUrl, 'yahoo', country_id, promotional_newsletter, newAccount).then(function(response){
						if(response.status == 200) {
							return response.data.account;
						}
					}, function(error) {
						return error;
					});
			}
			else {
				return YahooServices.openOauth()
					.then( YahooServices.getUID )
					.then( YahooServices.loginUser )
					.then(function(result) {
						self.res = result.profile;
						return LoginSocialMediaFactory.login_social_media(result.profile.guid, '', result.profile.nickname, result.profile.image.imageUrl, 'yahoo', country_id, promotional_newsletter).then(function(response){
							if(response.status == 200) {
								return response.data.account;
							}
						}, function(error) {
							return error;
						});
					});
			}
		},

		instagram_login: function(instagram_email)
		{
			OAuth.initialize('wvciRQM6wgiZSMDsaQ9QhX8zPrc');
			return InstagramService.openOauth();
		}
	}
}

angular
	.module('bridestoryApp.newLoginApp')
	.service('LoginSocialMediaService', [ '$rootScope', '$q', '$http', 'LoginSocialMediaFactory', 'Facebook', 'YahooServices', 'GooglePlus', 'InstagramService', LoginSocialMediaService ]);