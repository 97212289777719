angular
    .module('bridestoryApp.decorative')
    .factory('videoEssentialFactory', ['localStorageService', function(localStorageService) {

        var _cookey = 'BS_TutorialVendorVideo';

        return {
            setIsVideoPlayed: function(val) {
                localStorageService.cookie.add(_cookey, val);
            },
            getIsVideoPlayed: function() {
                return localStorageService.cookie.get(_cookey) ? parseInt(localStorageService.cookie.get(_cookey)) : 0;
            },
        }
    }])
    .service('videoEssentialService', ['$modal', '$http', '$q', 'userManager', 'launchAppFactory', 'cookieServices', 'videoEssentialFactory', function($modal, $http, $q, userManager, launchAppFactory, cookieServices, videoEssentialFactory) {
        var opened = false;
        var modalInstance = null;
        var _isVideoPlayed = videoEssentialFactory.getIsVideoPlayed();

        var checkStatus = function() {
            var defer = $q.defer();
            if (userManager.isLogged() && userManager.isVendor()) {
                if (typeof isMobile == 'undefined') {
                    if (_isVideoPlayed) {
                        defer.reject('already played');
                    } else {
                        defer.resolve(true);
                    }
                } else {
                    defer.reject('mobile not have tutorial video');
                }

            } else {
                defer.reject('Current account is not a vendor');
            }
            return defer.promise;
        };


        angular.extend(this, {
            open: function() {
                var defer = $q.defer();
                var flag_open = true;

                if (!opened && flag_open == false && _isVideoPlayed == 0) {
                    if (launchAppFactory.getCommenceState()) {
                        checkStatus().then(function(data) {
                            opened = true;

                            modalInstance = $modal.open({
                                templateUrl: 'modal-play-video-vendor.html',
                                backdrop: 'static',
                                keyboard: false,
                                windowClass: 'popup modal-scrolly',
                                controller: function($scope, $modalInstance) {
                                    jQuery('.sticky-vendor-video').hide();
                                    return {
                                        skip: function() {
                                            $modalInstance.dismiss();
                                            jQuery('#videoVendorTutorialPopup').attr('src', '');
                                            if (_isVideoPlayed < 1) {
                                                jQuery('.sticky-vendor-video').fadeIn();
                                            }
                                        },
                                        close: function() {
                                            $modalInstance.close();
                                            jQuery('#videoVendorTutorialPopup').attr('src', '');
                                            if (_isVideoPlayed < 1) {
                                                jQuery('.sticky-vendor-video').fadeIn();
                                            }
                                        },
                                        playThisVideoPopup: function() {
                                            /*
                                            jQuery('iframe[src*=youtube]').each(function () {
                                                var originalSrc = jQuery(this).attr('src');
                                                jQuery(this).attr('src', originalSrc + '&amp;autoplay=1');
                                            });
                                            */
                                            var originalSrc = jQuery('#videoVendorTutorialPopup').attr('src');
                                            jQuery('#videoVendorTutorialPopup').attr('src', originalSrc + '&amp;autoplay=1');
                                            videoEssentialFactory.setIsVideoPlayed(1);
                                            jQuery('.sticky-vendor-video').remove();
                                        }
                                    }
                                },
                                controllerAs: 'playVideoCtrl'
                            })

                            modalInstance.opened.then(function() {
                                launchAppFactory.setOpenLaunchAppModal(0);
                                defer.resolve(true);
                            });

                            modalInstance.result.then(function() {
                                launchAppFactory.setOpenLaunchAppModal(0);
                                opened = false;
                            }, function() {
                                launchAppFactory.setOpenLaunchAppModal(0);
                                opened = false;
                            });
                        }, function(reason) {
                            defer.reject(reason);
                        });
                    } else {
                        defer.reject('already logged in');
                    }
                } else {
                    defer.resolve('already opened');
                }

                return defer.promise;
            }
        })
    }])
    .controller('videoEssentialController', ['$scope', '$modal', 'videoEssentialFactory', 'cookieServices', function($scope, $modal, videoEssentialFactory, cookieServices) {

        var _isVideoPlayed = videoEssentialFactory.getIsVideoPlayed();

        if (_isVideoPlayed >= 1) {
            jQuery('.sticky-vendor-video').hide();
        }
        this.playVideoEssential = function() {
            $modal.open({
                templateUrl: 'modal-play-video-vendor.html',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'popup modal-scrolly',
                controller: ['$scope', '$modalInstance', function($scope, $modalInstance) {
                    return {
                        skip: function() {
                            $modalInstance.dismiss();
                            if (_isVideoPlayed < 1) {
                                jQuery('.sticky-vendor-video').fadeIn();
                            }
                        },
                        close: function() {
                            $modalInstance.close();
                            if (_isVideoPlayed < 1) {
                                jQuery('.sticky-vendor-video').fadeIn();
                            }
                        },
                        playThisVideoPopup: function() {
                            /*
                            jQuery('iframe[src*=youtube]').each(function () {
                                var originalSrc = jQuery(this).attr('src');
                                jQuery(this).attr('src', originalSrc + '&amp;autoplay=1');
                            });
                            */
                            var originalSrc = jQuery('#videoVendorTutorialPopup').attr('src');
                            jQuery('#videoVendorTutorialPopup').attr('src', originalSrc + '&amp;autoplay=1');
                            videoEssentialFactory.setIsVideoPlayed(1);
                            jQuery('.sticky-vendor-video').remove();
                        }
                    }
                }],
                controllerAs: 'playVideoCtrl'
            })
        }
    }])
    .directive('stickyVideoEssential', ['$injector', 'videoEssentialFactory', function($injector, videoEssentialFactory) {
        return {
            restrict: 'AC',
            priority: 600,
            controller: 'videoEssentialController',
            link: function(scope, element, attrs, ctrl) {
                var _isVideoPlayed = videoEssentialFactory.getIsVideoPlayed();

                if (_isVideoPlayed >= 1) {
                    jQuery('.sticky-vendor-video').hide();
                }

                scope.close = function() {
                    videoEssentialFactory.setIsVideoPlayed(1);
                    element.fadeOut();
                }
                scope.playVideoEssential = function() {
                    element.fadeOut();
                    ctrl.playVideoEssential();
                }
            }
        }
    }])