function vendorSearchController($rootScope, $scope, vendorSearchByServices, cityCountryPopulateServices, vendorFilterServices, cookieServices) {
  $scope.cityLoad = false;
  $scope.userLocation = false;
  var self = this;

  this.init  = function (categoryId, cityId, budgetId, countryId) {
    $rootScope.categoryId = categoryId;
    $rootScope.cityId = cityId;
    $rootScope.budgetId = budgetId;
    $rootScope.countryId = countryId;
  };

  this.setCityId = function (cityId) {
    $rootScope.cityId = cityId;
  };

  this.removeParam = function(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  this.search = function () {
    if ($scope.isSearching) return false;
    $scope.isSearching = true;

    var countryId = self.countryId;
    if (self.countryId) {
      var countryIds = ('' + self.countryId).split('_');
      countryId = countryIds[1] || countryIds[0];
    }

    vendorSearchByServices.buildVendorSearchUrl(countryId, self.categoryId, self.cityId, self.budgetId).then(function (url) {
      cookieServices.removeCustomCookie('filter-cultural');
      window.location.href = self.removeParam('page', bridestory_redirect_url + '/' + url + location.search);
    });
  };

  this.refreshCity = function () {
    var countryIds = ('' + self.countryId).split('_');
    var countryId = countryIds[1] || countryIds[0];
    if (countryId) {
      vendorFilterServices.getCities(countryId).then(function (response) {
        $scope.cities = [];
        var getCities = response.vendorCities;
        for(var i = 0; i < getCities.length; i++){
          $scope.cities.push({
            name: getCities[i].name,
            id: getCities[i].id
          });
        }
        $scope.cityLoad = true;
      });
    } else {
      self.countryId = '';
      $scope.cities = [];
    }
  };

  $rootScope.$watch('countryId', function(n){
    self.countryId = n;
    if (n) self.refreshCity();
  });

  $rootScope.$watch('cityId', function(n){
    self.cityId = n;
  });

  $rootScope.$watch('categoryId', function(n){
    self.categoryId = n;
  });

  $rootScope.$watch('budgetId', function(n){
    self.budgetId = n;
  });

  $scope.$watch('cities', function(n, o){
    if (angular.isDefined(o)) {
      self.cityId = '';
      $rootScope.cityId = '';
    };
  }, true);

  $rootScope.$on('location.loaded', function(arg, data){
    if (Object.keys(data).length && !self.countryId) {
      $scope.userLocation = true;
      vendorFilterServices.getCities(data.id).then(function (response) {
        $scope.cities = [];
        var getCities = response.vendorCities;
        for(var i = 0; i < getCities.length; i++){
          $scope.cities.push({
            name: getCities[i].name,
            id: getCities[i].id
          });
        }
        $scope.cityLoad = true;
      });
    } else {
      $scope.userLocation = false;
    }
  });
}

angular.module('bridestoryApp.vendorSearchApp')
  .controller('vendorSearchController', ['$rootScope', '$scope', 'vendorSearchByServices', 'cityCountryPopulateServices', 'vendorFilterServices', 'cookieServices', vendorSearchController]);