angular
  .module('bridestoryApp.pageCounterApp')
  .factory('pageCounterFactory', ['localStorageService', '$rootScope', function (localStorageService, $rootScope) {
    
    var _cookey = 'BS_PageView',
      _pageView = localStorageService.cookie.get(_cookey) ? parseInt(localStorageService.cookie.get(_cookey), 10) : 0;

    return {
      count : function () {
        return _pageView;
      },
      addCount : function () {
        // if (localStorageService.cookie.add(_cookey, _pageView + 1)) {
        //   _pageView++;
        //   $rootScope.$broadcast('PageCounter.increased');
        //   return _pageView;
        // }
        // return false;
      },
      removeCount : function(){
        if(localStorageService.cookie.add(_cookey,null)) return true;
        return false;
      },
      setCount : function(number){
        if (localStorageService.cookie.set (_cookey, number)) {
          _pageView=number;
          $rootScope.$broadcast('PageCounter.increased');
          return _pageView;
        }
        return false;      
      }
    };
  }]);