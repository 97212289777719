'use strict';

angular
	.module('bridestoryApp.newLoginApp', ['ui.bootstrap', 'googleplus', 'bridestoryApp.analyticApp', 'bridestoryApp.geoLocationApp','bridestoryApp.launchApp'])
	.config(['GooglePlusProvider', function(GooglePlusProvider)
	{
		GooglePlusProvider.init({
			clientId: Bridestory.google_clientId,
			apiKey: Bridestory.google_apiKey,
			scopes: "https://www.googleapis.com/auth/userinfo.email"
		});
	}]);