function cityCountryPopulateServices($http, $q, resourceManager) {
	var _countries = [];
    this.refreshCity = function (countryId) {
    	return resourceManager.get('/vendor_services/all_cities?country_id=:countryId',{
	      countryId : countryId
	    },'v1','GET',true);
	};

	this.getCountry = function (cityId) {
		return $http.get(Bridestory.api + '/v1/vendor_cities/' + cityId);
	};

	this.getCitiesByCountryId = function (countryId) {
		return $http.get(Bridestory.api + '/v1/vendor_cities/find/' + countryId);
	};

	this.searchCitiesByCountry = function (countryIso, search) {
		return $http.get(Bridestory.api + '/v1/vendor_cities/search?country=' + countryIso +'&keyword=' + search);
	};

	this.getCountries = function() {
	  	return $http.get(Bridestory.api + '/v1/country');
	};
	
}

angular
    .module('bridestoryApp.cityCountryPopulateApp')
    .service('cityCountryPopulateServices', [ '$http', '$q', 'resourceManager', cityCountryPopulateServices ]);