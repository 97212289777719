function notificationService($modal, $rootScope, $document, notificationMessage, $http, cookieServices)
{
  var self = this;
  this.meta = {};
  this.message = notificationMessage;

  var body = $document.find('body').eq(0);
  var _notification = {
    instance : null,
    status : {
      error : 'error',
      success : 'success'
    },
    current : {
      message : null,
      messagetype : undefined,
      opened : false
    },
    open : function(message,messagetype){
      _notification.current.message = message;
      _notification.current.messagetype = messagetype || undefined;
      _notification.instance = $modal.open({
        templateUrl: 'notification-alert.html',
        controller: 'warningAlertController',
        windowClass : 'notification-modal-csscancel modal-nobackground',
        backdrop : false,
        resolve : {
          message : function(){
            return _notification.current.message;
          },
          status : function(){
            return _notification.status;
          },
          messagetype : function(){
            return _notification.current.messagetype
          },
          autohide : function(){
            return true;
          },
          yesButtonText : function(){
            return;
          },
          noButtonText : function(){
            return;
          },
          callback : function(){
            return;
          },
          title : function(){
            return;
          }
        }
      });

      _notification.instance.opened.then(function(){
        _notification.current.opened = true;
        body.addClass('modal-open-notification');
      })

      _notification.instance.result.then(function(callback){
        if(callback && typeof callback == 'function') callback();
        _notification.current.opened = false;
        setTimeout(function(){
          body.removeClass('modal-open-notification');
        },90000)
      },function(){
        setTimeout(function(){
          body.removeClass('modal-open-notification');
        },900000)
      })

    }
  }

  this.type = {
    ERROR : _notification.status.error,
    SUCCESS : _notification.status.success
  }

  //to use notify error class : service.topbarNotify('message',service.type.ERROR);
  //or simply service.topbarNotify('message','error') -> as long as the keyword doesn't change
  this.topbarNotify = function(message,messagetype) {
    if(!_notification.current.opened) {
      if(message == _notification.current.message && messagetype == _notification.current.messagetype && _notification.current.opened) {
        _notification.instance.close(function(){
          _notification.open(message,messagetype);
        });
      } else {
        _notification.open(message,messagetype);
      }
    } else {
      _notification.open(message,messagetype)
    }
  }

  this.notify = function(message, error)
  {
    notify({
      message: message,
      classes: error ? 'icon-exclamation-error' : 'icon-exclamation-success',
      templateUrl: 'notification-message.html',
      position: 'center'
    });
  }

  this.getMeta = function() {
      return self.meta;
  };

  this.refreshMeta = function() {
    self.meta = {};
    $http.get(Bridestory.api + '/v2/vendors/meta', {
      headers : {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Session-Id': cookieServices.getCookie('BSID')
      }
    }).then(function(result){
      self.meta = result.data;
    });
  }

  this.warning = function(format, templateName) {
    if (typeof(templateName) == "undefined")
      templateName = 'modal-warning-alert.html';

    //message, yesButtonText, noButtonText, yesCallback, title
    return $modal.open({
      templateUrl: templateName,
      controller: ['$scope', '$modalInstance', '$timeout', 'title', 'message', 'additionalMessage', 'yesButtonText', 'noButtonText', 'type', function($scope, $modalInstance, $timeout,title,message,additionalMessage,yesButtonText,noButtonText,type){

        return {
          title : title,
          message : message,
          additionalMessage : additionalMessage,
          yesButtonText : yesButtonText,
          noButtonText : noButtonText,
          type : type,
          close : function(){
            $modalInstance.dismiss('No button clicked!');
          },
          proceed : function(){
            $modalInstance.close('Yes button clicked!');
          },
          dismiss : function(){
            $modalInstance.dismiss('Close button clicked!');
          }
        }
      }],
      controllerAs : 'alertCtrl',
      windowClass : 'popup',
      resolve : {
        title : function(){
          return format.title || format.yesButtonText || '';
        },
        message : function(){
          return format.message; //REQUIRED
        },
        additionalMessage : function(){
          return format.additionalMessage || false; // OPTIONAL
        },
        yesButtonText : function(){
          return format.yesButtonText || 'Yes'; //OPTIONAL
        },
        noButtonText : function(){
          return format.noButtonText || 'No'; //OPTIONAL
        },
        type : function(){
          return format.type || 'YESNO'; //OPTIONAL 'YES' or 'YESNO'
        }
      }
    });
  }
}

angular.module('bridestoryApp.notificationApp')
  .service('notificationService', ['$modal', '$rootScope','$document', 'notificationMessage', '$http', 'cookieServices', notificationService]);
