setTimeout(function(){ 
  var hashtag = window.location.hash;
  var targetscroll = hashtag.replace("/", "");

  if(targetscroll.length){
    $('body,html').animate({
        scrollTop: $(targetscroll).offset().top
    });
  }
}, 1000);


$(".bs-helpcenter").click(function() {        
  $(".help-box").toggle();           
  $(".help-box").addClass("block");   
  $(".bs-helpcenter").toggleClass("active", 1000);      
  $(".submit-box").hide();     
  $(".bs-submit-sticky").removeClass("active");  
});

$(".bs-submit-sticky").click(function() {        
  $(".submit-box").toggle();           
  $(".submit-box").addClass("block");   
  $(".bs-submit-sticky").toggleClass("active", 1000);      
  $(".help-box").hide();   
  $(".bs-helpcenter").removeClass("active");  
});