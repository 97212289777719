'use strict';

angular
    .module('bridestoryApp.vendorSearchApp', [])
    .provider("search", function () {
      var settings = {};

      settings.locale = true;

      this.setLocale = function (value) {
        settings.locale = value;
      };

      this.localeEnabled = function () {
        return settings.locale;
      };

      this.setFilters = function(param, value){
        settings.filters[params] = value;
      };

      this.getFilter = function(params){
        return settings.filters[params] || null
      };

      this.$get = [function () {
        return settings;
      }];
    })
    .run(['$rootScope', 'GeoLocationService', 'vendorFilterServices', 'search', function ($rootScope, GeoLocationService, vendorFilterServices, search) {
      var location = {};

      if(search.locale) {
  	  	vendorFilterServices.getAvailableCountries()
  		  	.then(function(countries ){
  		  		var found = false;
  		  		angular.forEach(countries.data.country, function(country) {
  		  			if (!found && country.iso == Bridestory.localized_bucket) {
  		  				angular.extend(location, country);
  		  				found = true;
  		  			};
  		  		});

  		  		if (!found) {
  		  			location = {};
  		  		}

  		  		$rootScope.$emit("location.loaded", location);
  		  	});
      }
    }])
    .directive('addUserLocation', ['$rootScope', '$document', 'search', function($root, $document, search) {
    	return {
    		restrict: 'A',
    		link: ['$scope', '$element', '$attr', function ($scope, $element, $attr) {
    			var createOption = function (value, text){
    				var opt = document.createElement('option');
            opt.value = value;
            opt.innerHTML = text;
            return opt;
    			};

      		$root.$on('location.loaded', function(args, data) {
            if(search.locale){
              if (Object.keys(data).length && !$root.countryId) {
                $('#'+$attr.id).prepend(createOption('_' + data.id, data.nicename));
                $('#'+$attr.id).val('_' + data.id);

                $root.countryId = '' + data.id;
              }
            }
      		});
    		}]
    	};
    }]);
