angular
	.module('bridestoryApp.launchApp')
	.service('valPromoService', ['$modal', '$http', '$q', 'userManager', 'launchAppFactory', 'cookieServices', function($modal, $http, $q, userManager, launchAppFactory, cookieServices){
		var opened = false;
		var modalInstance = null;

		var checkStatus = function(){
			var defer = $q.defer();
			if (userManager.isLogged() && userManager.isVendor()) {
				validateVendor().then(function(result){
					if (userManager.origin().iso != 'SG') {
						defer.resolve(true);
					} else {
						defer.reject('');
					}
				}, function(reason){
					defer.reject(reason);
				});
			} else {
				defer.reject('Current account is not a vendor');
			}

			return defer.promise;
		};

		var validDateRange = function(){
			var valid = false;
			var currentDate = new Date();

			if( currentDate <= new Date(Bridestory.val_promo_end) && currentDate >= new Date(Bridestory.val_promo_start)){
				valid = true
			}

			return valid;
		}

		var validateVendor = function(){
			var defer = $q.defer();

			$http({
				method  : 'GET',
				url     : Bridestory.api + '/v1/vendors/meta',
				headers : {
					'Session-Id': cookieServices.getCookie('BSID')
				}
			})
			.then(function(result){
				if(result.status === 200) {
					if (result.data.contactCredit > 1000 && result.data.vendor.status == 1 && result.data.activeMembershipCount <= 0) {
						defer.resolve(true);
					} else {
						defer.reject('Not a valid vendor');
					}
				} else {
					defer.reject('Failed to retrieve vendor data')
				}
			},function(result){
				defer.reject(result)
			});

			return defer.promise;
		}

		angular.extend(this, {
			open: function(){
				var valPromoTempalteUrl = Bridestory.default_url + '/popup/val-promo';
				if (location.protocol === 'https:') {
				    valPromoTempalteUrl = valPromoTempalteUrl.replace(/^http:\/\//i, 'https://');
				}

				var defer = $q.defer();
				if (launchAppFactory.getCommenceState()) {
					if(validDateRange()){
						checkStatus().then(function(data){
							if (!opened) {
								opened = true;
								modalInstance = $modal.open({
									templateUrl : valPromoTempalteUrl,
									windowClass : 'popup modal-scrolly popup-launchapp',
								});

								modalInstance.opened.then(function() {
									launchAppFactory.setOpenLaunchAppModal(0);
									defer.resolve(true);
							    });

							    modalInstance.result.then(function() {
							    	launchAppFactory.setOpenLaunchAppModal(0);
							    	opened = false;
							    }, function(){
							    	launchAppFactory.setOpenLaunchAppModal(0);
							    	opened = false;
							    });
							} else {
								defer.resolve('already opened');
							}
						}, function(reason){
							defer.reject(reason);
						});
					} else {
						defer.reject('Date exceeded limit of val promo');
					}
				} else {
					defer.reject('already logged in');
				}

				return defer.promise;
			}
		})
	}]);
