'use strict';

angular
	.module('bridestoryApp.menuApp')
	.controller('SearchVendorController', ['$rootScope', '$filter', 'autoCompleteServices', 'cookieServices',function($rootScope, $filter, autoCompleteServices, cookieServices){
		return {
			selected : null,
			searchVendor : function (name) {
				$rootScope.$broadcast('typeahead.city', false);
				return autoCompleteServices.searchVendor(name).then(function(result) {
					angular.forEach(result, function (item) {
						if (item.photo) {
							item.photo = $filter('imageStyle')(item.photo, 'search_vendor');
						}
					});
					return result;
				});
			},
			onSelectVendor : function (item, model, label, element, keywords, form) {
				if (angular.isDefined(item)) {
					this.selected = label;
					window.location.href = item.url;
				} else {
					form.submit();
				}
		    return false;
			}
		};
	}])
  .controller('MenuController', ['$scope', '$rootScope', '$modal', '$location', 'userManager', 'launchAppService', 'videoEssentialService', 'masterclassPromo', 'monetizeModal', 'cookieServices', 'launchAppFactory', 'LoginService', 'notificationService', 'vendormetaManager', 'resourceManager', 'MenuService', 'localStorageService', function($scope, $rootScope, $modal, $location, userManager, launchAppService, videoEssentialService, masterclassPromo, monetizeModal, cookieServices, launchAppFactory, LoginService, notificationService, vendormetaManager, resourceManager, MenuService, localStorageService) {
        $scope.searchClicked=false;

		var _membership = null,
			_trialVendor = false,
			_secondaryActive = false,
			_goldMembership = false,
			_unreadMessages = null,
			_bridestoryPoints = null;


		$scope.openPopup = function(){
			$scope.modalInstance = $modal.open({
				templateUrl : 'modal-language-selector.html',
                controller : 'MenuController',
				controllerAs : 'menuCtrl',
				backdrop : 'static',
				keyboard: false,
				windowClass : 'popup modal-scrolly'
			});
		}

		//Language Menu
		setTimeout(function() {
			$('.language-bar > ul > li:first-child').css({'cursor' : 'default'});

			$('.language-menu').click(function(e) {
			   $('.language-bar').toggle();
			});

			$(document).click(function (e) {
			   if(!$(e.target).is('.language-menu, .language-menu *')){
				   $('.language-bar').hide();
			   }else if($(e.target).is('.language-bar ul li:first-child') || $(e.target).is('.language-menu, .language-menu *')){
				   $('.language-bar').show();
			   }
			   /*else if($(e.target).is('.language-bar, .language-bar *')){
				   $('.language-bar').show();
			   }*/
		   	});
		}, 100);

		userManager.promise.then(function(){
			if ((userManager.isMonetized()) && (window.location.href.indexOf("dashboard") > -1)
				&& (window.location.pathname.indexOf('/dashboard/subscription-plan') == -1)
				&& (window.location.pathname.indexOf('/dashboard/checkout') == -1)
				&& (window.location.pathname.indexOf('/dashboard/premium-addon') == -1)
				&& (window.location.pathname.indexOf('/dashboard/transaction-history') == -1)
				&& (window.location.pathname.indexOf('/dashboard/invoice') == -1)
				&& (window.location.pathname.indexOf('/dashboard/confirm-payment') == -1)) {
				launchAppFactory.setOpenMonetizeModal(1);
				monetizeModal.init();
			} else {
				masterclassPromo.open().then(angular.noop(), function(){
					videoEssentialService.open().then(angular.noop(), function(reason){
						if(userManager.isVendor() && typeof isMobile !== 'undefined' && isMobile.any()) launchAppService.init();
					});
				});
			}


			var _uuid = userManager.uuid();

			if(userManager.isLogged()!=null){
				vendormetaManager.promise.then(function(){
					vendormetaManager.detail().promise.then(function(result){
						vendormetaManager.isAllowedAccessEvent = vendormetaManager.detail().vendorEvent();
						vendormetaManager.vendor = result.vendor;
						vendormetaManager.memberships = result.vendor.memberships.data;
						vendormetaManager.mainService = result.vendor.mainService.data;

						var _id = result.vendor.accountId;

						//Handle When Masquerade Vendor
						if(!cookieServices.getCookie('BS.SERVER.logDetail')) {
							var _vendorData = {
							  isVendor: true,
							  id: result.vendor.id,
							  isPublished: result.vendor.status == 1 ? true : false
							}

							cookieServices.setCustomCookie('BS.SERVER.logDetail', JSON.stringify(_vendorData), '365 days');
						}

						if(!cookieServices.getCookie('BS.isMasquerade')) {
							MenuService.updateLastSeen(_uuid, _id).then(function(response) {
								if(response.status != 200) {
									console.log("Something error. Try again.");
								}
							});
						}
						//END Handle When Masquerade Vendor
					})
				})
			}

            MenuService.counterMessageNotification(_uuid).then(function(response) {
                if(response.status == 200) {
                	var unreadMsg = response.data.data.unreadCount ? response.data.data.unreadCount : 0;
                	var unreadMsgSpecialUser = response.data.data.specialUserUnreadCount ? response.data.data.specialUserUnreadCount : 0;
					var unreadMsgTokped = response.data.data.tokpedUnreadCount ? response.data.data.tokpedUnreadCount : 0;
					vendormetaManager.unreadMessages = unreadMsg + unreadMsgSpecialUser;
					vendormetaManager.unreadTokpedMessages = unreadMsgTokped;                }
            });

			MenuService.counterNotification(userManager.raw().accountable.accountId, cookieServices.getCookie('BSID')).then(function(response) {
				if(response.status == 200) {
					vendormetaManager.unreadNotification = response.data.data.unreadCount;
				}
			});

			MenuService.getBridestoryPoints(cookieServices.getCookie('BSID')).then(function(response) {
                if(response.status == 200) {
                    vendormetaManager.bridestoryPoints = response.data.data;
                }
            });

		});

		return {
			vendor : function(){
				return vendormetaManager;
			},
			trialVendor : function(){
				if(vendormetaManager.memberships != undefined){
					if(vendormetaManager.memberships.length == 1 && vendormetaManager.memberships[0].status == 'expired'){
						_trialVendor = true;
					}else{
						angular.forEach(vendormetaManager.memberships, function(membership){
							if(membership.membershipType && membership.membershipType.data.name == 'Free' && membership.vendorsServiceId == vendormetaManager.mainService.id){
								_trialVendor = true;
							}else{
								if(membership.status == 'active' && membership.membershipType && membership.membershipType.data.name != 'Free'){
									_trialVendor = false;
									if(membership.membershipType.data.name == 'Gold') _goldMembership = membership;
									else _membership = membership;

									_secondaryActive = true;
								}
							}
						})
					}

					return _secondaryActive ? false : _trialVendor;
				}
			},
			mainMembership : function(){
				return _goldMembership ? _goldMembership : _membership;
			},
			isLogged : function(){
				if (userManager.isLogged() != null) return userManager.isLogged();
			},
			isVerified : function(){
				return userManager.isVerified();
			},
			isVendor : function(){
				return userManager.isVendor();
			},
			isPublished : function(){
				return userManager.isPublished();
			},
			isMonetized : function(){
				return userManager.isMonetized();
			},
			isAllowedAccessEvent : function(){
				return vendormetaManager.isAllowedAccessEvent;
			},
			vendorUrl : function(){
				return userManager.vendorUrl();
			},
			vendorPreviewUrl : function(){
				return userManager.vendorPreviewUrl();
			},
            vendorId : function(){
                return userManager.id();
            },
			userName : function(){
				return userManager.fullName();
			},
			photo : function(){
				return userManager.photo();
			},
			slug : function(){
				return userManager.slug();
			},
			naviLogin : function (redirect) {
				if(redirect)
				{
					if (window.location.pathname != "/" && window.location.pathname != "/login" && window.location.pathname != "/register/vendor") {
						window.location = redirect_url + '/login' + '?redirect_uri=' + window.location.pathname + window.location.hash + window.location.search;
					} else {
						window.location = redirect_url + '/login';
					}
			  		return false;
				}else{

		  			LoginService.openLogin().then(function(result) {});
				}

			},
			naviLogout : function(){
				$rootScope.$broadcast("logout", {});
				launchAppFactory.removeOpenMonetizeModal();

                /*Remove BS.user after redirect from chat message*/
                localStorage.removeItem("BS.user");
                cookieServices.removeCustomCookie('BS.user');
                cookieServices.removeCustomCookie('BS.SERVER.logDetail');
                cookieServices.removeCustomCookie('floatChat');
			},
			getTraditional: function(event){

			    $rootScope.filterCultural = true;

			    cookieServices.setCustomCookie('filter-cultural', true, "1 hours");

			    if (location.pathname == '/' || location.pathname.search('/homestream') > -1) {
			    	event.preventDefault();
			    }

			},
			resetTraditional: function(event){

			    $rootScope.filterCultural = null;

			    cookieServices.removeCustomCookie('filter-cultural');
			},
			newMessageCount: function(){
				return vendormetaManager.unreadMessages > 99 ? '99+' : vendormetaManager.unreadMessages;
			},
			newTokpedMessageCount: function(){
				return vendormetaManager.unreadTokpedMessages > 99 ? '99+' : vendormetaManager.unreadTokpedMessages;
			},
			newNotifCount: function(){
				return vendormetaManager.unreadNotification > 99 ? '99+' : vendormetaManager.unreadNotification;
			},
			setPreferredLang: function(lang){
				cookieServices.setCustomCookie('BS_PreferredLang', lang, "365 days");
				window.location.reload();
			},
			bridestoryPoints: function(){
				return vendormetaManager.bridestoryPoints;
			},
			numberWithCommas: function(x) {
			    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		}

		/*$scope.$on("checkLogin", function (event, args) {
			self.changeMenu(args.data);
		});*/
	}])
	.directive('focusMe', ['$timeout', '$parse', function($timeout, $parse) {
			return {
				//scope: true,   // optionally create a child scope
				link: function(scope, element, attrs) {
					// var model = $parse(attrs.focusMe);
					// scope.$watch(model, function(value) {
					// 	console.log('watch focus me: ', value);
					//   if(value === true) {
					//     $timeout(function() {
					//       element[0].focus();
					//     });
					//   }
					// });

					scope.$watch(attrs.focusMe, function(value) {
						if(value === true) {
						  	$timeout(function() {
						    	element[0].focus();
						  	});
						}
					});

					// to address @blesh's comment, set attribute value to 'false'
					// on blur event:
					element.bind('blur', function() {
						scope.$apply(attrs.focusMe);
					// scope.$apply(model.assign(scope, false));
					});
				}
			};
		}]);
