function cookieServices(ipCookie) {
    var self = this;
    var defaultCookieDomain = '.bridestory.com';
    //if (Bridestory.app_env === 'local') defaultCookieDomain = null;

    this.getCookies = function () {
        var c = document.cookie, v = 0, cookies = {};
        if (document.cookie.match(/^\s*\$Version=(?:"1"|1);\s*(.*)/)) {
            c = RegExp.$1;
            v = 1;
        }
        if (v === 0) {
            c.split(/[,;]/).map(function(cookie) {
                var parts = cookie.split(/=/, 2),
                    name = decodeURIComponent(parts[0].trimLeft()),
                    value = parts.length > 1 ? decodeURIComponent(parts[1].trimRight()) : null;
                cookies[name] = value;
            });
        } else {
            c.match(/(?:^|\s+)([!#$%&'*+\-.0-9A-Z^`a-z|~]+)=([!#$%&'*+\-.0-9A-Z^`a-z|~]*|"(?:[\x20-\x7E\x80\xFF]|\\[\x00-\x7F])*")(?=\s*[,;]|$)/g).map(function($0, $1) {
                var name = $0,
                    value = $1.charAt(0) === '"'
                              ? $1.substr(1, -1).replace(/\\(.)/g, "$1")
                              : $1;
                cookies[name] = value;
            });
        }
        return cookies;
    };

    this.getCookie = function (name) {
        return self.getCookies()[name];
    };

    this.setCustomCookie = function(name, value, expiration) {
        ipCookie(name, value, { expirationUnit: expiration.split(" ")[1], expires: parseInt(expiration.split(" ")[0]), path: '/', domain : defaultCookieDomain });
    };

    this.setCookies = function (sid, cookieDomain) {
        var cookieSecureAttr = ''
        if(Bridestory.environment != 'local') {
            cookieSecureAttr = 'SameSite=Lax;Secure;'
        }

        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 157784760000;
        now.setTime(expireTime);
        document.cookie = 'BSID=' + sid + ';expires='+now.toUTCString()+';domain=.bridestory.com;path=/;' + cookieSecureAttr;
    };

    this.setLocalStorage = function(data){
        var user = {
            accountId : data.accountId,
            isVendor : data.type === "vendor" ? true : false
        }
        window.localStorage.setItem('BS.user',JSON.stringify(user));
        window.localStorage.setItem('BS.user.origin',JSON.stringify(data.countryOfOrigin.data));
    }

    this.getLocalStorage = function(){
        var user = JSON.parse(window.localStorage.getItem('BS.user'));

        if (user && user.sessionId){
            //pageCounterFactory.removeCount();
            self.setCookies(user.sessionId);
        }
        else 
            self.removeCookies();
    }

    this.setCookiesSecure = function (sid) {
        ipCookie('BSID', sid, { expirationUnit: 'hours', expires: 43800, path: '/', secure: true, domain : defaultCookieDomain  });
    };

    this.removeCookies = function () {
        ipCookie.remove('BSID', { path: '/', domain : defaultCookieDomain });
    };

    this.removeCustomCookie = function (name) {
        ipCookie.remove(name, { path: '/', domain : defaultCookieDomain  });
    };

    this.setNativeCookies = function(name, value, expirationDayInNumber) {
        if (!expirationDayInNumber)
            expirationDayInNumber = 0;

        var expirationDate = new Date(); 
        expirationDate.setDate(new Date().getDate() + parseInt(expirationDayInNumber));
        document.cookie = name + '=' + value + ';expires=' + expirationDate.toGMTString() + ';path=/;domain=' + defaultCookieDomain + ';';
    }
}

angular
    .module('bridestoryApp.cookiesApp')
    .service('cookieServices', [ 'ipCookie', cookieServices ]);
