'use strict';

angular.module('bridestoryApp', [
    'ipCookie',
    'bridestoryApp.resource',
    'bridestoryApp.analyticApp',
    'bridestoryApp.suddenPopupApp',
    'bridestoryApp.cookiesApp',
    'bridestoryApp.newLoginApp',
    'LocalStorageModule',
    'bridestoryApp.menuApp',
    'bridestoryApp.pageCounterApp',
    'bridestoryApp.vendorSearchApp',
    'bridestoryApp.cityCountryPopulateApp',
    'bridestoryApp.newLoginApp',
    'bridestoryApp.notificationApp',
    'bridestoryApp.accountMetaApp',
    'bridestoryApp.filters',
    'bridestoryApp.decorative'
])

.config(['$interpolateProvider', '$locationProvider', function($interpolateProvider, $locationProvider) {
    $interpolateProvider.startSymbol('[[');
    $interpolateProvider.endSymbol(']]');
}])
.run(['AnalyticService', function (AnalyticService) {
  AnalyticService.pageTrack('GA');
}]);