function LoginAuthController(section, $rootScope, $scope, $modalInstance, $timeout, $location, $window, $q, LoginService, LoginSocialMediaService, AnalyticService, CountryServices, GeoLocationService,notificationService, userManager,$sce,origin, cookieServices, vendormetaManager, MenuService, resourceManager) {

	this.selection          = 'login';

	this.is_loading         = false;
	this.login_error        = false;
	this.error_no_email     = false;
	this.error_wrong_email  = false;
	this.password_get_error = false;
	this.register_error     = false;
	this.email_message      = '';
	this.password_message   = '';
	this.forgot_message     = '';
	this.error_message      = [];
	this.account 			= {};
  	$scope.newAccount       = 1;


  	//customize force login copy
  	this.subtitle = notificationService.message.LOGIN_CREATE_ACCOUNT_TITLE;
  	if(angular.isDefined(origin)) {

  		if (origin == "inspiration"){
  			this.title = notificationService.message.LOGIN_INSPIRATION_TITLE;
  			this.subtitle = notificationService.message.LOGIN_INSPIRSTION_SUBTITLE;

  			this.titleMobile = notificationService.message.LOGIN_INSPIRATION_TITLE_MOBILE;
  			this.subtitleMobile = notificationService.message.LOGIN_INSPIRSTION_SUBTITLE_MOBILE;
  		}
  		else if (origin == "comment"){
  			this.title = notificationService.message.LOGIN_COMMENT_TITLE;

  			this.titleMobile = notificationService.message.LOGIN_COMMENT_TITLE_MOBILE;
  			this.subtitleMobile = notificationService.message.LOGIN_COMMENT_SUBTITLE_MOBILE;
  		}
  		else if (origin == "save"){
  			this.title = notificationService.message.LOGIN_SAVE_TITLE;

  			this.titleMobile = notificationService.message.LOGIN_SAVE_TITLE_MOBILE;
  			this.subtitleMobile = notificationService.message.LOGIN_SAVE_SUBTITLE_MOBILE;
  		}
  		else if (origin == "message"){
  			this.title = notificationService.message.LOGIN_MESSAGE_TITLE;

  			this.titleMobile = notificationService.message.LOGIN_MESSAGE_TITLE_MOBILE;
  			this.subtitleMobile = notificationService.message.LOGIN_MESSAGE_SUBTITLE_MOBILE;
  		}
  		else if (origin == "leads"){
  			this.title = notificationService.message.LOGIN_LEADS_TITLE;

  			this.titleMobile = notificationService.message.LOGIN_LEADS_TITLE_MOBILE;
  			this.subtitleMobile = notificationService.message.LOGIN_LEADS_SUBTITLE_MOBILE;
  		}
  		else if (origin == "review"){
  			this.title = notificationService.message.LOGIN_REVIEW_TITLE;

  			this.titleMobile = notificationService.message.LOGIN_REVIEW_TITLE_MOBILE;
  			this.subtitleMobile = notificationService.message.LOGIN_REVIEW_SUBTITLE_MOBILE;
  		}
  		else if (origin == "askForPricelist"){
  			this.title = notificationService.message.LOGIN_PRICELIST_TITLE;

  			this.titleMobile = notificationService.message.LOGIN_PRICELIST_TITLE_MOBILE;
  			this.subtitleMobile = notificationService.message.LOGIN_PRICELIST_SUBTITLE_MOBILE;
  		}
  	}
  	else{
  		this.title = notificationService.message.LOGIN_ELSE_TITLE;

  		this.titleMobile = notificationService.message.LOGIN_ELSE_TITLE_MOBILE;
  		this.subtitleMobile = notificationService.message.LOGIN_ELSE_SUBTITLE_MOBILE;
  	}



	this.login              = {email:'', password:''};
	this.facebook           = {email:''};
	this.google             = {email:''};
	this.forgot             = {email:''};

	this.login_submitted    = false;
	this.register_submitted = false;
	this.forgot_submitted   = false;
  	this.term_condition           = false;

	if(Bridestory.localized_bucket == 'SG'){
		this.promotional_newsletter   = false;
	}else{
		this.promotional_newsletter   = true;
	}

	var self = this;

	$scope.myLocation = {};
	$scope.country = {};
	CountryServices.then(function(result) {
		getMyLocation(function(myLocation){
		  angular.forEach(result.data.country, function(value, key) {
		    if($scope.myLocation.country == value.iso)
		      $scope.country = value;
		  });
		});
	});

	var getMyLocation = function (successCallback){
		GeoLocationService.then(function(result) {
		  var myLocation = result;
		  $scope.myLocation = myLocation;
		  if(successCallback) successCallback(myLocation);
		});
	};

    this.trackButtonApply = function(){
        AnalyticService.eventTrack('Vendor_Registration', {'category' : 'business_site_action', 'label' : 'registration_apply_now_for_free'});
    }
    
	this.clear = function () {
        self.login.email = '';
    };

	this.change_section = function(section) {
		self.selection = section;
	    AnalyticService.pageTrackVirtual(section, 'GA');

		if(section == 'forgot') {
			$rootScope.forgot = true;
		}else if(section == 'register') {
			$rootScope.register = true;
		}
	}

	if(angular.isDefined(section)) {
		self.change_section(section);
	}

	this.isEmailAddress = function (email) {
		var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
	    var str = email;
	    return str.match(pattern);
	}

	this.updateHeader = function(){
		vendormetaManager.refresh().then(function(vendorMeta){
			new resourceManager.skeleton({
				API : '/vendors/' + userManager.slug() + '?attrType=slug&include=memberships,services,countryOfOrigin,mainService',
				apiVersion : 'v2',
				success : function(result) {
					vendormetaManager.memberships = result.vendor.memberships.data;
					vendormetaManager.mainService = result.vendor.mainService.data;
					vendormetaManager.vendor = result.vendor;

					angular.forEach(result.vendor.memberships.data,function(value,key){
						if(result.vendor.countryOfOrigin.data.id == 192 && value.status == 'active' && value.membershipType.data.name == 'Gold' && value.service.data.category.data.name == 'Venue')
							vendormetaManager.isAllowedAccessEvent = true;
					});
					cookieServices.setLocalStorage(result.vendor);
				}
			})
		});

		MenuService.counterMessageNotification(self.account.account.data.uuid).then(function(response) {
          if(response.status == 200) {
			var unreadMsg = response.data.data.unreadCount ? response.data.data.unreadCount : 0;
			var unreadMsgSpecialUser = response.data.data.specialUserUnreadCount ? response.data.data.specialUserUnreadCount : 0;
			var unreadMsgTokped = response.data.data.tokpedUnreadCount ? response.data.data.tokpedUnreadCount : 0;
			vendormetaManager.unreadMessages = unreadMsg + unreadMsgSpecialUser;
			vendormetaManager.unreadTokpedMessages = unreadMsgTokped;
          }
        });

		MenuService.counterNotification(self.account.accountId, cookieServices.getCookie('BSID')).then(function(response) {
			if(response.status == 200) {
				vendormetaManager.unreadNotification = response.data.data.unreadCount;
			}
		});

        MenuService.getBridestoryPoints(cookieServices.getCookie('BSID')).then(function(response) {
          if(response.status == 200) {
              vendormetaManager.bridestoryPoints = response.data.data;
          }
        });
	}

	this.login_register = function(form_login) {
		self.error_wrong_email = false;
		self.error_no_email = false;
		self.error_password_to_short = false;
		self.error_email_pass_not_match = false;
		self.password_get_error = false;
		self.error_wrong_password = false;


		if(form_login.email.$error.required) {
			self.error_no_email = true;
		}
		else if (form_login.email.$error.email) {
			self.error_wrong_email = true;
		}
		else if(form_login.password.$error.minlength || form_login.password.$error.required) {
			self.error_password_to_short = true;
		}
		else if(form_login.$valid) {
			if(self.isEmailAddress(self.login.email)) {
				self.is_loading = true;
				jQuery('.process-loading-background').removeClass('hidden');
				var loginAttributes = {
		          'email'    : self.login.email,
		          'password' : self.login.password
		        };
				LoginService.login(loginAttributes).then(function(result) {
					if (!result) notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_NOT_VENDOR,'error');

					if (result.status === 401) {
						if(result.data.type == "AccountNotFoundException")
						{
							cookieServices.setCustomCookie('BS-EmailNotFound', { email: self.login.email, showNotif: true }," 1 minutes");
							window.location = '/register/vendor';
						}else
						{
							self.error_wrong_password = true;
	            			self.error_email_pass_not_match = true;
						}
					}
					else if (result.status === 200) {
		       			AnalyticService.eventTrack('Login_Register_Success', {'category' : 'login', 'label' : location.pathname});
		       			if (result.data.account.isVerified=='0'){
							self.change_section('verification_user');
							notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_NOT_VERIFIED,'error');
						} else {
							cookieServices.setCookies(result.data.account.sessionId);
							cookieServices.setLocalStorage(result.data.account);

							self.account = result.data.account;
							
							$rootScope.$broadcast("checkLogin", { data: result.data.account });
							$rootScope.$broadcast('login_popup');
							$modalInstance.close(result.data.account);

							self.updateHeader();

							if (!window.location.pathname.startsWith('/blog')) location.href = redirect_url + '/dashboard/essential';
						}
					}
					self.is_loading = false;
					jQuery('.process-loading-background').addClass('hidden');
				});
			} else {
				self.error_wrong_email = true;
			}
		}
	}

	this.process_register = function(form_register, redirect_to) {
		self.error_no_full_name = false;
    	self.error_term_condition_uncheck = false;
    	var redirect_uri = '/';

    	if(getParameterByName('redirect_uri') != '')
    		redirect_uri = getParameterByName('redirect_uri');
		if(form_register.full_name.$error.required) {
			self.error_no_full_name = true;
		}else if(angular.isDefined(form_register.term_condition) && !self.term_condition){
	    	self.error_term_condition_uncheck = true;
	    }else if(form_register.$valid) {
			var registerData = {
	        	'full_name'    				: self.login.full_name,
	        	'email'        				: self.save_email,
	        	'password'     				: self.save_password,
	       		'mobile_phone' 				: '',
	       		'country_id'   				: $scope.country.id,
        		'promotional_newsletter'  	: (self.promotional_newsletter) ? 1 : 0,
        		'redirect_uri'           	: redirect_uri
	     	};
			self.is_loading = true;
			jQuery('.process-loading-background').removeClass('hidden');
			LoginService.register(registerData).then(function(result) {
				if(result.status === 200) {
		      		AnalyticService.eventTrack('Register_Success', {'category' : 'login', 'label' : location.pathname});
		          if(angular.isDefined(redirect_to) && redirect_to !== null && result.data.account.isVerified=='1') {
		          	if( result.data.account.type == 'vendor' && ((typeof isMobile == 'undefined' || (typeof isMobile != 'undefined' && !isMobile.any()))) && redirect_to.match('/dashboard') == null ) {
		          		$location.path('/dashboard')
		          	} else {
		          		$location.path( redirect_to );
		          	}
		          }
		          if (result.data.account.isVerified=='0')
		          	self.change_section('verification_user');
		          else
		          	rootScope.$broadcast("checkLogin", { data: result.data.account });
    			} else {
	            AnalyticService.eventTrack('Register_Failed', {'category' : 'login', 'label' : location.pathname});
	            self.register_error = notificationService.message.LOGIN_WARNING_REGISTRATION_FAILED;
	        }
    		self.is_loading = false;
    		jQuery('.process-loading-background').addClass('hidden');
			});
		}
	};

	this.forgot_process = function(form_forgot) {
		self.error_forgot_email_empty = false;
		self.error_forgot_email_wrong = false;
		self.error_forgot_email_not_found = false;

		if(form_forgot.email.$error.required) {
			self.error_forgot_email_empty = true;
		}
		else if (form_forgot.email.$error.email) {
			self.error_forgot_email_wrong = true;
		}
		else if(form_forgot.$valid) {
			if(self.isEmailAddress(self.login.email)){
				LoginService.forgot_password(self.login.email).then(function(result) {
					if(result.status == 200) {
						self.change_section('forgot_success');
					}
					else {
						self.login.email = '';
						self.error_forgot_email_not_found = true;
					}
				});
			}else{
				self.error_forgot_email_wrong = true;
			}
		}
	}

	this.facebook_login = function() {
		self.is_loading = true;

		LoginSocialMediaService.facebook_login(self.facebook.email, $scope.country.id, self.promotional_newsletter, $scope.newAccount).then(function(response) {
			if(response.status == 403) {
				self.change_section('facebook_not_found');
				self.is_loading = false;
			}
			else if(response.status == 401) {
				self.change_section('newsletter_confirmation');
				self.socmedType = 'facebook';
				self.is_loading = false;
			}
			else if(response.status == 'unknown') {
				self.is_loading = false;
			}
			else {
        		AnalyticService.eventTrack('Facebook', {'category' : 'login', 'label' : location.pathname});
				self.is_loading = false;
				userManager.refresh();
				$rootScope.$broadcast('login_popup');
				$modalInstance.close(response);
			}
		});
	}

	// gapi didn't profide handler for window closed,
	// here a workaround. It'll fire whenever any opened window is closed'
	var originWindowOpen = window.open;
	this.checkCloseGoogleAuth = function(){
		var d = $q.defer();

		(function() {
	        window.open = function() {
	            var win = originWindowOpen.apply(this, arguments);
	            var i = setInterval(function() {
	                if (angular.isDefined(win) && win.closed) {
	                   	d.resolve(true);
	                    clearInterval(i);
	                }
	            }, 100);
       		};
       	})(window.open);

       	return d.promise;
	};

	this.google_login = function() {
		this.is_loading = true;

		this.checkCloseGoogleAuth().then(function(){
			self.is_loading = false;
			window.open = originWindowOpen;
		});

		LoginSocialMediaService.google_login(self.google.full_name, $scope.country.id, self.promotional_newsletter, $scope.newAccount).then(function(response) {
			if(response === 'error') {

      		}
			else if(response.status == 403) {
				self.change_section('full_name_not_found');
				self.is_loading = false;
			}
			else if(response.status == 401) {
				self.change_section('newsletter_confirmation');
				self.socmedType = 'google';
				self.is_loading = false;
			}
			else {
        AnalyticService.eventTrack('Google', {'category' : 'login', 'label' : location.pathname});
				self.is_loading = false;
				userManager.refresh();
				$rootScope.$broadcast('login_popup');
				$modalInstance.close(response);
			}
		}, function(error) {
		});
	}

	this.yahoo_login = function() {
		this.is_loading = true;
		LoginSocialMediaService.yahoo_login(self.facebook.email, $scope.country.id, self.promotional_newsletter, $scope.newAccount).then(function(response) {
			if(response.status == 403) {
				self.change_section('yahoo_not_found');
				self.is_loading = false;
			}
			else if(response.status == 401) {
				self.change_section('newsletter_confirmation');
				self.socmedType = 'yahoo';
				self.is_loading = false;
			}
			else {
        AnalyticService.eventTrack('Yahoo', {'category' : 'login', 'label' : location.pathname});
				self.is_loading = false;
				userManager.refresh();
				$rootScope.$broadcast('login_popup');
				$modalInstance.close(response);
			}
		}, function(error) {

		});

		var w = window.open();
	}

	this.process_register_google = function(form_register_google) {
    AnalyticService.eventTrack('Register_Google', {'category' : 'login', 'label' : location.pathname});
		self.full_name_get_error = false;
		if(Bridestory.localized_bucket == 'SG'){
			if(!self.term_condition){
				self.error_term_condition_uncheck = true;
			}else if(form_register_google.full_name.$dirty && form_register_google.full_name.$invalid) {
				self.full_name_get_error = true;
				if(form_register_google.full_name.$error.required) {
					for (var i = 0; i < self.error_message.length; i++) {
						if(self.error_message[i].error === 'register-no-full-name') {
							self.email_message = self.error_message[i].message;
						}
					};
				}
			}else if(form_register_google.full_name) {
				self.google_login(self.google.full_name);
			}
		}else{
			if(form_register_google.full_name.$dirty && form_register_google.full_name.$invalid) {
				self.full_name_get_error = true;
				if(form_register_google.full_name.$error.required) {
					for (var i = 0; i < self.error_message.length; i++) {
						if(self.error_message[i].error === 'register-no-full-name') {
							self.email_message = self.error_message[i].message;
						}
					};
				}
			}
			else if(form_register_google.full_name) {
				self.google_login(self.google.full_name);
			}
		}
	}

	this.process_register_facebook = function(form_register_facebook) {
		self.error_no_email = false;
	    self.error_wrong_email = false;
	    self.error_unique_email = false;

	    AnalyticService.eventTrack('Register_Facebook', {'category' : 'login', 'label' : location.pathname});
	    if (Bridestory.localized_bucket == 'SG' && !self.term_condition)
	      self.error_term_condition_uncheck = true;
	    else
	      self.error_term_condition_uncheck = false;

	    $scope.newAccount = '';
	    if (form_register_facebook.email.$error.required)
	      self.error_no_email = true;
	    else if (form_register_facebook.email.$error.email)
	      self.error_wrong_email = true;
	    else if (form_register_facebook.$valid) {
	      LoginService.checkEmail(self.facebook.email).then(function(response) {
	        if (response.status == 409) {
	          self.error_unique_email = true;
	        } else {
	          self.facebook_login();
	        }
	      });
	    }
	}

	this.process_register_yahoo = function(form_register_facebook) {
		self.email_get_error = false;
    AnalyticService.eventTrack('Register_Yahoo', {'category' : 'login', 'label' : location.pathname});
    	if(Bridestory.localized_bucket == 'SG' && !self.term_condition){
	      self.error_term_condition_uncheck = true;
	    }

	   	$scope.newAccount = '';
		if(form_register_facebook.email.$dirty && form_register_facebook.email.$invalid) {
			self.email_get_error = true;
			if(form_register_facebook.email.$error.required) {
				for (var i = 0; i < self.error_message.length; i++) {
					if(self.error_message[i].error === 'login-no-email') {
						self.email_message = self.error_message[i].message;
					}
				};
			}
			else if (form_register_facebook.email.$error.email) {
				for (var i = 0; i < self.error_message.length; i++) {
					if(self.error_message[i].error === 'login-wrong-email') {
						self.email_message = self.error_message[i].message;
					}
				};
			}
		}
		else if(form_register_facebook.$valid) {
			self.facebook_login(self.facebook.email);
		}
	}

	this.newsletterConfirm = function(status, socmedType) {
		self.error_term_condition_uncheck = false;
		self.promotional_newsletter = status;

		if(Bridestory.localized_bucket == 'SG' && !self.term_condition){
	  		self.error_term_condition_uncheck = true;
	  		return false;
		}

		$scope.newAccount = '';
		if(socmedType == 'google'){
			self.google_login();
		}else if(socmedType == 'facebook'){
			self.facebook_login();
		}else if(socmedType == 'yahoo'){
			self.yahoo_login();
		}
	}

	this.cancel = function() {
    AnalyticService.eventTrack('Close_Without_Action', {'category' : 'login', 'label' : location.pathname});

		$modalInstance.dismiss('closed');
	}
	this.resend_verification = function(email) {
	    LoginService.resendEmailVerification(email).then(function(result) {
        if (result.status == 200)
            notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_VERIFICATION_EMAIL_SENT);
          else
            notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_VERIFICATION_EMAIL_NOTSENT,'error');
      	}	,function(error){
          notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_VERIFICATION_EMAIL_NOTSENT,'error');
    	});
	}
	this.verification_check = function(email) {
    	LoginService.verificationCheck(email).then(function(result) {
    		self.change_section('login');
      		if(result.data.account.isVerified == '0')
        		notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_NOT_VERIFIED,'error');
    	});
  	}
  	$scope.insertHTML = function(text) {
		return $sce.trustAsHtml(text);
	}
}

angular
	.module('bridestoryApp.newLoginApp')
	.controller('LoginAuthController', [ 'section', '$rootScope', '$scope', '$modalInstance', '$timeout', '$location', '$window', '$q' ,'LoginService', 'LoginSocialMediaService', 'AnalyticService', 'CountryServices', 'GeoLocationService', 'notificationService', 'userManager','$sce','origin', 'cookieServices', 'vendormetaManager', 'MenuService', 'resourceManager', LoginAuthController ]);
