function cityCountryPopulateControllers ($scope, cityCountryPopulateServices) {
    
  $scope.cityLoad = false;

  this.getCityId = function (cityId) {
    $scope.cityId = cityId;
  }

  this.refreshCity = function () {
    $scope.cities = [];
    cityCountryPopulateServices.refreshCity($scope.countryId).then(function (response) {
      var getCities = response.vendorCities;
      for(var i = 0; i < getCities.length; i++){
        $scope.cities.push({
          name: getCities[i].name,
          id: getCities[i].id
        });
      }
      $scope.cityLoad = true;
    });
  }
}

angular
    .module('bridestoryApp.cityCountryPopulateApp')
    .controller('cityCountryPopulateControllers', ['$scope', 'cityCountryPopulateServices', cityCountryPopulateControllers ]);