function LoginService($rootScope, $http, $q, $modal, cookieServices, LoginFactory, AnalyticService, userManager, resourceManager, launchAppFactory,launchAppService, valPromoService, videoEssentialService, masterclassPromo, vendormetaManager, MenuService) {

	var modalLoginOpened = false;
	var modalOpenedDefer = $q.defer();
	return {
		openLogin: function (section,origin) {
			if (!modalLoginOpened) {
				var modalInstance = $modal.open({
					templateUrl : 'modal-authenticate-user.html',
					controller : 'LoginAuthController',
					controllerAs : 'loginAuthCtrl',
					backdrop : 'static',
	    			keyboard: false,
					windowClass : 'popup modal-scrolly',
					resolve : {
					  section: function () { return section; },
	                  origin: function () { return origin; },
					}
				});

				modalLoginOpened = true;
				modalOpenedDefer.resolve(true);

				modalInstance.opened.then(function (data){
					AnalyticService.pageTrackVirtual('/login', 'GA');
				});

				return modalInstance.result.then(function (result) {
					if(result.type == 'vendor') {
						launchAppFactory.setOpenLaunchAppModal(1);
					}

					$rootScope.$broadcast("checkLogin", { data: result });
					// cookieServices.removeCookies();

					return userManager.refresh().then(function(res){
						if(userManager.isVendor()) {

							masterclassPromo.open().then(angular.noop(), function(){
								videoEssentialService.open().then(angular.noop(), function(reason){
									// console.log('Reason: ', reason);
								});
							});
						}

						return result;
					});

					modalLoginOpened = false;
					modalOpenedDefer = $q.defer();
					/* launch apps condition */
				}, function () {
					modalLoginOpened = false;
					modalOpenedDefer = $q.defer();
					/*if ($rootScope.register) {

					} else if ($rootScope.forgot) {

					} else {

					}*/
				});
			} else {
				return modalOpenedDefer.promise;
			}
		},

		popupLoginOpened: function(){
			return
		},

		openLoginOverlay: function () {
			return openPopupOverlay('popup-login');
		},

		login: function (loginAttributes) {
			return LoginFactory.login(loginAttributes).then(function(response) {
				if(response.data.account.type == 'vendor') {
		        	userManager.inject(response);
					launchAppFactory.setOpenLaunchAppModal(1);
		          	return response;
		        }
		        else {
		          return false;
		        }
			}, function(error) {
				return error;
			});
		},

		register: function(registerAttributes) {
			return LoginFactory.register(registerAttributes);
		},

		forgot_password: function(email) {
			return LoginFactory.forgot_password(email).then(function(response) {
				return response;
			}, function(error) {
				return error;
			});
		},

		change_password: function(changePasswordAttributes){
			return LoginFactory.change_password(changePasswordAttributes).then(function(response){
				return response;
			}, function(error){
				return error;
			});
		},

		getMyDetails: function(sid) {
	      return LoginFactory.getMyDetails(sid).then(function(response) {
						return response;
	        }, function(error) {
	          return error;
	        }
	      );
    	},
    sendEmail: function(accountId) {
      return $http({
        method  : 'PUT',
        url     : Bridestory.api + '/v1/account/register/email/' + accountId
      });
    },
    resendEmailVerification: function(email) {
      return $http({
        method  : 'POST',
        url     : Bridestory.api + '/v1/resend_email_verification?email=' + email,
      });
    },
    verificationCheck: function(email) {
      return $http({
        method  : 'GET',
        url     : Bridestory.api + '/v1/verification_check?email=' + email,
      });
    },
    checkEmail: function (email) {
      return $http({
        method  : 'GET',
        url     : Bridestory.api + '/v1/email_validation?email=' + email
      }).then(function (response) {
        return response;
      }, function (error) {
        return error;
      });
    },
    /*
    openBannerLogin: function (section) {
			var modalInstance = $modal.open({
				templateUrl : 'modal-authenticate-user.html',
				controller : 'LoginAuthController',
				controllerAs : 'loginAuthCtrl',
				backdrop : 'static',
    			keyboard: false,
				windowClass : 'popup modal-scrolly',
				resolve : {
				  section: function () { return section; },
				}
			});

			modalInstance.opened.then(function (data){
				AnalyticService.pageTrackVirtual('/login', 'GA');
			});

			return modalInstance.result.then(function (result) {
				$rootScope.$broadcast("checkLogin", { data: result });
				cookieServices.setCookies(result.sessionId);

				return userManager.refresh().then(function(res){
					return result;
				});
			}, function () {
				/*if ($rootScope.register) {

				} else if ($rootScope.forgot) {

				} else {

				}
			});
	}
	*/
	}
}

function CountryServices($rootScope, $http) {
  var promise = $http.get(Bridestory.api + '/v1/country').
    success(function (response) {
      return response;
    });
    return promise;
}

angular
	.module('bridestoryApp.newLoginApp')
	.service('CountryServices', [ '$rootScope', '$http', CountryServices ])
	.service('LoginService', [ '$rootScope', '$http', '$q', '$modal', 'cookieServices', 'LoginFactory', 'AnalyticService', 'userManager', 'resourceManager', 'launchAppFactory','launchAppService', 'valPromoService', 'videoEssentialService', 'masterclassPromo', 'vendormetaManager', 'MenuService', LoginService ]);
