function Facebook($q, $http, $rootScope)
{
	// resolving or rejecting a promise from a third-party
	// API such as Facebook must be
	// performed within $apply so that watchers get
	// notified of the change
	var resolve = function(errval, retval, deferred) {
		$rootScope.$apply(function() {
			if (errval) {
				deferred.reject(errval);
			} else {
				if(retval !== null) {
					retval.connected = true;
					deferred.resolve(retval);
				}
			}
		});
	};

	return {
		authenticate: function(callback, errorCallback){
			FB.getLoginStatus(function(response) {
				if (response.status === 'connected')
				{
					callback(response.authResponse);
				} else {
					FB.login(function(response)
					{
						if (response.authResponse)
						{
							callback(response.authResponse);
						} else {
							errorCallback && errorCallback(response);
							// resolve(response.error, null, deferred);
						}
					});
				}
			});
		},
		login: function(permissionScope){
			var scope = permissionScope ? permissionScope.join(',') : '';

			var deferred = $q.defer();
			FB.login(function(response)
			{
				if (response.authResponse)
				{
					deferred.resolve(response.authResponse);
				} else {
					deferred.reject(response);
				}
			}, { scope: scope});

			return deferred.promise;
		},
		getUserPermissions: function(){
			var deferred = $q.defer();
			FB.api('/me/permissions', function(response){
			 	if (response && response.data && response.data.length){
			 		var permissions = [];
			 		angular.forEach(response.data, function(item){
			 			if (item.status == 'granted') {
			 				permissions.push(item.permission);
			 			}
			 		});
			    	deferred.resolve(permissions);
			  	}
			});

			return deferred.promise;
		},
		getUser: function() {
			var deferred = $q.defer();
			var users = [];
			this.authenticate(function(authDetail){
				FB.api('/me?fields=id,name,email,picture,token_for_business', function(response) {
					users['users_detail'] = response;
        			users['auth_detail'] = authDetail;
					// remove verified check
					// reason : https://www.facebook.com/help/196050490547892
					if (!response || response.error)
					{
						resolve(response.error, null, deferred);
					} else {
						FB.api('/me/friends', function(friend) {
							users['users_friend'] = friend.summary;
							resolve(null, users, deferred);
						});
					}
				});
			}, function(error){
				deferred.reject(error);
			});

			return deferred.promise;
		},
		getUserByAccessToken : function (accessToken) {
			var deferred = $q.defer();
			FB.api('/me', { access_token : accessToken }, function(response) {
			  	if (!response || response.error) {
			    	deferred.reject(data);
			  	} else {
			  		deferred.resolve(response);
			  	}
			});
			return deferred.promise;
		},
		getUserById : function(facebookId, accessTokenId)
		{
			var deferred = $q.defer();
			$http.get('https://graph.facebook.com/me?access_token='+accessTokenId).
				success(function(data, status, headers, config) {
					deferred.resolve(data);
				}).
				error(function(data, status, headers, config) {
					deferred.reject(data);
				});

			return deferred.promise;
		},
		getUserPicture : function(facebookId)
		{
			var deferred = $q.defer();

			FB.api('/' + facebookId + '/picture',
				{
					"redirect": false,
					"height": "166",
					"type": "normal",
					"width": "166"
				}, function(response) {
				deferred.resolve(response.data.url);
			});

			return deferred.promise;
		},
		getUserPage : function()
		{
			var deferred = $q.defer();
			var self = this;

			this.authenticate(function(){
				self.getUserPermissions().then(function(permission){
					if (permission.indexOf('manage_pages') > -1) {
						FB.api('/me/accounts', function(response) {
							deferred.resolve(response.data);
						});
					} else {
						self.login(['manage_pages']).then(function(){
							FB.api('/me/accounts', function(response) {
								deferred.resolve(response.data);
							});
						});
					}
				});
			});

			return deferred.promise;
		},
		getAlbums : function(id){
			var deferred = $q.defer();
			var self = this;

			this.getUserPermissions().then(function(permission){
				if (permission.indexOf('user_photos') > -1) {
					FB.api('/' + id + '/albums', {fields : 'id,name,count,cover_photo'}, function(response) {
						deferred.resolve(response.data);
					});
				} else {
					self.login(['user_photos']).then(function(){
						FB.api('/' + id + '/albums', {fields : 'id,name,count,cover_photo'}, function(response) {
							deferred.resolve(response.data);
						});
					});
				}
			});

			return deferred.promise;
		},
		getAlbumPhotos : function(albumId){
			var deferred = $q.defer();

			FB.api('/' + albumId + '/photos?limit=50',{fields : 'source,url'}, function(response) {
				deferred.resolve(response.data);
			});

			return deferred.promise;
		},
		getCoverPhoto : function(albumId){
			var deferred = $q.defer();

			FB.api('/' + albumId + '/picture', {
					"redirect": false
				}, function(response) {
				deferred.resolve(response.data);
			});

			return deferred.promise;
		},
		getPhoto : function(id){
			var deferred = $q.defer();

			FB.api('/' + id, function(response) {
				deferred.resolve(response.images);
			});

			return deferred.promise;
		},
		getUserUrlAlias: function (fbId, accessToken) {
			var deferred = $q.defer();
			$http.get('https://graph.facebook.com/fql?q=SELECT url FROM object_url WHERE id='+fbId+'&access_token=' + accessToken).
				success(function(result) {
					deferred.resolve(result.data);
				}).
				error(function(data) {
					deferred.reject(data);
				});
			return deferred.promise;
		}
	};

}

angular
	.module('bridestoryApp.newLoginApp')
	.service('Facebook', [ '$q', '$http', '$rootScope', Facebook ]);