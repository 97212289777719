angular
	.module('bridestoryApp.resource')
	.service('vendormetaManager',['resourceManager', function(resourceManager) {
		var _isZeroContactCredit = false,
			_totalContactCredit = 0,
			_isMembershipActive = false,
			_isMainMembershipActive = false,
			_isPublished = false,
			_isMonetized,
			_isAllowedAccessEvent = false,
			_detail = false,
			_activeMembership = false,
			_myID = false,
			_services = new resourceManager.skeleton({
				API : '/vendors/meta',
				success : function(result){
					_isPublished = result.vendor.status > 0;
					_isMonetized = result.vendor.status == 3;
					_totalContactCredit = result.contactCredit;
					_isZeroContactCredit = result.contactCredit == 0 ? true : false;
					_isMembershipActive = result.activeMembershipCount == 0 ? false : true;
					_isMainMembershipActive = result.activeMainMembership == 0 ? false : true;
				}
			});

		_services.promise.then(function(result){

			_myID = result.vendor.id;

			var _detail__raw = false,
				_detail__isExpiring = false,
				_detail__expiringCity = [],
				_detail__cities = {},
				_detail__arrayOfCities = [],
				_detail__categories = {},
				_detail__allCategories = {},
				_detail__categoriesByCities = {},
				_detail__mainCity = {},
				_detail__mainCategory = {},
				_detail__settings = {},
				_detail__isAllowedAccessEvent = false,
				_hasActiveMembership,
				_detail__citiesOfCountry = function(countryId) {

					if(_detail__cities)

					var __citiesFromCountry = [];

					for(var key in _detail__cities) {
						if(_detail__cities[key].country.data.id == countryId) __citiesFromCountry.push(_detail__cities[key]);
					}

					return __citiesFromCountry;
				}

			_activeMembership = angular.extend(new resourceManager.skeleton({
				API : '/me',
				apiVersion : 'v2',
				success : function(result) {
					//override default raw to more spesific (result.vendor instead of result)
					_hasActiveMembership = result.vendor.hasActiveMembership;
				}
			}),{
				//override default raw to more spesific (result.vendor instead of result)
				hasActiveMembership : function(){
					return _hasActiveMembership;
				}
			});

			_detail = angular.extend(new resourceManager.skeleton({
				API : '/vendors/' + _myID + '?include=account,accountCountryCurrency,accountmanager,details,memberships,services,mainService,settings,hildaPartner,countryOfOrigin,freeTrial&mask=0',
				apiVersion : 'v2',
				success : function(result) {
					//override default raw to more spesific (result.vendor instead of result)
					_detail__raw = result.vendor;
					_detail__isExpiring = false;
					_detail__expiringCity = [];
					_detail__cities = {};
					_detail__arrayOfCities = [];
					_detail__categories = {};
					_detail__allCategories = {};
					_detail__categoriesByCities = {};
					_detail__mainCity = {};
					_detail__mainCategory = {};
					_detail__settings = {};
					_detail__accountManager = {};
					_detail__isAllowedAccessEvent = false;
					_detail__isVenueVendor = false;
					_detail__freeTrial = {};

					angular.forEach(result.vendor.memberships.data,function(value,key){
						if(value.status == 'active' && value.service && _detail__expiringCity.indexOf(value.service.data.city.data.name) == -1 && value.endDate !== null && new Date(value.endDate).getTime() <= new Date().getTime() + (30 * 24 * 60 * 60 *1000)) {
							_detail__expiringCity.push(value.service.data.city.data.name)
						}

						if(value.service)
							_detail__cities[value.service.data.city.data.id] = value.service.data.city.data;

						if(result.vendor.status == 1)
							_detail__isAllowedAccessEvent = true;

					})

					angular.forEach(result.vendor.services.data,function(value){
						if(value.status == 'active') {
							_detail__categories[value.category.data.id] = value.category.data;

							if(_detail__categoriesByCities[value.city.data.id] === undefined)
								_detail__categoriesByCities[value.city.data.id] = {};

							_detail__categoriesByCities[value.city.data.id][value.category.data.id] = value.category.data;
						}

						if(value.category.data.name == 'Venue') _detail__isVenueVendor = true;

						_detail__allCategories[value.category.data.id] = angular.extend(value.category.data,{
							transcoded : {
								categoryStatus : value.status
							}
						});

					})

					for(var key in _detail__cities) {
						_detail__arrayOfCities.push(_detail__cities[key]);
						if(_detail__cities[key].id == result.vendor.mainService.data.city.data.id)
							_detail__arrayOfCities.splice(0, 0, _detail__arrayOfCities.splice(_detail__arrayOfCities.length-1, 1)[0]);
					}

					_detail__mainCategory = result.vendor.mainService.data.category.data;
					_detail__mainCity = result.vendor.mainService.data.city.data;
					_detail__isExpiring = !!_detail__expiringCity.length;
					_detail__settings = result.vendor.settings.data;
				}
			}),{
				//override default raw to more spesific (result.vendor instead of result)
				raw : function(){
					return _detail__raw
				},
				isExpiring : function(){
					return _detail__isExpiring
				},
				expiringCity : function(){
					return _detail__expiringCity
				},
				mainCategory : function(){
					return _detail__mainCategory
				},
				mainCity : function(){
					return _detail__mainCity
				},
				categories : function(){
					return _detail__categories
				},
				categoriesByCities : function(){
					return _detail__categoriesByCities
				},
				allCategories : function(){
					return _detail__allCategories
				},
				isHavingPendingCategories : function(){
					for(var key in _detail__allCategories) {
						if(_detail__allCategories[key].transcoded.categoryStatus == 'pending') return true;
					}
					return false;
				},
				cities : function(){
					return _detail__cities
				},
				arrayOfCities : function(){
					return _detail__arrayOfCities
				},
				citiesOfCountry : function(countryId){
					return _detail__citiesOfCountry(countryId);
				},
				settings : function(){
					return _detail__settings
				},
				removeCategory : function(category) {
					if(category.id != _detail__mainCategory.id) {
						return new resourceManager.skeleton({
							API : ['/vendors_services/:vendorId/:categoryId',{
								vendorId :  _myID,
								categoryId : category.id
							}],
							apiVersion : 'v1',
							method : 'DELETE'
						})
					}
					return false;
				},
				vendorEvent: function() {
					return _detail__isAllowedAccessEvent;
				},
				venueVendor : function() {
					return _detail__isVenueVendor;
				}
			});
		})

		//FREE PLAN IS :
		// !isMembershipActive

		_services = angular.extend(_services,{
			isPublished : function(){
				return _isPublished;
			},
			isZeroContactCredit : function(){
				return _isZeroContactCredit;
			},
			isMonetized: function(){
				return _isMonetized;
			},
			totalContactCredit: function(){
				return _totalContactCredit;
			},
			isMembershipActive : function(){
				return _isMembershipActive;
			},
			isMainMembershipActive : function(){
				return _isMainMembershipActive;
			},
			detail : function(){
				return _detail;
			},
			activeMembership : function(){
				return _activeMembership;
			}
		})

		return _services;

	}])
