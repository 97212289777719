function vendorSearchByServices($q, $http, vendorFilterServices) {
  this.filters = [];
  var self = this;

  this.completeSearchUrl = function (category_id, budget_id, callback) {
    if (category_id) {
      vendorFilterServices.getCategories().then(function (categories) {
        angular.forEach(categories.data.categories, function (category) {
          if (category.id == parseInt(category_id)) {
            self.filters.push(category.slug);
          }
        });
        vendorFilterServices.getbudgets().then(function (budgets) {
          angular.forEach(budgets.data.budgets, function (budget) {
            if (budget.id == parseInt(budget_id)) {
              self.filters.push(budget.slug);
            }
          });

          callback & callback()
        });
      });
    } else {
      if (budget_id) {
          self.filters.push('all-categories');
      }
      
      vendorFilterServices.getbudgets().then(function (budgets) {
        angular.forEach(budgets.data.budgets, function (budget) {
          if (budget.id == parseInt(budget_id)) {
            self.filters.push(budget.slug);
          }
        });

        callback & callback()
      });
    }
  };

  this.buildVendorSearchUrl = function (country_id, category_id, location_id, budget_id) {
    self.filters = [];
    var deferred = $q.defer();

    if (angular.isDefined(country_id) && country_id) {
      vendorFilterServices.getCountries().then(function (countries) {
        angular.forEach(countries.data.country, function (country) {
          if (country.id == parseInt(country_id)) {
            self.filters.push(country.slug);
          }
        });

        if (location_id) {
          vendorFilterServices.getCities(country_id).then(function (result) {
            angular.forEach(result.vendorCities, function (city) {
              if (city.id == parseInt(location_id)) {
                self.filters.push(city.slug);
              }
            });
            self.completeSearchUrl(category_id, budget_id, function() {
              deferred.resolve(self.filters.join('/'));
            });
          });
        } else {
          if (category_id ||  budget_id) {
            self.filters.push('all-cities');
          }
          
          self.completeSearchUrl(category_id, budget_id, function() {
            deferred.resolve(self.filters.join('/'));
          });
        }
      });
    } else {
      self.filters.push('all-countries');
      if (category_id ||  budget_id) {
        self.filters.push('all-cities');
      }
      self.completeSearchUrl(category_id, budget_id, function() {
        deferred.resolve(self.filters.join('/'));
      });
    }

    return deferred.promise;
  };


  this.searchByName = function(name, page) {
    if(!page) page = 1;
    var includes = ['displayMembership', 'displayService', 'votesCounter', 'viewsCounter', 'pricelistCounter', 'reviewsSummary'];
    return $http({
        method  : 'GET',
        url     : Bridestory.api + '/v2/vendors/search/' + name + '?limit=12&page=' + page + '&include=' +includes.join(','),
        headers : {
            'bs-localization-bucket': (Bridestory.localized_bucket == 'default') ? 'none' : Bridestory.localized_bucket
        }
    });
  };
}

function vendorFilterServices($http, $q, resourceManager) {
  this.getFilters = function () {
    var deferred = $q.defer();

    $q.all([
      $http.get(Bridestory.api + '/v1/vendor_categories'),
      $http.get(Bridestory.api + '/v1/vendor_cities'),
      $http.get(Bridestory.api + '/v1/vendor_budgets')
    ])
      .then(function (results) {
        deferred.resolve(results);
      }, function (errors) {
        deferred.reject(errors);
      }, function (updates) {
        deferred.update(updates);
      });

    return deferred.promise;
  };

  this.getCountries = function (countryId) {
    var deferred = $q.defer();
    $http.get(Bridestory.api + '/v1/country')
      .then(function (results) {
        deferred.resolve(results);
      }, function (errors) {
        deferred.reject(errors);
      }, function (updates) {
        deferred.update(updates);
      });

    return deferred.promise;
  };

  this.getAvailableCountries = function() {
    return $http.get(Bridestory.cdn_api + '/v1/country/conditional_cities');
  };
  
  this.getCities = function (countryId) {
    var deferred = $q.defer();
    resourceManager.get('/vendor_services/all_cities?country_id=:countryId',{
      countryId : countryId
    },'v1','GET',true).then(function(result){
      deferred.resolve(result);
    },function(error){
      deferred.reject(error);
    });

    return deferred.promise;
  };

  this.getCategories = function () {
    var deferred = $q.defer();
    $http.get(Bridestory.api + '/v1/vendor_categories')
      .then(function (results) {
        deferred.resolve(results);
      }, function (errors) {
        deferred.reject(errors);
      }, function (updates) {
        deferred.update(updates);
      });
    return deferred.promise;
  };

  this.getbudgets = function () {
    var deferred = $q.defer();
    $http.get(Bridestory.api + '/v1/vendor_budgets')
      .then(function (results) {
        deferred.resolve(results);
      }, function (errors) {
        deferred.reject(errors);
      }, function (updates) {
        deferred.update(updates);
      });

    return deferred.promise;
  };
}

function getURLParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

angular.module('bridestoryApp.vendorSearchApp')
  .service('vendorSearchByServices', ['$q', '$http', 'vendorFilterServices', vendorSearchByServices ])
  .service('vendorFilterServices', [ '$http', '$q', 'resourceManager', vendorFilterServices]);
