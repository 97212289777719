function warningAlertController($sce, $scope, $modalInstance, $timeout, autohide, message, messagetype, status, title, yesButtonText, noButtonText, callback){

	$scope.message = $sce.trustAsHtml(message);
	$scope.messagetype = messagetype;
	$scope.status = status;
	$scope.title = title || yesButtonText;
	$scope.yesButtonText = yesButtonText;
	$scope.noButtonText = noButtonText;

	$scope.close = function(){
		$modalInstance.close();
	};
	$scope.proceed = function(){
		if(callback && typeof callback == 'function') callback();
		$modalInstance.close();
	};

	$scope.dismiss = function(){
		$modalInstance.dismiss();
	}

	if(autohide) {
		$timeout(function(){
	    $modalInstance.dismiss();
	  }, 5000);
	}
}

angular
	.module('bridestoryApp.notificationApp')
	.controller('warningAlertController', ['$sce', '$scope', '$modalInstance' , '$timeout', 'autohide', 'message', 'messagetype', 'status', 'title', 'yesButtonText', 'noButtonText', 'callback', warningAlertController]);