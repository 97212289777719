try { angular.module("bridestoryApp.filters"); } catch (err) { angular.module('bridestoryApp.filters', []); }

angular.module("bridestoryApp.filters")
  .constant('imageStyleConfig', {
    style: {
      'original' : {},
      'blog_portrait' : {
        'width' : 340,
        'height' : 506,
        'backgroundColor' : 'ffffff'
      },
      'blog_landscape' : {
        'width' : 680,
      },

      'work_portrait' : {
        'width' : 346,
        // 'height' : 510,
        'backgroundColor' : 'ffffff'
      },
      'work_landscape' : {
        'width' : 696,
      },
      'search_vendor' : {
        'width' : 36,
        'height' : 36,
        'crop' : true
      },
      'project_cover' : {
        'width' : 213,
        'height' : 213,
        'crop' : true,
      },
      'medium_thumbnail' : {
        'width' : 200,
        'height' : 200,
        'crop' : true
      },
      'profile_picture' : {
        'width' : 200,
        'height' : 200,
        'crop' : true
      },
      'small_thumbnail' : {
        'width' : 31,
        'height' : 31,
        'crop' : true
      },
      'homestream_thumbnail' : {
        'width' : 235
      },
      'homestream' : {
        'width' : 235
      },
      'homestream_blog' : {
        'width' : 235
      },
      'inspiration-image' : {
        'width' : 235,
        'height' : 235,
        'crop' : true
      },
      'inspiration-blog-image' : {
        'width' : 235,
        'height' : 235,
        'crop' : true
      },
      'homestream_mobile' : {
        'width' : 310,
      },
      'homestream_mobile_midres' : {
        'width' : 310,
        'dpr' : '2.0'
      },
      'homestream_mobile_hires' : {
        'width' : 310,
        'dpr' : '3.0'
      },
      'homestream_blog_mobile' : {
        'width' : 310,
      },
      'homestream_blog_mobile_midres' : {
        'width' : 310,
        'dpr' : '2.0'
      },
      'homestream_blog_mobile_hires' : {
        'width' : 310,
        'dpr' : '3.0'
      },
      'vendor_profile': {
        'width' : 250,
        'height' : 250,
        'crop' : true,
      },
      'vendor_cover': {
        'width' : 250,
        'height' : 250,
        'q' : 80,
        'dpr' : 'auto',
        'crop' : true
      },
      'mobile_vendor_cover': {
        'width' : 640,
        'height' : 150,
        'thumb' : true,
        'blur' : true
      },
      'comment_cover' : {
        'width' : 52,
        'height' : 52,
        'crop' : true
      },
      'inspiration_thumb' : {
        'width' : 200,
        'height' : 200,
        'thumb' : true
      },
      'inspiration_blog_thumb' : {
        'width' : 200,
        'height' : 200,
        'thumb' : true
      },
      'other_project_thumbnail': {
        'width' : 213,
        'height' : 135,
        'crop' : true,
      },
      'board_inspiration_cover': {
        'width' : 233,
        'height': 170,
        'thumb' : true
      },
      'board_inspiration': {
        'width' : 100,
        'height': 100,
        'thumb' : true
      },
      'board_inspiration_cover_blog': {
        'width' : 233,
        'height' : 170,
        'thumb' : true
      },
      'board_inspiration_blog' :{
        'width' : 100,
        'height' : 100,
        'thumb' : true
      },
      'work_thumbnail' : {
        'width' : 188,
        'height' : 188,
        'thumb' : true
      },
      'deal_thumbnail' : {
        'width' : 128,
        'height' : 128,
        'thumb' : true
      },
      'deal_list' : {
        'width' : 320,
        'height' : 170,
        'thumb' : true
      }
    },
    transformations : ['q_80', 'fl_progressive', 'f_auto']
  })
  .run(function(){
    $.cloudinary.config({
      cloud_name: 'bridestory',
      cname: Bridestory.imagehost_url,
      secure_distribution: Bridestory.imagehost_url,
      private_cdn: true,
      secure: true,
    });
  })
  .service('cloudinaryUrlService', ['$filter', 'imageStyleConfig', function($filter, imageStyleConfig){
    var defaultOptions = {'quality': 80,'flags': 'progressive','fetch_format': 'auto','crop':'scale', 'dpr':window.devicePixelRatio.toFixed(1)};
    var style = 'original';

    var generateCloudinaryOptions = function(styleName){
      var styleConfig = imageStyleConfig.style[styleName];
      var arrOptions = [];
      arrOptions['quality'] = 80;

      if (angular.isDefined(styleConfig)) {
        angular.isDefined(styleConfig.width) && (arrOptions['width'] = styleConfig.width);
        angular.isDefined(styleConfig.height) && (arrOptions["height"] = styleConfig.height);

        arrOptions['flags'] = 'progressive';
        arrOptions['fetch_format'] = 'auto';


        if(angular.isDefined(styleConfig.center) && styleConfig.center){
          arrOptions['crop'] = ["lpad"];
        } else {
          (angular.isDefined(styleConfig.crop) && styleConfig.crop) ? (arrOptions['crop'] = "fill") : (arrOptions['crop'] = 'scale');
        }

        if(styleName == 'project_cover') {
          arrOptions['gravity'] = "face";
          arrOptions['crop'] = "fill";
        }

        (angular.isDefined(styleConfig.blur) && styleConfig.blur) && (arrOptions['effect'] = "blur");
        (angular.isDefined(styleConfig.thumb) && styleConfig.thumb) && (arrOptions['crop'] = "thumb");


        if(angular.isDefined(styleConfig.dpr)) {
          arrOptions['dpr'] = styleConfig.dpr;
        } else {
          arrOptions['dpr'] = window.devicePixelRatio.toFixed(1);
        }
      }

      return arrOptions;
    };

    var isSocmedUrl = function(url){
      return /googleusercontent.com/.exec(url) || /fbcdn-profile-a.akamaihd.net/.exec(url) || /scontent.cdninstagram.com/.exec(url);
    };

    var isYoutube = function(url){
      var youtubeReg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return (url.match(youtubeReg)) ? RegExp.$1 : false;
    };

    var isVimeo = function(url){
      var vimeoReg = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
      var match = url.match(vimeoReg);

      return match ? match[3] : false;
    };

    var isVideoCDN = function(url){
      var parser = document.createElement('a');
      parser.href = url;

      var pathArr = parser.pathname.split("/");

      if (pathArr.length > 2) {
        if (['vimeo', 'youtube'].indexOf(pathArr[2]) != -1) {
          return pathArr;
        }
      }

      return false;
    };


    this.toCDN = function(url, styleName, seoName){
        style = styleName || style;
        var options = generateCloudinaryOptions(style);

        // var options = angular.copy(defaultOptions);

        // options = angular.extend(options, generateCloudinaryOptions(style));
        var filename = '';
        var externalUrl = false;
        if ((pathArr = isVideoCDN(url))) {
          options = angular.extend(options, {'type': pathArr[2]});
          filename = pathArr[3];
          externalUrl = true;
        } else {
          // check if image is external url
          if (isSocmedUrl(url)) {
            options = angular.extend(options, {'type':'fetch'});
            filename = url;
            externalUrl = true;
          } else {
            filename =  $filter('encodeUriString')(url.substring(url.lastIndexOf('/') + 1));

            if (url.indexOf('/blogs/') !== -1) {
                filename = 'blogs' + '/' + filename;
            } else {
              if (['vendor_profile','board_inspiration_cover','board_inspiration', 'inspiration-image', 'inspiration_thumb', 'homestream_mobile_midres', 'homestream_mobile_hires', 'vendor_cover_homestream_hires','vendor_cover_homestream_midres', 'vendor_cover_homestream', 'homestream', 'homestream_mobile', 'work_landscape', 'work_portrait', 'project_cover', 'vendor_cover', 'other_project_thumbnal', 'original', 'mobile_vendor_cover', 'social_media_project', 'search_vendor', 'work_thumbnail', 'deal_thumbnail', 'profile_picture', 'deal_list'].indexOf(styleName) != -1){
                filename = 'assets' + '/' + filename;
              } else {
                filename = 'blogs' + '/' + filename;
              }
            }
          }
        }

        if (angular.isDefined(seoName) && seoName !== '') {
          filename = filename.replace(/(?:\.([^.]+))?$/, '');
          if (!externalUrl) {
            options['url_suffix'] = seoName;
            options['path_prefix'] = 'images';
          }
          options['format'] = 'jpg';

        }

        options['page'] = 1;

        //return $.cloudinary.url(filename, options).replace(/^https:\/\//i, 'http://');
        return $.cloudinary.url(filename, options);
      };

    this.transformVideoCDN = function(url, base, styleName){
        style = styleName || style;

        var filename = $filter('encodeUriString')(url.substring(url.lastIndexOf('/') + 1));
        var defaultOptions = angular.copy(defaultOptions);
        var styleOptions = generateCloudinaryOptions(style);

        var options = $.extend(defaultOptions, styleOptions);

        options['transformVideoCDN', 'type'] = base;
        if(styleName == 'project_cover')
          options['crop'] = 'fill';
        else
          options['crop'] = 'pad';
        options['background'] = 'black';

        return $.cloudinary.url(filename, options);
    };

    this.transformSocmedCDN = function(url, base, styleName){
        style = styleName || style;

        var filename = $filter('encodeUriString')(url.substring(url.lastIndexOf('/') + 1));
        var defaultOptions = angular.copy(defaultOptions);
        var styleOptions = generateCloudinaryOptions(style);

        var options = $.extend(defaultOptions, styleOptions);

        options['transformVideoCDN', 'type'] = base;
        options['background'] = 'black';
        options['default_image'] = 'profile-dash-default_t6ouok.gif';

        return $.cloudinary.url(filename, options);
    };

    this.videoToCDN = function(url, styleName){
        style = styleName || style;
        var filename = '';
        var options = {};

        if ((filename = isVimeo(url))) {
          options['type'] = 'vimeo';
        }

        if ((filename = isYoutube(url))) {
          options['type'] = 'youtube';
        }

        options['format'] = 'jpg';

        return $.cloudinary.url(filename, options);
    };
  }])
  .service('imageStyleService', ['$filter', 'imageStyleConfig', function ($filter, imageStyleConfig) {
    var isSocmedUrl = function(url){
      return /googleusercontent.com/.exec(url) || /fbcdn-profile-a.akamaihd.net/.exec(url) || /scontent.cdninstagram.com/.exec(url);
    };

    this.toCDN = function (url, styleName, seoName) {
      var youtubeImage = /.*(?:img.youtube.com\/vi)([^#\&\?]*).*/;
      var isVideoUrl =  youtubeImage.exec(url);

      var filename = isVideoUrl || isSocmedUrl(url) ? url : $filter('encodeUriString')(url.substring(url.lastIndexOf('/') + 1));
      var transformations = ["q_80"],
        style = imageStyleConfig.style[styleName],
        prefix = 'blogs',
        ext = '';

      if (angular.isDefined(style)) {
        angular.isDefined(style.width) && transformations.push("w_" + style.width);
        angular.isDefined(style.height) && transformations.push("h_" + style.height);

        transformations.push('fl_progressive');
        transformations.push('f_auto');

        if (!isVideoUrl) {
          if(angular.isDefined(style.center) && style.center){
            transformations.push("c_lpad");
          } else {
            (angular.isDefined(style.crop) && style.crop) ? transformations.push("c_fill") : transformations.push('c_scale');
          }
        }

        (angular.isDefined(style.blur) && style.blur) && transformations.push("e_blur:2000");
        (angular.isDefined(style.thumb) && style.thumb) && transformations.push("c_thumb");

        if(angular.isDefined(style.dpr)) {
          transformations.push("dpr_" + style.dpr)
        } else {
          transformations.push("dpr_" + window.devicePixelRatio.toFixed(1))
        }

        if (['vendor_profile','board_inspiration_cover','board_inspiration', 'inspiration-image', 'inspiration_thumb', 'homestream_mobile_midres', 'homestream_mobile_hires', 'vendor_cover_homestream_hires','vendor_cover_homestream_midres', 'vendor_cover_homestream', 'homestream', 'homestream_mobile', 'work_landscape', 'work_portrait', 'project_cover', 'vendor_cover', 'other_project_thumbnal', 'original', 'mobile_vendor_cover', 'social_media_project', 'search_vendor', 'work_thumbnail', 'deal_thumbnail', 'deal_list'].indexOf(styleName) != -1){
          prefix = 'assets';
        }

        if (isVideoUrl || isSocmedUrl(url)) {
          return "https://"+Bridestory.imagehost_url+"/image/fetch/" + transformations.join(',') + "/" + filename;
        } else {
          if (seoName != null && seoName != '') {
            var ext = /(?:\.([^.]+))?$/.exec(filename)[1];
            if (ext) ext = '.' + ext;
            return "https://"+Bridestory.imagehost_url+"/images/" + transformations.join(',') + "/" + prefix + "/" + filename.replace(/\.[^/.]+$/, "") + "/" + seoName + ext;
          } else {
            return Bridestory.imagehost_upload_url +"/"+ transformations.join(',') + "/" + prefix + "/" + filename;
          }
        }
      }

      return url;
    };

    this.videoToCDN = function(url, styleName){
      var style = styleName || 'default';
      var cdnUrl = 'https://'+Bridestory.imagehost_url+'/image';
      var filename = '';

      if ((filename = isVimeo(url))) {
        return cdnUrl += '/vimeo/' + filename + '.jpg';
      }

      if ((filename = isYoutube(url))) {
        return cdnUrl += '/youtube/' + filename + '.jpg';
      }

      return url;
    };

    var generateTransformations = function(styleName){
      var transformations = angular.copy(imageStyleConfig.transformations);
      var style = imageStyleConfig.style[styleName];
      var dpr = false;
      var thumb = false;

      if (angular.isDefined(style)) {
        angular.forEach(style, function(value, type){
          switch(type) {
            case 'width':
              transformations.push("w_" + value);
              break;
            case 'height':
              transformations.push("h_" + value);
              break;
            case 'dpr':
              transformations.push("dpr_" + value);
              dpr = true;
            case 'blur':
              if (value)
                transformations.push("e_blur:2000");
            case 'thumb':
              if (value)
                transformations.push("c_thumb");
                thumb = true;
            default:
              angular.noop();
          }
        });

        if (!dpr) {
          transformations.push('dpr_' + window.devicePixelRatio.toFixed(1));
        }

        if (!thumb) {
          transformations.push('c_thumb');
        }
      }

      return transformations;
    };

    var isYoutube = function(url){
      var youtubeReg = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return (url.match(youtubeReg)) ? RegExp.$1 : false;
    };

    var isVimeo = function(url){
      var vimeoReg = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
      var match = url.match(vimeoReg);

      return match ? match[3] : false;
    };

    this.transformVideoCDN = function(url, base, style){
      var filename = $filter('encodeUriString')(url.substring(url.lastIndexOf('/') + 1));
      var cdnUrl = 'https://'+Bridestory.imagehost_url+'/image';

      return cdnUrl + '/' + base + '/' +  generateTransformations(style) + '/' + filename;
    }
  }])
  .filter('imageStyle', ['$sce', 'cloudinaryUrlService', function ($sce, cloudinaryUrlService) {
    return function (input, style, seoName) {
      var vimeo_regex  = new RegExp("https:\/\/"+Bridestory.imagehost_url+"\/image\/vimeo");
      var youtube_regex = new RegExp("https:\/\/"+Bridestory.imagehost_url+"\/image\/youtube");

      if (input == null) return false;

      if(vimeo_regex.exec(input.toString())){
        return $sce.trustAsResourceUrl(cloudinaryUrlService.transformVideoCDN(input.toString(), 'vimeo', style));
      }

      if(youtube_regex.exec(input.toString())){
        return $sce.trustAsResourceUrl(cloudinaryUrlService.transformVideoCDN(input.toString(), 'youtube', style));
      }

      if(/res.cloudinary.com\/bridestory\/image\/facebook/.exec(input.toString())){
        return $sce.trustAsResourceUrl(cloudinaryUrlService.transformSocmedCDN(input.toString(), 'facebook', style));
      }

      if(/res.cloudinary.com\/bridestory\/image\/gplus/.exec(input.toString())){
        return $sce.trustAsResourceUrl(cloudinaryUrlService.transformSocmedCDN(input.toString(), 'gplus', style));
      }

      return $sce.trustAsResourceUrl(cloudinaryUrlService.toCDN(input.toString(), style, seoName));
    };
  }])
  .filter('videoImageStyle', ['$sce', 'cloudinaryUrlService', function ($sce, cloudinaryUrlService) {
    return function (input, style, seoName) {
      if (input == null) return false;
      return $sce.trustAsResourceUrl(cloudinaryUrlService.videoToCDN(input.toString(), style));
    };
  }])
  .filter('homestreamImage', function () {
    var max_width = 235,
      img = {'width' : max_width, 'height' : max_width },
      scale = 1;

    return function (width, height) {
      if (width > 0 && height > 0) {
        scale = max_width / width;
        img = {'width' : Math.floor(scale * width), 'height' : Math.floor(scale * height) };
      }
      return img;
    };
  })
  .filter('encodeUriString', function () {
    return function (str) {
      if (typeof str == "string" && decodeURIComponent(str) === str){
        str = encodeURIComponent(str);
      }
      return str;
    }
  });
