function mobileSearchController($scope, vendorSearchMobileService) {
  $scope.city = false;
  $scope.country = false;
  $scope.budget = false;


  this.closePopupOverlay = function (classname) {
    $('.popup-overlay.' + classname).hide();
    $('body').css('overflow', 'auto');
    vendorSearchMobileService.updateParams($scope.categoryId, $scope.country, $scope.city, $scope.budget);
  };
}

angular.module('bridestoryApp.vendorSearchAppMobile')
  .controller('mobileSearchController', ['$scope', 'vendorSearchMobileService', mobileSearchController]);