angular
.module('bridestoryApp')
.factory('localizationInjector', [function() {
  var localizationInjector = {
    request: function(config) {
      if (config.url == false)
        return config;

      if (config.url.indexOf('html') >= 0 || config.url.indexOf('module') >= 0)
        return config;

      if (config.method !== 'GET')
        return config;

      if (config.url.indexOf('?') == -1)
        config.url += '?';
      else
        config.url += '&';
      
      config.url += 'bs-translation-bucket=' + language.toUpperCase() || _loc_Bucket;

      config.headers['bs-translation-bucket'] = language.toUpperCase() || _loc_Bucket;
      config.headers['bs-localization-bucket'] = _loc_Bucket || language.toUpperCase();
      return config;
    }
  };
  return localizationInjector;
}])
.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('localizationInjector');
}]);