function accountMetaController($scope, $rootScope, accountMetaService, cookieServices){

	this.meta = null;
	this.userMeta = null;

	var self = this;

	this.retrieveMeta = function(){
		accountMetaService.retrieveMeta(cookieServices.getCookie('BSID')).then(function(response) {
      		if(response.status == 200) {
      			self.meta = response.data;
      			$rootScope.$broadcast("setMeta", { data: response.data });
      		}
		});
	}

	this.retrieveUserMeta = function(){
		accountMetaService.retrieveUserMeta(cookieServices.getCookie('BSID')).then(function(response) {
      		if(response.status == 200) {
      			self.userMeta = response.data;
      			$rootScope.$broadcast("setUserMeta", { data: response.data });
      		}
		});
	}

	$scope.$on("updateMeta", function (event, args) {
		self.retrieveMeta();
	});

	$scope.$on("updateUserMeta", function (event, args) {
		self.retrieveUserMeta();
	});

	$scope.$on("setMeta", function (event, args){
		self.meta = args.data;
	})

	$scope.$on("setUserMeta", function (event, args){
		self.userMeta = args.data;
	})

	this.retrieveMeta();

	$scope.parseInt = parseInt;
}

angular
	.module('bridestoryApp.accountMetaApp')
	.controller('accountMetaController', ['$scope', '$rootScope', 'accountMetaService', 'cookieServices', accountMetaController]);