function AnalyticController ($scope, $location, AnalyticService) {
	var self = this;
	if(location.pathname == '/wedding-vendors') { // to double page view in vendor list page
		ga('send', 'pageview');
    ga('detailedTracker.send', 'pageview');
	}

	this.eventTrack = function(_category, _action, _label) {
		var data = {category : _category, action : _action, label : _label};
		AnalyticService.eventTrack(_action, data);
	}
}

angular
	.module('bridestoryApp.analyticApp')
	.controller('AnalyticController', [ '$scope', '$location', 'AnalyticService', AnalyticController ]);