angular
.module('bridestoryApp.launchApp',['ui.bootstrap'])
.factory('launchAppFactory', ['localStorageService', function(localStorageService) {

	this.BridestoryTrans = BridestoryTrans;

	var _cookey = 'BS_LaunchAppCommence',
		_counterCookey = 'BS_LaunchAppCounter',
        _monetizeCookie = 'BS_Monetize';

	return {
		setOpenLaunchAppModal : function(val){
			localStorageService.cookie.add(_cookey,val);
		},
		getCommenceState : function(){
			return localStorageService.cookie.get(_cookey) ? parseInt(localStorageService.cookie.get(_cookey),10) : 0;
		},
		getLaunchAppCounter : function(){
			return localStorageService.cookie.get(_counterCookey) ? parseInt(localStorageService.cookie.get(_counterCookey),10) : 0;
		},
		addLaunchAppCounter : function(){
			localStorageService.cookie.add(_counterCookey, this.getLaunchAppCounter() + 1);
		},
        getOpenMonetizeModal : function(val) {
            return localStorageService.cookie.get(_monetizeCookie) ? parseInt(localStorageService.cookie.get(_monetizeCookie),10) : 0;
        },
        setOpenMonetizeModal : function(val) {
            localStorageService.cookie.add(_monetizeCookie, val);
        },
        removeOpenMonetizeModal : function(val) {
            localStorageService.cookie.remove(_monetizeCookie);
        }
	}
}])
.service('launchAppService', ['launchAppFactory', 'cookieServices', '$modal', '$q', '$http', function(launchAppFactory, cookieServices, $modal, $q, $http){

	var _isCommencing = launchAppFactory.getCommenceState(),
		_counter = launchAppFactory.getLaunchAppCounter(),
        opened = false;

    var isDownloaded = function(){
        var defer = $q.defer();

        $http.get(Bridestory.api + '/v2/mobile_apps/isDownloaded', {
            headers: {
                'Session-Id': cookieServices.getCookie('BSID')
            }
        }).then(function(res){
            defer.resolve(res.data.status);
        }, function(){
            defer.reject(false);
        });

        return defer.promise;
    }
	return {
		init : function(){
            isDownloaded().then(function(status){
                if (!status & !opened) {
                    if(_isCommencing && _counter <= 3) {
                        var modalInstance = $modal.open({
                            templateUrl : Bridestory.default_url + '/popup/app-download',
                            controller: 'launchAppsBannerController',
                            controllerAs : 'launchAppsCtrl',
                            windowClass : 'popup modal-scrolly popup-launchapp',
                        });

                        opened = true;

                        modalInstance.opened.then(function() {
                            launchAppFactory.addLaunchAppCounter();
                            launchAppFactory.setOpenLaunchAppModal(0);
                        });

                        modalInstance.result.then(function(){
                            opened = false;
                        }, function(){
                            opened = false;
                        })
                    }
                }
            });
		}
	}
}])
.service('monetizeModal', ['$modal', 'launchAppFactory', function($modal, launchAppFactory){
    var _isCommencing = launchAppFactory.getCommenceState(),
        _isMonetize   = launchAppFactory.getOpenMonetizeModal();

    this.init = function(){
        if ((_isCommencing) || (_isCommencing && _isMonetize) || (!_isCommencing && _isMonetize)) {
            var modalInstance = $modal.open({
                templateUrl : redirect_url + '/popup/monetize',
                windowClass : 'popup modal-scrolly popup-launchapp',
                controller : 'monetizeModalController',
                controllerAs : 'monetizeModalCtrl'
            });

            modalInstance.opened.then(function() {
                launchAppFactory.setOpenLaunchAppModal(0);
                launchAppFactory.setOpenMonetizeModal(1);
            });
        }
    };
}])
.controller('monetizeModalController', ['vendormetaManager', 'cookieServices', 'resource.utility.availablePlanService', 'resource.utility.lowestPriceService', function(vendormetaManager, cookieServices, availablePlanService, lowestPriceService){
    var _accountManager = {},
        _origin = null,
        _isELigiblePrepaid = null,
        _trialPackage = false;

    vendormetaManager.promise.then(function(){
        vendormetaManager.detail().promise.then(function(){
            _accountManager = vendormetaManager.detail().raw().accountManager;
            _origin = vendormetaManager.detail().raw().countryOfOrigin.data.iso;
            availablePlanService.trialPlan().promise.then(function(result){
                _trialPackage = result;
                _isPlanListLoading = false;
            },function(){
                _isPlanListLoading = false;
            });

            availablePlanService.prepaidEligible().promise.then(function(result){
                _isELigiblePrepaid = result;
            });

        })
    })

    return {
        vendor : vendormetaManager,
        currency : lowestPriceService.currency,
        lowestPrice : lowestPriceService.lowestPrice,
        isELigiblePrepaid : function(){
            return _isELigiblePrepaid;
        },
        isOrigin : function(){
            return _origin;
        },
        accountManager : function(){
            return _accountManager
        },
        trialPlan : function(){
            return _trialPackage[0];
        }
    }

}])
.controller('launchAppMobileFooterController', ['userManager', 'localStorageService', function(userManager, localStorageService){
	var _isClosed = localStorageService.cookie.get('mobileStickyLaunchApp') ? true : false;

	return {
		isVisible : function(){
			// return (userManager.isVendor() || !userManager.isLogged()) && !_isClosed
			return !_isClosed
		},
		isVisibleUser : function(){
			return (!userManager.isVendor())
			return !_isClosed
		},
		isClosed : function(val) {
			if(val === undefined)
				return _isClosed;
			_isClosed = val;
		},
        close: function(){
            localStorageService.cookie.set('mobileStickyLaunchApp', 1, 1);
            _isClosed = true;
        }
	}
}])
.controller('launchAppsBannerController', [ '$rootScope', '$http', '$filter', '$scope', 'notificationService', 'cookieServices', 'userManager', function($rootScope, $http, $filter, $scope, notificationService, cookieServices, userManager){
	$scope.userEmail = '';
	$scope.phoneAreaCode = [];
	$scope.phoneArea = [];
	$scope.userNumber = '';
    $scope.phoneArea.value = '+62';

    var self = this;
    var emailsscheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var numberscheck = new RegExp('^[0-9]+$');

	$scope.$watch(function(){ return userManager.raw()}, function(n){
	      $scope.vendorDetail = n;
	      if (n != false){
	      	$scope.phoneArea.value = '+'+$scope.vendorDetail.accountable.countryOfOrigin.phonecode;
            self.init();
	      }
	});
	this.init = function () {
		$http.get(Bridestory.api + '/v1/country').then(function (result) {
		    angular.forEach(result.data.country, function (value) {
		      	$scope.phoneAreaCode.push({ name: value.nicename + ' (' + value.phonecode + ')', value: '+' + value.phonecode, country: value.iso });
		    });
		});
	}

  	$scope.sendEmail = function(){
  		if ($scope.vendorDetail == false || $scope.vendorDetail.accountable.type == 'user'){
  			notificationService.topbarNotify(notificationService.message.APP_SEND_EMAIL_VENDOR_REQUIRED_ERROR, notificationService.type.ERROR);
  			return;
  		}

        if (!$scope.userEmail) {
            notificationService.topbarNotify(notificationService.message.APP_EMAIL_EMPTY_ERROR, notificationService.type.ERROR);
        }
        else if (!emailsscheck.test($scope.userEmail)) {
            notificationService.topbarNotify(notificationService.message.APP_EMAIL_INVALID_ERROR, notificationService.type.ERROR);
        }
	    else {
		    	$http({
	                method  : 'POST',
	                url     : Bridestory.api + '/v2/broadcast_link',
	                data  : $.param({
	                    messageType: "email",
	                    broadcastType: "bs_pro",
	                    recipients: $scope.userEmail
	                }),
	                headers : {
	                    'Content-Type': 'application/x-www-form-urlencoded',
	                    'Session-Id': cookieServices.getCookie('BSID')
	                }
	            }).then(function (result) {
	          	if(result){
		            jQuery('body,html').animate({
		              	scrollTop: 0
		            }, 800);
		            $('#userEmail').val('');
		            notificationService.topbarNotify(notificationService.message.APP_SEND_EMAIL_SUCCESS);
		            $scope.userEmail = null;

	          	}
	          	else {
		            notificationService.topbarNotify(notificationService.message.APP_SEND_SMS_ERROR, notificationService.type.ERROR);
		        }
	      	});

	      	/*data = { "messageType": "email", "broadcastType": "bs_pro", "recipients": $scope.userEmail };
	      	headers = {'Session-Id': cookieServices.getCookie('BSID')};
	      	$http.post(Bridestory.api + '/v2/broadcast_link', data, headers ).then(function (result) {
	          	if(result){
		            jQuery('body,html').animate({
		              	scrollTop: 0
		            }, 800);
		            notificationService.topbarNotify('A download link has been sent to your email!');
	          	}
	          	else {
		            jQuery('body,html').animate({
		             	scrollTop: 0
		            }, 800);
		            notificationService.topbarNotify('Failed send message', notificationService.type.ERROR);
		        }
	      	});*/
	    }
	}

  	$scope.sendSms = function(){
  		if ($scope.vendorDetail  == false || $scope.vendorDetail.accountable.type == 'user'){
  			notificationService.topbarNotify(notificationService.message.APP_SEND_SMS_VENDOR_REQUIRED_ERROR, notificationService.type.ERROR);
  			return;
  		}

        if (!$scope.userNumber) {
            notificationService.topbarNotify(notificationService.message.APP_PHONE_EMPTY_ERROR, notificationService.type.ERROR);
        }
        else if (!numberscheck.test($scope.userNumber)) {
            notificationService.topbarNotify(notificationService.message.APP_PHONE_INVALID_ERROR, notificationService.type.ERROR);
        }
        else if (!$scope.phoneAreaCode){
            notificationService.topbarNotify(notificationService.message.APP_PHONE_AREA_CODE_REQUIRED_ERROR, notificationService.type.ERROR);
            $('#userPhone').val('');
        }
    	else {

    		var number = $scope.phoneArea.value + $scope.userNumber;
    		$http({
                method  : 'POST',
                url     : Bridestory.api + '/v2/broadcast_link',
                data  : $.param({
                    messageType: "sms",
                    broadcastType: "bs_pro",
                    recipients: number
                }),
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Session-Id': cookieServices.getCookie('BSID')
                }
            }).then(
            	function (result) {
                    jQuery('body,html').animate({
                        scrollTop: 0
                    }, 800);
                    $('#userPhone').val('');
		            notificationService.topbarNotify(notificationService.message.APP_SEND_SMS_SUCCESS);
		            $scope.userNumber = null ;
	      		},
	      		function(error) {
		            notificationService.topbarNotify(notificationService.message.APP_SEND_EMAIL_ERROR, notificationService.type.ERROR);
			    }
	      	);

    		/*data = { "messageType": "sms", "broadcastType": "bs_pro", "recipients": $scope.userNumber };
       		headers = {'Session-Id': cookieServices.getCookie('BSID')};
       			$http.post(Bridestory.api + '/v2/broadcast_link', data, headers).then(function (result) {
	          	if(result){
		            jQuery('body,html').animate({
		              	scrollTop: 0
		            }, 800);
		            notificationService.topbarNotify('A download link has been sent via text message to your number!');
	          	}
	          	else {
		            jQuery('body,html').animate({
		             	scrollTop: 0
		            }, 800);
		            notificationService.topbarNotify('Failed send message', notificationService.type.ERROR);
		        }
	      	});*/
    	}
	}
}])
.controller('launchUserAppsController', [ '$rootScope', '$http', '$filter', '$scope', 'notificationService', 'cookieServices', 'userManager', function($rootScope, $http, $filter, $scope, notificationService, cookieServices, userManager){
    $scope.userEmail = '';
    $scope.phoneAreaCode = [];
    $scope.phoneArea = [];
    $scope.userNumber = '';
    $scope.phoneArea.value = '+62';
    $scope.emailFormat = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;

    var self = this;
    var numberscheck = new RegExp('^[0-9]+$');
    var emailsscheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.init = function () {
        $http.get(Bridestory.api + '/v1/country').then(function (result) {
            angular.forEach(result.data.country, function (value) {
                $scope.phoneAreaCode.push({ name: value.nicename + ' (' + value.phonecode + ')', value: '+' + value.phonecode, country: value.iso });
            });
        });
    }

    $scope.sendEmail = function(){
        if (!$scope.userEmail) {
            notificationService.topbarNotify(notificationService.message.APP_EMAIL_EMPTY_ERROR, notificationService.type.ERROR);
        }
        else if (!emailsscheck.test($scope.userEmail)) {
            notificationService.topbarNotify(notificationService.message.APP_EMAIL_INVALID_ERROR, notificationService.type.ERROR);
        }
        else {
                $http({
                    method  : 'POST',
                    url     : Bridestory.api + '/v2/broadcast_link',
                    data  : $.param({
                        messageType: "email",
                        broadcastType: "bs_user_app",
                        recipients: $scope.userEmail
                    }),
                    headers : {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Session-Id': cookieServices.getCookie('BSID')
                    }
                }).then(function (result) {
                if(result){
                    jQuery('body,html').animate({
                        scrollTop: 0
                    }, 800);
                    $('#userEmail').val('');
                    notificationService.topbarNotify(notificationService.message.APP_SEND_EMAIL_SUCCESS);
                    $scope.userEmail = null;

                }
                else {
                    notificationService.topbarNotify(notificationService.message.APP_SEND_EMAIL_ERROR, notificationService.type.ERROR);
                }
            });
        }
    }

    $scope.sendSms = function(){
        if (!$scope.userNumber) {
            notificationService.topbarNotify(notificationService.message.APP_PHONE_EMPTY_ERROR, notificationService.type.ERROR);
        }
        else if (!numberscheck.test($scope.userNumber)) {
            notificationService.topbarNotify(notificationService.message.APP_PHONE_INVALID_ERROR, notificationService.type.ERROR);
        }
        else if (!$scope.phoneAreaCode){
            notificationService.topbarNotify(notificationService.message.APP_PHONE_AREA_CODE_REQUIRED_ERROR, notificationService.type.ERROR);
            $('#userPhone').val('');
        }
        else {

            var number = $scope.phoneArea.value + $scope.userNumber;
            $http({
                method  : 'POST',
                url     : Bridestory.api + '/v2/broadcast_link',
                data  : $.param({
                    messageType: "sms",
                    broadcastType: "bs_user_app",
                    recipients: number,
                    link: "http://bride.st/1pZrHdx"
                }),
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Session-Id': cookieServices.getCookie('BSID')
                }
            }).then(
                function (result) {
                    jQuery('body,html').animate({
                        scrollTop: 0
                    }, 800);
                    $('#userPhone').val('');
                    notificationService.topbarNotify(notificationService.message.APP_SEND_SMS_SUCCESS);
                    $scope.userNumber = null ;
                },
                function(error) {
                    notificationService.topbarNotify(notificationService.message.APP_SEND_SMS_ERROR, notificationService.type.ERROR);
                }
            );
        }
    }
}])
.directive('numbersOnly', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attr, ngModelCtrl) {
        function fromUser(text) {
          if (text) {
            var transformedInput = text.replace(/[^0-9]/g, '');
            transformedInput = transformedInput.replace(/^0+/, '');

            if (transformedInput !== text) {
              ngModelCtrl.$setViewValue(transformedInput);
              ngModelCtrl.$render();
            }
            return transformedInput;
          }
          return undefined;
        }
        ngModelCtrl.$parsers.push(fromUser);
      }
    };
});
