'use strict';

angular
    .module('bridestoryApp.menuApp', ['bridestoryApp.autoCompleteApp'])
    .run(['$document', function($document){
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            var body = $document.find('body').eq(0);
           $document.on('focus', 'body > div input,body > div textarea', function(e) {
                body.addClass('chromefixfixed');
            })
            .on('blur', 'input, textarea', function(){
                body.removeClass('chromefixfixed');
            })
        }
    }]);