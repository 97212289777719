function AnalyticService(AnalyticFactoryGA, AnalyticFactoryGAdwords, userManager) {
	this.pageTrack = function(provider) {
		if(provider == 'GA')
			AnalyticFactoryGA.pageTrack();
	};

	this.pageTrackVirtual = function(uri, provider) {
		if(provider == 'GA')
			AnalyticFactoryGA.pageTrackVirtual(uri);
	};

  this.eventTrack = function (eventName, properties) {
    if (properties.category == 'leads') {
      if (!userManager.isVendor()) {
        AnalyticFactoryGA.eventTrack(eventName, properties);
      }
    } else {
      AnalyticFactoryGA.eventTrack(eventName, properties);
    }

    if (properties.category == 'leads' && !userManager.isVendor()) {
      AnalyticFactoryGAdwords.trackFacebookAds();
      AnalyticFactoryGAdwords.trackGoogleAdwords();
      AnalyticFactoryGAdwords.trackYahooConversion();

      if(Bridestory.localized_bucket == 'ID' || Bridestory.localized_bucket == 'default') {
        AnalyticFactoryGAdwords.trackSociomanticConversion();
      }
    };
  };

  this.sendECommerceTransaction = function(transactionId, affiliation, revenue, shippping, tax, items, currency) {
    AnalyticFactoryGA.loadECommerce();
    AnalyticFactoryGA.addECommerceTransaction(transactionId, affiliation, revenue, shippping, tax, currency);

    for(var i=0;i<items.length;i++) {
      var item = items[i];
      AnalyticFactoryGA.addECommerceItem(transactionId, item.name, item.sku, item.category, item.price, item.quantity);
    }
    AnalyticFactoryGA.sendECommerce();
    AnalyticFactoryGA.clearECommerce();
  };
}

angular
	.module('bridestoryApp.analyticApp')
	.service('AnalyticService', [ 'AnalyticFactoryGA', 'AnalyticFactoryGAdwords', 'userManager', AnalyticService ]);