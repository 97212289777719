'use strict';

function LoginSocialMediaController($rootScope, $scope, $location, LoginSocialMediaService, LoginService, cookieServices,userManager,launchAppFactory,launchAppService, valPromoService, videoEssentialService, masterclassPromo) {
	this.is_login   = false;
	this.is_loading = false;
	this.facebook   = { email: '' };
	this.google     = { email: '' };

	var _updateUser = function(){
		userManager.refresh().then(function(){
			if(userManager.isVendor()) {
				launchAppFactory.setOpenLaunchAppModal(1);
				masterclassPromo.open().then(angular.noop(), function(){
					videoEssentialService.open().then(angular.noop(), function(reason){
						if(isMobile && isMobile.any()) launchAppService.init();
					});
				});
			}
		});
	}

	var self = this;

	this.facebook_login = function(redirect_to)
	{
		LoginSocialMediaService.facebook_login().then(function(response) {
			if(response.status == 403) {
		        LoginService.openLogin('facebook_not_found');
		        self.is_loading = false;
			}
			else {
				cookieServices.setCookies(response.sessionId);
				$rootScope.$broadcast("checkLogin", { data: response });
				_updateUser();
				self.is_login = true;
				if(angular.isDefined(redirect_to)) {
					$location.path( redirect_to );
				}
			}
		});
	}

	this.google_login = function(redirect_to)
	{
		LoginSocialMediaService.google_login().then(function(response) {
			if(response.status == 403) {
		        LoginService.openLogin('full_name_not_found');
		        self.is_loading = false;
			}
			else {
				cookieServices.setCookies(response.sessionId);
				$rootScope.$broadcast("checkLogin", { data: response });
				_updateUser();
				self.is_login = true;
				if(angular.isDefined(redirect_to)) {
					$location.path( redirect_to );
				}
			}
		});
	}

	this.yahoo_login = function(redirect_to)
	{
		LoginSocialMediaService.yahoo_login().then(function(data) {
			cookieServices.setCookies(data.sessionId);
			$rootScope.$broadcast("checkLogin", { data: data });
			_updateUser();
			self.is_login = true;
			if(angular.isDefined(redirect_to)) {
				$location.path( redirect_to );
			}
		}, function(error) {

		});
	}

	this.hideLoginRegister = function() {
		self.is_login = true;
	}

	$scope.$on("checkLogin", function (event, args) {
		self.hideLoginRegister();
	});
}

angular
	.module('bridestoryApp.newLoginApp')
	.controller('LoginSocialMediaController', [ '$rootScope', '$scope', '$location', 'LoginSocialMediaService', 'LoginService', 'cookieServices','userManager','launchAppFactory','launchAppService', 'valPromoService', 'videoEssentialService', 'masterclassPromo', LoginSocialMediaController ]);