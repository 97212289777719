'use strict';

angular
  .module('bridestoryApp.vendorSearchAppMobile',['bridestoryApp.vendorSearchApp'])
  .filter('array-filter', function() {
    return function(arrayData) {
      var out = [];

      angular.forEach(arrayData, function(e, i){
        if (e) { out.push(i)};
      });

      return out;
    }
  });