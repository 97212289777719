function notificationMessage() {
	this.BridestoryTrans = BridestoryTrans;

	this.WARNING_DELETE_TAGGED_PROJECT = BridestoryTrans[language].notifications.WARNING_DELETE_TAGGED_PROJECT;
    this.WARNING_DELETE_PROJECT = BridestoryTrans[language].notifications.WARNING_DELETE_PROJECT;
    this.WARNING_DELETE_DEALS = BridestoryTrans[language].notifications.WARNING_DELETE_DEALS;
    this.WARNING_DELETE_REALWEDDING = BridestoryTrans[language].notifications.WARNING_DELETE_REALWEDDING;
    this.WARNING_DELETE_PRICELIST = BridestoryTrans[language].notifications.WARNING_DELETE_PRICELIST;
    this.WARNING_DELETE_CITY = BridestoryTrans[language].notifications.WARNING_DELETE_CITY;
    this.WARNING_DELETE_FAQ_INFO = BridestoryTrans[language].notifications.WARNING_DELETE_FAQ_INFO;
    this.WARNING_NOT_DO_MENTION = BridestoryTrans[language].notifications.WARNING_NOT_DO_MENTION;
    this.WARNING_DELETE_ALL_PHOTOS = BridestoryTrans[language].notifications.WARNING_DELETE_ALL_PHOTOS;
    this.WARNING_DELETE_SELECTED_PHOTO = BridestoryTrans[language].notifications.WARNING_DELETE_SELECTED_PHOTO;
    this.WARNING_DELETE_SELECTED_VIDEO = BridestoryTrans[language].notifications.WARNING_DELETE_SELECTED_VIDEO;
    this.WARNING_DELETE_ME_FROM_TAGGED = BridestoryTrans[language].notifications.WARNING_DELETE_ME_FROM_TAGGED;
    this.WARNING_NOT_DO_TAGGING = BridestoryTrans[language].notifications.WARNING_NOT_DO_TAGGING;
    this.WARNING_DELETE_REVIEW = BridestoryTrans[language].notifications.WARNING_DELETE_REVIEW;
    this.WARNING_DELETE_REPLY_TITLE = BridestoryTrans[language].notifications.WARNING_DELETE_REPLY_TITLE;
    this.WARNING_DELETE_REPLY_INFO = BridestoryTrans[language].notifications.WARNING_DELETE_REPLY_INFO;
	this.WARNING_DELETE_SUBMISSION = BridestoryTrans[language].notifications.WARNING_DELETE_SUBMISSION;
	this.WARNING_CANCEL_TRANSACTION = BridestoryTrans[language].notifications.WARNING_CANCEL_TRANSACTION;

    this.ERROR_PROJECT_WORKS_EMPTY = BridestoryTrans[language].notifications.ERROR_PROJECT_WORKS_EMPTY;
    this.ERROR_PROJECT_TITLE_EMPTY = BridestoryTrans[language].notifications.ERROR_PROJECT_TITLE_EMPTY;
    this.ERROR_FAIL_DELETE_PROJECT = BridestoryTrans[language].notifications.ERROR_FAIL_DELETE_PROJECT;
    this.ERROR_FAIL_UPDATE_PROJECT = BridestoryTrans[language].notifications.ERROR_FAIL_UPDATE_PROJECT;
    this.ERROR_FAIL_SET_BUSINESS_CONTACT = BridestoryTrans[language].notifications.ERROR_FAIL_SET_BUSINESS_CONTACT;
    this.ERROR_FAIL_SET_PRIMARY_CONTACT = BridestoryTrans[language].notifications.ERROR_FAIL_SET_PRIMARY_CONTACT;
    this.ERROR_INVALID_PASSWORD = BridestoryTrans[language].notifications.ERROR_INVALID_PASSWORD;
    this.ERROR_FAIL_UPDATE_PASSWORD = BridestoryTrans[language].notifications.ERROR_FAIL_UPDATE_PASSWORD;
    this.ERROR_FAIL_DEACTIVATE_ACCOUNT = BridestoryTrans[language].notifications.ERROR_FAIL_DEACTIVATE_ACCOUNT;
    this.ERROR_FAIL_UPDATE_EMAIL_SETTING = BridestoryTrans[language].notifications.ERROR_FAIL_UPDATE_EMAIL_SETTING;
    this.ERROR_FAIL_DELETE_CONTACT = BridestoryTrans[language].notifications.ERROR_FAIL_DELETE_CONTACT;
    this.ERROR_FAIL_DELETE_BANK_ACCOUNT = BridestoryTrans[language].notifications.ERROR_FAIL_DELETE_BANK_ACCOUNT;
    this.ERROR_FAIL_ADD_CONTACT = BridestoryTrans[language].notifications.ERROR_FAIL_ADD_CONTACT;
    this.ERROR_FAIL_SEND_REQUEST_CONFIRMATION = BridestoryTrans[language].notifications.ERROR_FAIL_SEND_REQUEST_CONFIRMATION;
    this.ERROR_FAIL_UPDATE_PROFILE_PICTURE = BridestoryTrans[language].notifications.ERROR_FAIL_UPDATE_PROFILE_PICTURE;
    this.ERROR_FAIL_UPDATE_BUSINESS_INFO = BridestoryTrans[language].notifications.ERROR_FAIL_UPDATE_BUSINESS_INFO;
    this.ERROR_FAIL_ADD_CONTACT_SOCMED = BridestoryTrans[language].notifications.ERROR_FAIL_ADD_CONTACT_SOCMED;
    this.ERROR_FAIL_DELETE_SERVICE = BridestoryTrans[language].notifications.ERROR_FAIL_DELETE_SERVICE;
    this.ERROR_FAIL_UPDATE_EMAIL = BridestoryTrans[language].notifications.ERROR_FAIL_UPDATE_EMAIL;
    this.ERROR_FAIL_SEND_MESSAGE = BridestoryTrans[language].notifications.ERROR_FAIL_SEND_MESSAGE;
    this.ERROR_NOT_ENOUGH_PHOTO = BridestoryTrans[language].notifications.ERROR_NOT_ENOUGH_PHOTO;
    this.ERROR_NOT_ENOUGH_PHOTO_REALWEDDING = BridestoryTrans[language].notifications.ERROR_NOT_ENOUGH_PHOTO_REALWEDDING;
    this.ERROR_FAIL_SEND_REVIEW = BridestoryTrans[language].notifications.ERROR_FAIL_SEND_REVIEW;
    this.ERROR_FAIL_DELETE_REVIEW = BridestoryTrans[language].notifications.ERROR_FAIL_DELETE_REVIEW;
    this.ERROR_FAIL_REQUEST_REVIEW = BridestoryTrans[language].notifications.ERROR_FAIL_REQUEST_REVIEW;
    this.ERROR_FAIL_LOGIN_UNKNOWN_REASON = BridestoryTrans[language].notifications.ERROR_FAIL_LOGIN_UNKNOWN_REASON;
    this.ERROR_FAIL_REMOVE_REALWEDDING_SUBMISSION = BridestoryTrans[language].notifications.ERROR_FAIL_REMOVE_REALWEDDING_SUBMISSION;
    this.ERROR_SOME_VENDORS_INVALID = BridestoryTrans[language].notifications.ERROR_SOME_VENDORS_INVALID;
    this.ERROR_FAIL_SEND_COMMENT = BridestoryTrans[language].notifications.ERROR_FAIL_SEND_COMMENT;
    this.ERROR_FAIL_DELETE_COMMENT = BridestoryTrans[language].notifications.ERROR_FAIL_DELETE_COMMENT;
    this.ERROR_CANT_REVIEW_OWN_VENDOR = BridestoryTrans[language].notifications.ERROR_CANT_REVIEW_OWN_VENDOR;

    this.INFO_SUCCESS_DELETE_COMMENT = BridestoryTrans[language].notifications.INFO_SUCCESS_DELETE_COMMENT;
    this.INFO_SUCCESS_SEND_COMMENT = BridestoryTrans[language].notifications.INFO_SUCCESS_SEND_COMMENT;
    this.INFO_SUCCESS_REMOVE_REALWEDDING_SUBMISSION = BridestoryTrans[language].notifications.INFO_SUCCESS_REMOVE_REALWEDDING_SUBMISSION;
    this.INFO_SUCCESS_UPDATE_REVIEW = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_REVIEW;
    this.INFO_SUCCESS_SEND_REVIEW = BridestoryTrans[language].notifications.INFO_SUCCESS_SEND_REVIEW;
    this.INFO_SUCCESS_SEND_REVIEW_REPLY = BridestoryTrans[language].notifications.INFO_SUCCESS_SEND_REVIEW_REPLY;
    this.INFO_SUCCESS_DELETE_REVIEW_REPLY = BridestoryTrans[language].notifications.INFO_SUCCESS_DELETE_REVIEW_REPLY;
    this.INFO_SUCCESS_REQUEST_REVIEW = BridestoryTrans[language].notifications.INFO_SUCCESS_REQUEST_REVIEW;
    this.INFO_SUCCESS_DELETE_REVIEW = BridestoryTrans[language].notifications.INFO_SUCCESS_DELETE_REVIEW;
    this.INFO_SUCCESS_SAVE_VENDOR = BridestoryTrans[language].notifications.INFO_SUCCESS_SAVE_VENDOR;
    this.INFO_SUCCESS_UNSAVE_VENDOR = BridestoryTrans[language].notifications.INFO_SUCCESS_UNSAVE_VENDOR;
    this.INFO_SUCCESS_SEND_MESSAGE = BridestoryTrans[language].notifications.INFO_SUCCESS_SEND_MESSAGE;
    this.INFO_SUCCESS_DELETE_SERVICE = BridestoryTrans[language].notifications.INFO_SUCCESS_DELETE_SERVICE;
    this.INFO_SUCCESS_UPDATE_SERVICE = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_SERVICE;
    this.INFO_SUCCESS_UPDATE_EMAIL = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_EMAIL;
    this.INFO_SUCCESS_UPDATE_BUSINESS_INFO = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_BUSINESS_INFO;
    this.INFO_SUCCESS_UPDATE_PROFILE_PICTURE = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_PROFILE_PICTURE;
    this.INFO_SUCCESS_ADD_CONTACT = BridestoryTrans[language].notifications.INFO_SUCCESS_ADD_CONTACT;
    this.INFO_SUCCESS_ADD_BANK_ACCOUNT = BridestoryTrans[language].notifications.INFO_SUCCESS_ADD_BANK_ACCOUNT;
    this.INFO_SUCESS_SET_PRIMARY_BANK_ACCOUNT_1 = BridestoryTrans[language].notifications.INFO_SUCESS_SET_PRIMARY_BANK_ACCOUNT_1;
    this.INFO_SUCESS_SET_PRIMARY_BANK_ACCOUNT_2 = BridestoryTrans[language].notifications.INFO_SUCESS_SET_PRIMARY_BANK_ACCOUNT_2;
    this.INFO_SUCCESS_DELETE_CONTACT = BridestoryTrans[language].notifications.INFO_SUCCESS_DELETE_CONTACT;
    this.INFO_SUCCESS_DELETE_BANK_ACCOUNT = BridestoryTrans[language].notifications.INFO_SUCCESS_DELETE_BANK_ACCOUNT;
    this.INFO_SUCCESS_ADD_CONTACT_SOCMED = BridestoryTrans[language].notifications.INFO_SUCCESS_ADD_CONTACT_SOCMED;
    this.INFO_SUCESS_DELETE_PROJECT = BridestoryTrans[language].notifications.INFO_SUCESS_DELETE_PROJECT;
    this.INFO_SUCCESS_UPDATE_PROJECT = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_PROJECT;
    this.INFO_SUCCESS_UPDATE_PASSWORD = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_PASSWORD;
    this.INFO_SUCCESS_DEACTIVATE_ACCOUNT = BridestoryTrans[language].notifications.INFO_SUCCESS_DEACTIVATE_ACCOUNT;
    this.INFO_SUCCESS_UPDATE_EMAIL_SETTING = BridestoryTrans[language].notifications.INFO_SUCCESS_UPDATE_EMAIL_SETTING;
    this.INFO_SUCCESS_SAVE_DRAFT = BridestoryTrans[language].notifications.INFO_SUCCESS_SAVE_DRAFT;
    this.INFO_SUCCESS_EMAIL_BADGE = BridestoryTrans[language].notifications.INFO_SUCCESS_EMAIL_BADGE;
    this.INFO_SUCCESS_REPORT_REVIEW = BridestoryTrans[language].notifications.INFO_SUCCESS_REPORT_REVIEW;

    this.ERROR_SAVE_VENDOR = BridestoryTrans[language].notifications.ERROR_SAVE_VENDOR;
    this.SUCCESS_UNTAG_VENDOR = BridestoryTrans[language].notifications.SUCCESS_UNTAG_VENDOR;
    this.ERROR_UNTAG_VENDOR = BridestoryTrans[language].notifications.ERROR_UNTAG_VENDOR;
    this.ERROR_REPORT_VENDOR_OWN = BridestoryTrans[language].notifications.ERROR_REPORT_VENDOR_OWN;
    this.ERROR_REPORT_REVIEW_OWN = BridestoryTrans[language].notifications.ERROR_REPORT_REVIEW_OWN;
    this.ERROR_REPORT_PROJECT_OWN = BridestoryTrans[language].notifications.ERROR_REPORT_PROJECT_OWN;
    this.SUCCESS_REPORT = BridestoryTrans[language].notifications.SUCCESS_REPORT;
    this.ERROR_REPORT = BridestoryTrans[language].notifications.ERROR_REPORT;
	this.ERROR_MY_PROJECT_EMPTY = BridestoryTrans[language].notifications.ERROR_MY_PROJECT_EMPTY;
	this.ERROR_WRONG_MOBILE_VERIFICATION_CODE = BridestoryTrans[language].notifications.ERROR_WRONG_MOBILE_VERIFICATION_CODE;
	this.ERROR_FAIL_RESEND_VERIFICATION_CODE = BridestoryTrans[language].notifications.ERROR_FAIL_RESEND_VERIFICATION_CODE;
	this.ERROR_CANT_REPORT_OWN_REVIEW = BridestoryTrans[language].notifications.ERROR_CANT_REPORT_OWN_REVIEW;
	this.ERROR_FAIL_REPORT_REVIEW = BridestoryTrans[language].notifications.ERROR_FAIL_REPORT_REVIEW;
	this.ERROR_NOTIF = BridestoryTrans[language].notifications.ERROR_NOTIF;
    this.SUCCESS_VERIFICATION_CODE_RESEND = BridestoryTrans[language].notifications.SUCCESS_VERIFICATION_CODE_RESEND;
    this.SUCCESS_VERIFIED_MOBILE_NUMBER = BridestoryTrans[language].notifications.SUCCESS_VERIFIED_MOBILE_NUMBER;
    this.SUCCESS_ADD_PRICELIST = BridestoryTrans[language].notifications.SUCCESS_ADD_PRICELIST;
    this.OPTION_DELETE_REVIEW_YES = BridestoryTrans[language].notifications.OPTION_DELETE_REVIEW_YES;
    this.OPTION_DONT_DELETE = BridestoryTrans[language].notifications.OPTION_DONT_DELETE;
    this.INFO_SUCCESS_COPY_BADGE = BridestoryTrans[language].notifications.INFO_SUCCESS_COPY_BADGE;
    this.ERROR_FAIL_COPY_BADGE = BridestoryTrans[language].notifications.ERROR_FAIL_COPY_BADGE;
    this.ERROR_FAIL_WRONG_FILETYPE = BridestoryTrans[language].notifications.ERROR_FAIL_WRONG_FILETYPE;
    this.ERROR_IG_EXIST = BridestoryTrans[language].notifications.ERROR_IG_EXIST;
    this.SUCCESS_IG_CONNECT = BridestoryTrans[language].notifications.SUCCESS_IG_CONNECT;
    this.ERROR_IG_CONNECT = BridestoryTrans[language].notifications.ERROR_IG_CONNECT;
    this.ERROR_IG_NOTFOUND = BridestoryTrans[language].notifications.ERROR_IG_NOTFOUND;
    this.INFO_IG_CHANGE = BridestoryTrans[language].notifications.INFO_IG_CHANGE;
    this.INFO_IG_CHANGE_PROMPT = BridestoryTrans[language].notifications.INFO_IG_CHANGE_PROMPT;
    this.INFO_IG_CHANGE_SUCCESS = BridestoryTrans[language].notifications.INFO_IG_CHANGE_SUCCESS;
    this.PROMPT_CHANGE = BridestoryTrans[language].notifications.PROMPT_CHANGE;
    this.PROMPT_CANCEL = BridestoryTrans[language].notifications.PROMPT_CANCEL;
    this.ERROR_IG_FAIL = BridestoryTrans[language].notifications.ERROR_IG_FAIL;
    this.INFO_SOCIALSTRENGTH_FB = BridestoryTrans[language].notifications.INFO_SOCIALSTRENGTH_FB;
    this.INFO_SOCIALSTRENGTH_IG = BridestoryTrans[language].notifications.INFO_SOCIALSTRENGTH_IG;
    this.BLOG_COMMENT_SUBMITTED = BridestoryTrans[language].notifications.BLOG_COMMENT_SUBMITTED;
    this.BLOG_COMMENT_DELETED = BridestoryTrans[language].notifications.BLOG_COMMENT_DELETED;
    this.APP_SEND_EMAIL_VENDOR_REQUIRED_ERROR = BridestoryTrans[language].notifications.APP_SEND_EMAIL_VENDOR_REQUIRED_ERROR;
    this.APP_EMAIL_EMPTY_ERROR = BridestoryTrans[language].notifications.APP_EMAIL_EMPTY_ERROR;
    this.APP_EMAIL_INVALID_ERROR = BridestoryTrans[language].notifications.APP_EMAIL_INVALID_ERROR;
    this.APP_SEND_EMAIL_SUCCESS = BridestoryTrans[language].notifications.APP_SEND_EMAIL_SUCCESS;
    this.APP_SEND_EMAIL_ERROR = BridestoryTrans[language].notifications.APP_SEND_EMAIL_ERROR;
    this.APP_SEND_SMS_VENDOR_REQUIRED_ERROR = BridestoryTrans[language].notifications.APP_SEND_SMS_VENDOR_REQUIRED_ERROR;
    this.APP_PHONE_AREA_CODE_REQUIRED_ERROR = BridestoryTrans[language].notifications.APP_PHONE_AREA_CODE_REQUIRED_ERROR;
    this.APP_PHONE_EMPTY_ERROR = BridestoryTrans[language].notifications.APP_PHONE_EMPTY_ERROR;
    this.APP_PHONE_INVALID_ERROR = BridestoryTrans[language].notifications.APP_PHONE_INVALID_ERROR;
    this.APP_SEND_SMS_SUCCESS = BridestoryTrans[language].notifications.APP_SEND_SMS_SUCCESS;
    this.APP_SEND_SMS_ERROR = BridestoryTrans[language].notifications.APP_SEND_SMS_ERROR;
    this.LOGIN_NOT_VERIFIED_ERROR = BridestoryTrans[language].notifications.LOGIN_NOT_VERIFIED_ERROR;
    this.LOGIN_FORGOT_SUCCESS = BridestoryTrans[language].notifications.LOGIN_FORGOT_SUCCESS;
    this.LOGIN_RESEND_VERIFICATION_SUCCESS = BridestoryTrans[language].notifications.LOGIN_RESEND_VERIFICATION_SUCCESS;
    this.LOGIN_RESEND_VERIFICATION_ERROR = BridestoryTrans[language].notifications.LOGIN_RESEND_VERIFICATION_ERROR;
    this.LOGIN_ERROR = BridestoryTrans[language].notifications.LOGIN_ERROR;

    this.MEMBER_SETTINGS_UPDATE_SUCCESS = BridestoryTrans[language].notifications.link_notification_update_success;
    this.MEMBER_SETTINGS_UPDATE_FAILED = BridestoryTrans[language].notifications.link_notification_update_failed;
    this.MEMBER_SETTINGS_FB_CONNECT = BridestoryTrans[language].notifications.link_notification_fb_connect;
    this.MEMBER_SETTINGS_FB_CONNECT_FAILED = BridestoryTrans[language].notifications.link_notification_fb_connect_failed;
    this.MEMBER_SETTINGS_FB_CONNECT_FAILED2 = BridestoryTrans[language].notifications.link_notification_fb_connect_failed2;
    this.MEMBER_SETTINGS_UNSUPPORTED_FILE = BridestoryTrans[language].notifications.unsupported_file;
    this.MEMBER_SETTINGS_INVALID_PASSWORD = BridestoryTrans[language].notifications.invalid_password;
    this.MEMBER_SETTINGS_FAIL_UPDATE_PASSWORD = BridestoryTrans[language].notifications.fail_update_password;

    this.MEMBER_DELETE_REVIEW_TITLE = BridestoryTrans[language].notifications.MEMBER_DELETE_REVIEW_TITLE;
    this.MEMBER_DELETE_REVIEW_BUTTON = BridestoryTrans[language].notifications.MEMBER_DELETE_REVIEW_BUTTON;
    this.MEMBER_DELETE_REVIEW_BUTTON_DONT = BridestoryTrans[language].notifications.MEMBER_DELETE_REVIEW_BUTTON_DONT;
    this.MEMBER_REPORT_REVIEW_SUBMITTED = BridestoryTrans[language].notifications.MEMBER_REPORT_REVIEW_SUBMITTED;
    this.MEMBER_REPORT_REVIEW_CANT = BridestoryTrans[language].notifications.MEMBER_REPORT_REVIEW_CANT;
    this.MEMBER_REPORT_REVIEW_FAILED = BridestoryTrans[language].notifications.MEMBER_REPORT_REVIEW_FAILED;

    this.MEMBER_CHANGE_PASS_UPDATE = BridestoryTrans[language].notifications.MEMBER_CHANGE_PASS_UPDATE;
    this.MEMBER_CHANGE_PASS_INVALID = BridestoryTrans[language].notifications.MEMBER_CHANGE_PASS_INVALID;
    this.MEMBER_CHANGE_PASS_FAILED = BridestoryTrans[language].notifications.MEMBER_CHANGE_PASS_FAILED;

    this.MAGAZINE_UPLOAD_SAVE = BridestoryTrans[language].notifications.MAGAZINE_UPLOAD_SAVE;

	this.DECORATIVE_LIMIT_UPLOAD_MESSAGE1 = BridestoryTrans[language].notifications.DECORATIVE_LIMIT_UPLOAD_MESSAGE1;
    this.DECORATIVE_LIMIT_UPLOAD_MESSAGE2 = BridestoryTrans[language].notifications.DECORATIVE_LIMIT_UPLOAD_MESSAGE2;
    this.DECORATIVE_LIMIT_UPLOAD_LABEL_PHOTOS1= BridestoryTrans[language].notifications.DECORATIVE_LIMIT_UPLOAD_LABEL_PHOTOS1;
    this.DECORATIVE_LIMIT_UPLOAD_LABEL_PHOTOS2= BridestoryTrans[language].notifications.DECORATIVE_LIMIT_UPLOAD_LABEL_PHOTOS2;
    this.DECORATIVE_LIMIT_UPLOAD_TITLE = BridestoryTrans[language].notifications.DECORATIVE_LIMIT_UPLOAD_TITLE;
    this.DECORATIVE_LIMIT_UPLOAD_BUTTON_OK = BridestoryTrans[language].notifications.DECORATIVE_LIMIT_UPLOAD_BUTTON_OK;

    this.EDIT_PROJECT_BUTTON_DELETE = BridestoryTrans[language].notifications.EDIT_PROJECT_BUTTON_DELETE;
    this.EDIT_PROJECT_BUTTON_SAVE_CHANGES = BridestoryTrans[language].notifications.EDIT_PROJECT_BUTTON_SAVE_CHANGES;
    this.EDIT_PROJECT_BUTTON_TAG = BridestoryTrans[language].notifications.EDIT_PROJECT_BUTTON_TAG;

    this.EDIT_TAG_ALERT_CLOSE = BridestoryTrans[language].notifications.EDIT_TAG_ALERT_CLOSE;
    this.OPTION_SAVE = BridestoryTrans[language].notifications.OPTION_SAVE;
    this.OPTION_DONT_SAVE = BridestoryTrans[language].notifications.OPTION_DONT_SAVE;

    this.MESSAGE_SELECT_ALERT = BridestoryTrans[language].notifications.MESSAGE_SELECT_ALERT;
    this.MESSAGE_CONFIRM = BridestoryTrans[language].notifications.MESSAGE_CONFIRM;
    this.MESSAGE_INVALID_RECIPIENT_ERROR = BridestoryTrans[language].notifications.MESSAGE_INVALID_RECIPIENT_ERROR;

    this.PLAN_WARNING_UNDER_REVIEW = BridestoryTrans[language].notifications.PLAN_WARNING_UNDER_REVIEW;
    this.PLAN_WARNING_PENDING_PAYMENT = BridestoryTrans[language].notifications.PLAN_WARNING_PENDING_PAYMENT;
    this.PLAN_WARNING_PROCEED = BridestoryTrans[language].notifications.PLAN_WARNING_PROCEED;
    this.PLAN_WARNING_PROCEED_TRIAL = BridestoryTrans[language].notifications.PLAN_WARNING_PROCEED_TRIAL;
    this.PLAN_WARNING_PENDING_CATEGORY = BridestoryTrans[language].notifications.PLAN_WARNING_PENDING_CATEGORY;
    this.PLAN_WARNING_PENDING_CATEGORY_TRIAL = BridestoryTrans[language].notifications.PLAN_WARNING_PENDING_CATEGORY_TRIAL;

    this.PLAN_WARNING_NEED_PURCHASE_TITLE = BridestoryTrans[language].notifications.PLAN_WARNING_NEED_PURCHASE_TITLE;
    this.PLAN_WARNING_NEED_PURCHASE_MESSAGE = BridestoryTrans[language].notifications.PLAN_WARNING_NEED_PURCHASE_MESSAGE;

    this.PLAN_WARNING_ERROR_PROCESSING_PAYMENT = BridestoryTrans[language].notifications.PLAN_WARNING_ERROR_PROCESSING_PAYMENT;
    this.PLAN_WARNING_CREDIT_CARD_EXPIRED = BridestoryTrans[language].notifications.PLAN_WARNING_CREDIT_CARD_EXPIRED;

    this.PLAN_WARNING_DELETE_PENDING_TRANSACTION_TITLE = BridestoryTrans[language].notifications.PLAN_WARNING_DELETE_PENDING_TRANSACTION_TITLE;
    this.PLAN_WARNING_DELETE_PENDING_TRANSACTION_CONTENT_1 = BridestoryTrans[language].notifications.PLAN_WARNING_DELETE_PENDING_TRANSACTION_CONTENT_1;
    this.PLAN_WARNING_DELETE_PENDING_TRANSACTION_CONTENT_2 = BridestoryTrans[language].notifications.PLAN_WARNING_DELETE_PENDING_TRANSACTION_CONTENT_2;
    this.PLAN_WARNING_REMOVE_CATEGORY_TITLE = BridestoryTrans[language].notifications.PLAN_WARNING_REMOVE_CATEGORY_TITLE;
    this.PLAN_WARNING_REMOVE_CATEGORY_CONTENT = BridestoryTrans[language].notifications.PLAN_WARNING_REMOVE_CATEGORY_CONTENT;
    this.PLAN_WARNING_REMOVE_CATEGORY_MESSAGE = BridestoryTrans[language].notifications.PLAN_WARNING_REMOVE_CATEGORY_MESSAGE;
    this.PLAN_WARNING_REMOVE_CATEGORY_PURCHASE = BridestoryTrans[language].notifications.PLAN_WARNING_REMOVE_CATEGORY_PURCHASE;

    this.PLAN_BUTTON_YES = BridestoryTrans[language].notifications.PLAN_BUTTON_YES;
    this.PLAN_BUTTON_OK = BridestoryTrans[language].notifications.PLAN_BUTTON_OK;
    this.PLAN_BUTTON_CANCEL = BridestoryTrans[language].notifications.PLAN_BUTTON_CANCEL;
    this.PLAN_BUTTON_CONFIRM_PAYMENT = BridestoryTrans[language].notifications.PLAN_BUTTON_CONFIRM_PAYMENT;
    this.PLAN_BUTTON_DO_LATER = BridestoryTrans[language].notifications.PLAN_BUTTON_DO_LATER;
    this.PLAN_BUTTON_UNDERSTAND = BridestoryTrans[language].notifications.PLAN_BUTTON_UNDERSTAND;

    this.PLAN_BUTTON_PENDING_PAYMENT = BridestoryTrans[language].notifications.PLAN_BUTTON_PENDING_PAYMENT;
    this.PLAN_BUTTON_AWAITION_VERIFICATION = BridestoryTrans[language].notifications.PLAN_BUTTON_AWAITION_VERIFICATION;
    this.PLAN_BUTTON_PENDING_VERIFICATION = BridestoryTrans[language].notifications.PLAN_BUTTON_PENDING_VERIFICATION;
    this.PLAN_BUTTON_EXPIRING_SOON = BridestoryTrans[language].notifications.PLAN_BUTTON_EXPIRING_SOON;
    this.PLAN_BUTTON_EXTEND = BridestoryTrans[language].notifications.PLAN_BUTTON_EXTEND;
    this.PLAN_BUTTON_EXPIRED = BridestoryTrans[language].notifications.PLAN_BUTTON_EXPIRED;
    this.PLAN_BUTTON_SUBSCRIBE = BridestoryTrans[language].notifications.PLAN_BUTTON_SUBSCRIBE;
    this.PLAN_BUTTON_ACTIVE = BridestoryTrans[language].notifications.PLAN_BUTTON_ACTIVE;

    this.PLAN_TRANSACTION_EXPIRED = BridestoryTrans[language].notifications.PLAN_TRANSACTION_EXPIRED;

    this.PRICELIST_BUTTON_DELETE = BridestoryTrans[language].notifications.PRICELIST_BUTTON_DELETE;
    this.BUTTON_DELETE_SUBMISSION = BridestoryTrans[language].notifications.BUTTON_DELETE_SUBMISSION;

	//Insert New Variable Here
	this.EDIT_PROJECT_BUTTON_SAVE_CHANGES = BridestoryTrans[language].notifications.EDIT_PROJECT_BUTTON_SAVE_CHANGES;
    this.EDIT_PROJECT_BUTTON_TAG = BridestoryTrans[language].notifications.EDIT_PROJECT_BUTTON_TAG;
	this.ADD_PROJECT_TAG_MESSAGE = BridestoryTrans[language].notifications.ADD_PROJECT_TAG_MESSAGE;
	this.ADD_PROJECT_TAG_BUTTON_YES = BridestoryTrans[language].notifications.ADD_PROJECT_TAG_BUTTON_YES;
	this.ADD_PROJECT_TAG_TITLE = BridestoryTrans[language].notifications.ADD_PROJECT_TAG_TITLE;
	this.ADMIN_COUNTER_ERROR = BridestoryTrans[language].notifications.ADD_PROJECT_TAG_TITLE;
	this.ADMIN_COUNTER_CONTACT_TECHTEAM = BridestoryTrans[language].notifications.ADD_PROJECT_TAG_TITLE;
	this.DRAG_REORDER_DELETE_PHOTO = BridestoryTrans[language].notifications.DRAG_REORDER_DELETE_PHOTO;
	this.DRAG_REORDER_DELETE_VIDEO = BridestoryTrans[language].notifications.DRAG_REORDER_DELETE_VIDEO;
	this.DRAG_REORDER_DONT_DELETE = BridestoryTrans[language].notifications.DRAG_REORDER_DONT_DELETE;

	this.EDIT_TAG_ERROR_ENCOUNTERED = BridestoryTrans[language].notifications.EDIT_TAG_ERROR_ENCOUNTERED;
	this.EDIT_TAG_SUCESS_SAVE = BridestoryTrans[language].notifications.EDIT_TAG_SUCESS_SAVE;
	this.FAQ_DELETE_INFO = BridestoryTrans[language].notifications.FAQ_DELETE_INFO;
	this.FAQ_WARNING_UPDATE = BridestoryTrans[language].notifications.FAQ_WARNING_UPDATE;
	this.EVENT_DELETE_SUBMISSION = BridestoryTrans[language].notifications.EVENT_DELETE_SUBMISSION;
	this.EVENT_SUCCESS_DELETE = BridestoryTrans[language].notifications.EVENT_SUCCESS_DELETE;
	this.EVENT_SUCCESS_UPDATE = BridestoryTrans[language].notifications.EVENT_SUCCESS_UPDATE;
    this.EVENT_ERROR_FILESIZE = BridestoryTrans[language].notifications.EVENT_ERROR_FILESIZE;
	this.EVENT_ERROR_TIME = BridestoryTrans[language].notifications.EVENT_ERROR_TIME;
	this.FILE_UPLOAD_ERROR_INVALID_URL = BridestoryTrans[language].notifications.FILE_UPLOAD_ERROR_INVALID_URL;
	this.FILE_UPLOAD_ERROR_MAX_QUOTA = BridestoryTrans[language].notifications.FILE_UPLOAD_ERROR_MAX_QUOTA;
	this.FILE_UPLOAD_ERROR_EXCEED = BridestoryTrans[language].notifications.FILE_UPLOAD_ERROR_EXCEED;
	this.FILE_UPLOAD_ERROR_SERVER_ERROR = BridestoryTrans[language].notifications.FILE_UPLOAD_ERROR_SERVER_ERROR;

	this.LOGIN_WARNING_NOT_VENDOR = BridestoryTrans[language].notifications.LOGIN_WARNING_NOT_VENDOR;
	this.LOGIN_WARNING_NOT_VERIFIED = BridestoryTrans[language].notifications.LOGIN_WARNING_NOT_VERIFIED;
	this.LOGIN_WARNING_REGISTRATION_FAILED = BridestoryTrans[language].notifications.LOGIN_WARNING_REGISTRATION_FAILED;
	this.LOGIN_WARNING_VERIFICATION_EMAIL_SENT = BridestoryTrans[language].notifications.LOGIN_WARNING_VERIFICATION_EMAIL_SENT;
	this.LOGIN_WARNING_VERIFICATION_EMAIL_NOTSENT = BridestoryTrans[language].notifications.LOGIN_WARNING_VERIFICATION_EMAIL_NOTSENT;
	this.LOGIN_WARNING_PASSWORD_INSTRUCTION_SENT = BridestoryTrans[language].notifications.LOGIN_WARNING_PASSWORD_INSTRUCTION_SENT;
	this.LOGIN_WARNING_WENT_WRONG = BridestoryTrans[language].notifications.LOGIN_WARNING_WENT_WRONG;

	this.LOGIN_CREATE_ACCOUNT_TITLE = BridestoryTrans[language].login.LOGIN_CREATE_ACCOUNT_TITLE;
	this.LOGIN_INSPIRATION_TITLE = BridestoryTrans[language].login.LOGIN_INSPIRATION_TITLE;
	this.LOGIN_INSPIRATION_SUBTITLE = BridestoryTrans[language].login.LOGIN_INSPIRATION_SUBTITLE;
	this.LOGIN_INSPIRATION_TITLE_MOBILE = BridestoryTrans[language].login.LOGIN_INSPIRATION_TITLE_MOBILE;
	this.LOGIN_INSPIRATION_SUBTITLE_MOBILE = BridestoryTrans[language].login.LOGIN_INSPIRATION_SUBTITLE_MOBILE;

	this.LOGIN_COMMENT_TITLE = BridestoryTrans[language].login.LOGIN_COMMENT_TITLE;
	this.LOGIN_COMMENT_TITLE_MOBILE = BridestoryTrans[language].login.LOGIN_COMMENT_TITLE_MOBILE;
	this.LOGIN_COMMENT_SUBTITLE_MOBILE = BridestoryTrans[language].login.LOGIN_COMMENT_SUBTITLE_MOBILE;

	this.LOGIN_MESSAGE_TITLE = BridestoryTrans[language].login.LOGIN_MESSAGE_TITLE;
	this.LOGIN_MESSAGE_TITLE_MOBILE = BridestoryTrans[language].login.LOGIN_MESSAGE_TITLE_MOBILE;
	this.LOGIN_MESSAGE_SUBTITLE_MOBILE = BridestoryTrans[language].login.LOGIN_MESSAGE_SUBTITLE_MOBILE;

	this.LOGIN_LEADS_TITLE = BridestoryTrans[language].login.LOGIN_LEADS_TITLE;
	this.LOGIN_LEADS_TITLE_MOBILE = BridestoryTrans[language].login.LOGIN_LEADS_TITLE_MOBILE;
	this.LOGIN_LEADS_SUBTITLE_MOBILE = BridestoryTrans[language].login.LOGIN_LEADS_SUBTITLE_MOBILE;

	this.LOGIN_REVIEW_TITLE = BridestoryTrans[language].login.LOGIN_REVIEW_TITLE;
	this.LOGIN_REVIEW_TITLE_MOBILE = BridestoryTrans[language].login.LOGIN_REVIEW_TITLE_MOBILE;
	this.LOGIN_REVIEW_SUBTITLE_MOBILE = BridestoryTrans[language].login.LOGIN_REVIEW_SUBTITLE_MOBILE;

	this.LOGIN_PRICELIST_TITLE = BridestoryTrans[language].login.LOGIN_PRICELIST_TITLE;
	this.LOGIN_PRICELIST_TITLE_MOBILE = BridestoryTrans[language].login.LOGIN_PRICELIST_TITLE_MOBILE;
	this.LOGIN_PRICELIST_SUBTITLE_MOBILE = BridestoryTrans[language].login.LOGIN_PRICELIST_SUBTITLE_MOBILE;

	this.LOGIN_ELSE_TITLE = BridestoryTrans[language].login.LOGIN_ELSE_TITLE;
	this.LOGIN_ELSE_TITLE_MOBILE = BridestoryTrans[language].login.LOGIN_ELSE_TITLE_MOBILE;
	this.LOGIN_ELSE_SUBTITLE_MOBILE = BridestoryTrans[language].login.LOGIN_ELSE_SUBTITLE_MOBILE;

    this.MESSAGE_PREVIOUS_30_DAYS = BridestoryTrans[language].notifications.MESSAGE_PREVIOUS_30_DAYS;
    this.MESSAGE_PREVIOUS_MONTH = BridestoryTrans[language].notifications.MESSAGE_PREVIOUS_MONTH;
    this.MESSAGE_PREVIOUS_3_MONTHS = BridestoryTrans[language].notifications.MESSAGE_PREVIOUS_3_MONTHS;
    this.MESSAGE_PREVIOUS_6_MONTHS = BridestoryTrans[language].notifications.MESSAGE_PREVIOUS_6_MONTHS;
    this.MESSAGE_PREVIOUS_12_MONTHS = BridestoryTrans[language].notifications.MESSAGE_PREVIOUS_12_MONTHS;
    this.MESSAGE_PLEASE_SELECT_MESSAGE = BridestoryTrans[language].notifications.MESSAGE_PLEASE_SELECT_MESSAGE;

    this.MESSAGE_POPUP_TITLE_TRASH = BridestoryTrans[language].notifications.MESSAGE_POPUP_TITLE_TRASH;
    this.MESSAGE_POPUP_CONTENT_TRASH_1 = BridestoryTrans[language].notifications.MESSAGE_POPUP_CONTENT_TRASH_1;
    this.MESSAGE_POPUP_CONTENT_TRASH_2 = BridestoryTrans[language].notifications.MESSAGE_POPUP_CONTENT_TRASH_2;
    this.MESSAGE_POPUP_TITLE_MOVE = BridestoryTrans[language].notifications.MESSAGE_POPUP_TITLE_MOVE;
    this.MESSAGE_POPUP_CONTENT_MOVE_1 = BridestoryTrans[language].notifications.MESSAGE_POPUP_CONTENT_MOVE_1;
    this.MESSAGE_POPUP_CONTENT_MOVE_2 = BridestoryTrans[language].notifications.MESSAGE_POPUP_CONTENT_MOVE_2;
    this.MESSAGE_POPUP_TITLE_DELETE = BridestoryTrans[language].notifications.MESSAGE_POPUP_TITLE_DELETE;
    this.MESSAGE_POPUP_CONTENT_DELETE_1 = BridestoryTrans[language].notifications.MESSAGE_POPUP_CONTENT_DELETE_1;
    this.MESSAGE_POPUP_CONTENT_DELETE_2 = BridestoryTrans[language].notifications.MESSAGE_POPUP_CONTENT_DELETE_2;

    this.MESSAGE_DESCRIPTION_OTHER = BridestoryTrans[language].notifications.MESSAGE_DESCRIPTION_OTHER;
    this.MESSAGE_DESCRIPTION_AVAILABILITY = BridestoryTrans[language].notifications.MESSAGE_DESCRIPTION_AVAILABILITY;
    this.MESSAGE_DESCRIPTION_APPOINTMENT = BridestoryTrans[language].notifications.MESSAGE_DESCRIPTION_APPOINTMENT;
    this.MESSAGE_DESCRIPTION_PRICELIST = BridestoryTrans[language].notifications.MESSAGE_DESCRIPTION_PRICELIST;

    this.MESSAGE_SENT_SUCCESS = BridestoryTrans[language].notifications.MESSAGE_SENT_SUCCESS;
    this.MESSAGE_SENT_FAILED = BridestoryTrans[language].notifications.MESSAGE_SENT_FAILED;

    this.REQUEST_PHONE_CONFIRMATION_ERROR = BridestoryTrans[language].notifications.REQUEST_PHONE_CONFIRMATION_ERROR;
    this.PHONE_DUPLICATE_ERROR = BridestoryTrans[language].notifications.PHONE_DUPLICATE_ERROR;
    this.ADDITIONAL_EMAIL_DELETED = BridestoryTrans[language].notifications.ADDITIONAL_EMAIL_DELETED;
    this.ADDITIONAL_EMAIL_DELETED_ERROR = BridestoryTrans[language].notifications.ADDITIONAL_EMAIL_DELETED_ERROR;
    this.ADDITIONAL_EMAIL_SAVE = BridestoryTrans[language].notifications.ADDITIONAL_EMAIL_SAVE;
    this.ADDITIONAL_EMAIL_SAVE_ERROR = BridestoryTrans[language].notifications.ADDITIONAL_EMAIL_SAVE_ERROR;

    this.REGISTER_DONT_HAVE_VENDOR_ACCOUNT = BridestoryTrans[language].notifications.REGISTER_DONT_HAVE_VENDOR_ACCOUNT;

    this.FAILED_LOGIN_FACEBOOK = BridestoryTrans[language].notifications.FAILED_LOGIN_FACEBOOK;
    this.FAILED_LOGIN_GOOGLE = BridestoryTrans[language].notifications.FAILED_LOGIN_GOOGLE;

    this.SUBSCRIBTION_WARNING_PENDING_PAYMENT_1 = BridestoryTrans[language].notifications.SUBSCRIBTION_WARNING_PENDING_PAYMENT_1;
    this.SUBSCRIBTION_WARNING_PENDING_PAYMENT_2 = BridestoryTrans[language].notifications.SUBSCRIBTION_WARNING_PENDING_PAYMENT_2;
    this.SUBSCRIBTION_WARNING_UNDER_REVIEW_1 = BridestoryTrans[language].notifications.SUBSCRIBTION_WARNING_UNDER_REVIEW_1;
    this.SUBSCRIBTION_WARNING_UNDER_REVIEW_2 = BridestoryTrans[language].notifications.SUBSCRIBTION_WARNING_UNDER_REVIEW_2;
    this.ALERT_SUCCESS_REGISTER_MOBILE = BridestoryTrans[language].notifications.ALERT_SUCCESS_REGISTER_MOBILE;

    this.MESSAGE_MOVETOINBOX = BridestoryTrans[language].notifications.MESSAGE_MOVETOINBOX;
    this.MESSAGE_MOVETOTRASH = BridestoryTrans[language].notifications.MESSAGE_MOVETOTRASH;
    this.MESSAGE_DELETEPERMANENT = BridestoryTrans[language].notifications.MESSAGE_DELETEPERMANENT;
    this.MESSAGE_CANNOTREPLY = BridestoryTrans[language].notifications.MESSAGE_CANNOTREPLY;
    this.PLAN_WARNING_REMOVE_CITY_TITLE = BridestoryTrans[language].notifications.PLAN_WARNING_REMOVE_CITY_TITLE;
    this.PLAN_CHECKOUT_REMOVECITY = BridestoryTrans[language].notifications.PLAN_CHECKOUT_REMOVECITY;
    this.PLAN_CHECKOUT_SAMECITY = BridestoryTrans[language].notifications.PLAN_CHECKOUT_SAMECITY;
    this.PLAN_CHECKOUT_ERROR_ADDCITY = BridestoryTrans[language].notifications.PLAN_CHECKOUT_ERROR_ADDCITY;
    this.SUCCESS_CANCEL_PAYMENT = BridestoryTrans[language].notifications.SUCCESS_CANCEL_PAYMENT;
    this.YOUR_ALBUM = BridestoryTrans[language].projects.YOUR_ALBUM;

    this.ERROR_INSTALLMENT_CHOOSE_METHOD = BridestoryTrans[language].notifications.ERROR_INSTALLMENT_CHOOSE_METHOD;
    this.ERROR_INSTALLMENT_CARD_ISSUER = BridestoryTrans[language].notifications.ERROR_INSTALLMENT_CARD_ISSUER;
    this.ERROR_INSTALLMENT_FETCHING_LIST = BridestoryTrans[language].notifications.ERROR_INSTALLMENT_FETCHING_LIST;
    this.ERROR_INSTALLMENT_INVALID_CC = BridestoryTrans[language].notifications.ERROR_INSTALLMENT_INVALID_CC;
    this.ERROR_INSTALLMENT_INVALID_CVV = BridestoryTrans[language].notifications.ERROR_INSTALLMENT_INVALID_CVV;
    
    this.delete_deal_title = BridestoryTrans[language].store.delete_product_title;
    this.delete_deal_desc = BridestoryTrans[language].store.delete_product_desc;
    this.delete_deal_cancel = BridestoryTrans[language].store.cancel;
    this.delete_deal = BridestoryTrans[language].store.delete;
    this.edit_deal_yes = BridestoryTrans[language].store.edit;
    this.edit_deal_title = BridestoryTrans[language].store.edit_title;
    this.edit_deal_description_3 = BridestoryTrans[language].store.edit_description_3;

    this.banner_tokped_sync_alert1 = BridestoryTrans[language].store.banner_tokped_sync_alert1;
    this.banner_tokped_sync_alert2 = BridestoryTrans[language].store.banner_tokped_sync_alert2;
    this.banner_tokped_sync_alert3 = BridestoryTrans[language].store.banner_tokped_sync_alert3;

    this.STORE_DEALS_POPUP_CONFIRMATION_TITLE = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_CONFIRMATION_TITLE;    
    this.STORE_DEALS_POPUP_CONFIRMATION_DESC = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_CONFIRMATION_DESC;    
    this.STORE_DEALS_POPUP_EDIT_TITLE = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_EDIT_TITLE;    
    this.STORE_DEALS_POPUP_EDIT_DESC = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_EDIT_DESC;    
    this.STORE_DEALS_POPUP_BUTTON_EDIT = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_BUTTON_EDIT;    
    this.STORE_DEALS_POPUP_BUTTON_REGULAR = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_BUTTON_REGULAR;    
    this.STORE_DEALS_POPUP_REJECT_TITLE = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_REJECT_TITLE;    
    this.STORE_DEALS_POPUP_REJECT_DESC = BridestoryTrans[language].notifications.STORE_DEALS_POPUP_REJECT_DESC;    
}

angular.module('bridestoryApp.notificationApp')
  .service('notificationMessage', [notificationMessage]);
