function MenuService($rootScope, $http, cookieServices, MenuFactory) {

	return {
        counterMessageNotification: function(_uuid,sid) {
            return MenuFactory.counterMessageNotification(_uuid,sid).then(function(response) {
                return response;
            }, function(error) {
                return error;
            });
        },
        counterNotification: function(accountId,sid) {
            return MenuFactory.counterNotification(accountId,sid).then(function(response) {
                return response;
            }, function(error) {
                return error;
            });
        },
        updateLastSeen: function(_uuid, _id, sid) {
            return MenuFactory.updateLastSeen(_uuid,_id,sid).then(function(response) {
                return response;
            }, function(error) {
                return error;
            });
        },
		getBridestoryPoints: function(_sid) {
            return MenuFactory.getBridestoryPoints(_sid).then(function(response) {
                return response;
            }, function(error) {
                return error;
            });
        }
	}

}

angular
	.module('bridestoryApp.menuApp')
	.service('MenuService', [ '$rootScope', '$http', 'cookieServices', 'MenuFactory', MenuService ]);
