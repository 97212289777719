function GeoLocationService($rootScope, $http, cookieServices, $q) {
  var _cookey = 'BS.userOrigin',
      _origin = undefined,
      _promise = $q.defer();

  if(cookieServices.getCookie(_cookey)) {
    _origin = JSON.parse(cookieServices.getCookie(_cookey));
    _promise.resolve(_origin);

  } else {
    $http
      .get(Bridestory.api + '/v2/geo_location/detect')
      .success(function(data) {
        _origin = data.geoLocation;
        _promise.resolve(_origin);
        try {
          window.localStorage.setItem(_cookey,JSON.stringify({sessionid: sessionId}));
        }catch(e) {
          // console.log(e);
        }
      })
      .error(function(error) {
        _origin = {
          "city": "United States",
          "country": "US"
        };
        _promise.resolve(_origin);
      });
  }

  return _promise.promise;
}

angular
  .module('bridestoryApp.geoLocationApp')
  .service('GeoLocationService', [ '$rootScope', '$http','cookieServices','$q', GeoLocationService ]);