function vendorSearchMobileService($http, cookieServices) {
  var params = {
    'session_id' : cookieServices.getCookie('BSID'),
    'country_id' : 'ina'
  };

  return {
    getPage: function(categoryId, cityId, budgetId, limit, page, sort) {
      var getParams = angular.extend({
        'category_id' : categoryId,
        'city_id' : cityId,
        'budget_id' : budgetId,
        'limit' : limit,
        'page' : page,
        'sort' : sort
      }, params);

      return $http.get(Bridestory.api + '/v1/vendors?' + build_query(getParams));
    },
    updateParams : function(categoryId, countryId, cityId, budgetId){
      params = angular.extend(params, {
        'category_id' : categoryId,
        'country_id' : countryId,
        'city_id' : cityId,
        'budget_id' : budgetId
      });
    },
    getParams : function(){
      return params;
    }
  }
}

angular
  .module('bridestoryApp.vendorSearchAppMobile')
  .service('vendorSearchMobileService', [ '$http', 'cookieServices', vendorSearchMobileService])
