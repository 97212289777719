angular
	.module('bridestoryApp.decorative')
	.directive('jsSmoothScroll', ['$window', '$position', function($window, $position) {
		return {
			restrict: 'AC',
			link: function(scope, element, attrs) {

				var _alive = !!attrs.ssNoliveupdate ? false : true,
					_offset = !!attrs.ssScrolloffset ? parseInt(attrs.ssScrolloffset, 10) : 0,
					_anchor = (function() {
						var _anchor, _init;
						return function() {
							return _alive || !_init ? (_init = true, _anchor = !!attrs.jsSmoothScroll ? isNaN(parseInt(attrs.jsSmoothScroll, 10)) ? $position.offset(angular.element(attrs.jsSmoothScroll)).top : parseInt(attrs.jsSmoothScroll, 10) : 0) : _anchor;
						}
					})(),
					_target,
					_duration = (function() {
						var _duration, _init;
						return function() {
							return _alive || !_init ? (_init = true, _duration = !!attrs.ssDuration ? isNaN(parseInt(attrs.ssDuration, 10)) ? Math.abs($window.pageYOffset - _target) / 5 : parseInt(attrs.ssDuration, 10) : 500) : _duration;
						}
					})(),
					$viewport = angular.element('html, body'),
					$body = angular.element('body');

				element.bind('click', function() {
					_target = Math.min(_anchor() + _offset, $body.innerHeight());
					$viewport.stop(false, true).animate({
						scrollTop: _target,
					}, _duration());

					$viewport.bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(e) {
						if (e.which > 0 || e.type === "mousedown" || e.type === "mousewheel") {
							$viewport.stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup'); // This identifies the scroll as a user action, stops the animation, then unbinds the event straight after (optional)
						}
					});
				})
			}
		}
	}])