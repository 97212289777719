function LoginFactory($http) {
  return {
    login: function(loginAttributes) {
      return $http({
        method  : 'POST',
        url     : Bridestory.api + '/v1/login',
        data    : $.param(loginAttributes),
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    },

    register: function(registerData) {
      return $http({
        method  : 'POST',
        url     : Bridestory.direct_api + '/v1/account/register/user',
        data    : $.param(registerData),
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    },

    forgot_password: function(email) {
      var forgotData = {
        'email' : email
      };
      return $http({
        method  : 'POST',
        url     : Bridestory.api + '/v2/forgot_password',
        data    : $.param(forgotData),
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    },

    change_password: function(changePasswordAttributes) {
      return $http({
        method  : 'POST',
        url     : Bridestory.api + '/v2/forgot_save',
        data    : $.param(changePasswordAttributes),
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    },

    getMyDetails: function (sid) {
      return $http({
          method  : 'GET',
          url     : Bridestory.api + '/v1/me/accountable',
          headers : {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Session-Id': sid
          }
      });
    }
  }
}

angular
  .module('bridestoryApp.newLoginApp')
  .service('LoginFactory', [ '$http', LoginFactory ]);
