
function LoginController($rootScope, $scope, $location, LoginService, LoginSocialMediaService, cookieServices, notificationService, AnalyticService, GeoLocationService, CountryServices, userManager, resourceManager, vendormetaManager, MenuService) {

  this.is_loading               = false;
  this.login_error              = false;
  this.error_no_email           = false;
  this.error_wrong_email        = false;
  this.password_get_error       = false;
  this.register_error           = false;

  this.login                    = {email:'', password:''};
  this.reset_pass               = {new_password: '', conf_password: ''};
  this.facebook                 = {email:null};
  this.google                   = {email:''};
  this.forgot                   = {email:''};
  this.save_email               = '';
  this.save_password            = '';
  this.socmedType               = '';
  $scope.newAccount               = 1;

  if(Bridestory.localized_bucket == 'SG'){
    this.promotional_newsletter   = false;
  }else{
    this.promotional_newsletter   = true;
  }

  this.login_submitted          = false;
  this.register_submitted       = false;
  this.forgot_submitted         = false;
  this.facebook_register_manual = false;
  this.google_register_manual   = false;
  this.term_condition           = false;

  this.redirect_to              = false;

  this.resetsent                = false;
  this.BridestoryTrans          = BridestoryTrans;
  this.selection = '';
  this.account                  = {};

  var self = this;

  $scope.myLocation = {};
  $scope.country = {};
  CountryServices.then(function(result) {
    getMyLocation(function(myLocation){
      angular.forEach(result.data.country, function(value, key) {
        if($scope.myLocation.country == value.iso)
          $scope.country = value;
      });
    });
  });

  var getMyLocation = function (successCallback){
    GeoLocationService.then(function(result) {
      var myLocation = result;
      $scope.myLocation = myLocation;
      if(successCallback) successCallback(myLocation);
    });
  }

  var updateHeader = function(){
    //CHECK IF VENDOR ALLOWED TO ACCESS EVENT
    vendormetaManager.refresh().then(function(vendorMeta){
      new resourceManager.skeleton({
          API : '/vendors/' + userManager.slug() + '?attrType=slug&include=memberships,services,countryOfOrigin,mainService',
          apiVersion : 'v2',
          success : function(result) {
            vendormetaManager.memberships = result.vendor.memberships.data;
            vendormetaManager.vendor = result.vendor;
            vendormetaManager.mainService = result.vendor.mainService.data;

            angular.forEach(result.vendor.memberships.data,function(value,key){
                if(result.vendor.countryOfOrigin.data.id == 192 && value.status == 'active' && value.membershipType.data.name == 'Gold' && value.service.data.category.data.name == 'Venue')
                  vendormetaManager.isAllowedAccessEvent = true;
            })
          }
      })
    });

    MenuService.counterMessageNotification(self.account.account.data.uuid).then(function(response) {
      if(response.status == 200) {
        var unreadMsg = response.data.data.unreadCount ? response.data.data.unreadCount : 0;
        var unreadMsgSpecialUser = response.data.data.specialUserUnreadCount ? response.data.data.specialUserUnreadCount : 0;
        var unreadMsgTokped = response.data.data.tokpedUnreadCount ? response.data.data.tokpedUnreadCount : 0;
        vendormetaManager.unreadMessages = unreadMsg + unreadMsgSpecialUser;
        vendormetaManager.unreadTokpedMessages = unreadMsgTokped;      }
    });

    MenuService.counterNotification(self.account.accountId, cookieServices.getCookie('BSID')).then(function(response) {
      if(response.status == 200) {
        vendormetaManager.unreadNotification = response.data.data.unreadCount;
      }
    });

    MenuService.getBridestoryPoints(cookieServices.getCookie('BSID')).then(function(response) {
      if(response.status == 200) {
          vendormetaManager.bridestoryPoints = response.data.data;
      }
    });
  }

  var successLoginService = function(result,redirect_to){
      cookieServices.setCookies(result.data.account.sessionId);
      cookieServices.setLocalStorage(result.data.account);
      userManager.refresh();


      if(result.data.account.type === 'vendor' || (result.data.account.type=='user' && result.data.account.isVerified == '1')){
        AnalyticService.eventTrack('Login_Success', {'category' : 'login', 'label' : location.pathname});
        $rootScope.$broadcast('login_popup');

        updateHeader();

        if(angular.isDefined(redirect_to) && redirect_to !== null) {
          $location.path( redirect_to );
        } else {
          if (result.data.account.type === 'vendor'){
            if (getParameterByName('redirect_uri') === '' && getParameterByName('redirect_uri').match('/dashboard') == null){
              window.location.href = redirect_url + '/dashboard/essential';
            } else {
              //condition for handle localization parameter
              if(getParameterByName('redirect_uri').match('/dashboard') != null && getParameterByName('redirect_uri').split('/dashboard')[1]){
                var param = getParameterByName('redirect_uri').split('/dashboard')[1];
                window.location.href = redirect_url + '/dashboard' + param + window.location.hash || null;
              }else{
                window.location.href = redirect_url + validateRedirectUri(getParameterByName('redirect_uri'));
              }
            }
          }
        }
      } else{
        self.change_section('verification_user');
        notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_NOT_VERIFIED,'error');
      }
  }

  this.trackButtonApply = function(){
      AnalyticService.eventTrack('Vendor_Registration', {'category' : 'business_site_action', 'label' : 'registration_apply_now_for_free'});
  }

  this.isUserVerified = function(){
    if (!userManager.isVerified())
      return false;
    return true;
  }
  this.change_section = function(section) {
    self.selection = section;
    AnalyticService.pageTrackVirtual(section, 'GA');
  }

    this.clear = function () {
        self.login.email = '';
    };

  this.isEmailAddress = function (email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  this.login_register = function(form_login, redirect_to) {
    self.error_wrong_email = false;
    self.error_no_email = false;
    self.error_password_to_short = false;
    self.password_get_error = false;
    self.error_wrong_password = false;
    self.error_email_pass_not_match = false;

    if(form_login.email.$error.required) {
      self.error_no_email = true;
    }
    else if (form_login.email.$error.email) {
      self.error_wrong_email = true;
    }
    else if(form_login.password.$error.minlength || form_login.password.$error.required) {
      self.error_password_to_short = true;
    }
    else if(form_login.$valid) {
      if(self.isEmailAddress(self.login.email)){
        self.is_loading = true;
        jQuery('.process-loading-background').removeClass('hidden');
        var loginAttributes = {
          'email'    : self.login.email,
          'password' : self.login.password
        };
        LoginService.login(loginAttributes).then(function(result) {
          if (!result) notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_NOT_VENDOR,'error');

          if (result.status === 401)
          {
              if(result.data.type == "AccountNotFoundException")
              {
                cookieServices.setCustomCookie('BS-EmailNotFound', { email: self.login.email, showNotif: true }," 1 minutes");
                window.location = '/register/vendor';
              }else
              {
                self.error_wrong_password = true;
                self.error_email_pass_not_match = true;
              }
          }
          else if (result.status === 200) {
            self.account = result.data.account;
            $rootScope.$broadcast("checkLogin", { data: result.data.account });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              "event": "v2_sign_in",
              "v2_authentication_method": 'form',
              "v2_userId": self.account.accountId,
              "v2_loginType": 'vendor'
            });

            successLoginService(result,redirect_to);
          }
          self.is_loading = false;
          jQuery('.process-loading-background').addClass('hidden');
        });
      } else
        self.error_wrong_email = true;
    }
  }

  this.process_register = function(form_register, redirect_to) {
    self.error_no_full_name = false;
    self.error_term_condition_uncheck = false;
    var redirect_uri = '/';

    if(getParameterByName('redirect_uri') != '') redirect_uri = getParameterByName('redirect_uri');

    if(form_register.full_name.$error.required) {
      self.error_no_full_name = true;
    }else if(angular.isObject(form_register.term_condition) && !self.term_condition){
      self.error_term_condition_uncheck = true;
    }else if(form_register.$valid) {
      var registerData = {
        'full_name'              : self.login.full_name,
        'email'                  : self.save_email,
        'password'               : self.save_password,
        'mobile_phone'           : '',
        'country_id'             : $scope.country.id,
        'promotional_newsletter' : (self.promotional_newsletter) ? 1 : 0,
        'redirect_uri'           : redirect_uri
      };

      self.is_loading = true;
      jQuery('.process-loading-background').removeClass('hidden');
      LoginService.register(registerData).then(function(result) {
        if(result.status === 200) {
          AnalyticService.eventTrack('Register_Success', {'category' : 'login', 'label' : location.pathname});
          AnalyticService.eventTrack('Member', {'category' : 'register', 'label' : 'AccountID: ' + result.data.account.accountId});
          $rootScope.$broadcast("checkLogin", { data: result.data.account });
          cookieServices.setCookies(result.data.account.sessionId);
          userManager.refresh();
          self.change_section('verification_user');
        } else {
          AnalyticService.eventTrack('Register_Failed', {'category' : 'login', 'label' : location.pathname});
          self.register_error = notificationService.message.LOGIN_WARNING_REGISTRATION_FAILED;
        }
        self.is_loading = false;
        jQuery('.process-loading-background').addClass('hidden');
      });
    }
  };

  this.forgot_process = function(form_forgot, redirect_to) {
    self.error_forgot_email_empty = false;
    self.error_forgot_email_wrong = false;
    self.error_forgot_email_not_found = false;

    if(form_forgot.email.$error.required) {
      self.error_forgot_email_empty = true;
    }
    else if (form_forgot.email.$error.email) {
      self.error_forgot_email_wrong = true;
    }
    else if(form_forgot.$valid) {
      if (self.isEmailAddress(self.login.email)) {
        LoginService.forgot_password(self.login.email).then(function(response) {
          if(response.status == 200) {
            self.resetsent = true;
            AnalyticService.eventTrack('Forgot_Password', {'category' : 'login', 'label' : location.pathname});
            notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_PASSWORD_INSTRUCTION_SENT);
            self.change_section('forgot_success');
          }
          else {
            self.error_forgot_email_not_found = true;
          }
        });
      }else {
        self.error_forgot_email_wrong = true
        ;
      }
    }
  }

  this.check_confirm_expired = ['checkExpired', function(checkExpired){
        if(checkExpired == true){
            self.change_section('forgot');
            notificationService.topbarNotify("The link to change your password has expired. Please enter your email address again.", 'error');
        }else{
            self.change_section('');
        }
  }]

  this.redirect_login = function(){
        window.location = redirect_url + '/login';
  }

  
  this.new_password = function(form_new_password, redirect_to){
    self.error_password_empty       = false;
    self.error_password_to_short    = false;
    self.error_confpassword_empty   = false;
    self.error_password_notmatch    = false;

    var tokenId = window.location.pathname.split('/confirm-forgot-password/')[1];

    if(form_new_password.new_password.$error.required) {
        self.error_password_empty = true;
    }else if(form_new_password.new_password.$error.minlength || form_new_password.new_password.$error.required) {
        self.error_password_to_short = true;
    }else if(form_new_password.conf_password.$error.required){
        self.error_confpassword_empty = true;
    }else if(form_new_password.new_password.$viewValue !== form_new_password.conf_password.$viewValue || form_new_password.conf_password.$error.required ){
        self.error_password_notmatch = true;
    }else if(form_new_password.$valid) {
      if(!self.is_loading) {
        self.is_loading = true;
        jQuery('.process-loading-background').removeClass('hidden');

        var changePasswordAttributes = {
          'tokenId'    : tokenId,
          'newPassword' : self.reset_pass.new_password,
          'confirmPassword' : self.reset_pass.conf_password
        };

        LoginService.change_password(changePasswordAttributes).then(function(result){
            if(result.status === 200) {

                var loginAttributes = {
                  'email'    : result.data.email,
                  'password' : self.reset_pass.new_password
                };

                LoginService.login(loginAttributes).then(function(result) {
                    if(result.status == 200){
                      self.account = result.data.account;
                      //$rootScope.$broadcast("checkChangePassword", {status : true});
                      cookieServices.setCustomCookie('change-password', true, "1 days");
                      successLoginService(result,redirect_to);
                    }
                    self.is_redirecting = false;
                    jQuery('.process-loading-background').addClass('hidden');
                });
            }
        });
      }
    }
  }
  

  this.masquerade = function(sessionId) {
    AnalyticService.eventTrack('Masquerade', {'category' : 'login', 'label' : location.pathname});
    cookieServices.removeCookies();
    cookieServices.removeCustomCookie('BS.SERVER.logDetail');
    cookieServices.setCookies(sessionId);
    cookieServices.setCustomCookie('BS.isMasquerade', true, '1 days');
    setTimeout(function(){
      window.location.href = redirect_url + '/login';
    }, 2000);
  }

  this.newsletterConfirm = function(status, socmedType) {
    self.error_term_condition_uncheck = false;
    self.promotional_newsletter = status;

    if(Bridestory.localized_bucket == 'SG' && !self.term_condition){
        self.error_term_condition_uncheck = true;
        return false;
    }

    $scope.newAccount = '';
    if (socmedType == 'google'){
      self.google_login();
    } else if (socmedType == 'facebook'){
      self.facebook_login();
    } else if (socmedType == 'yahoo'){
      self.yahoo_login();
    }
  }

  this.resend_verification = function(email) {
    LoginService.resendEmailVerification(email).then(function(result) {
        if (result.status == 200)
            notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_VERIFICATION_EMAIL_SENT);
          else
            notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_VERIFICATION_EMAIL_NOTSENT,'error');
      },function(error){
          notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_VERIFICATION_EMAIL_NOTSENT,'error');
    });
  }

  this.verification_check = function(email) {
    LoginService.verificationCheck(email).then(function(result) {

        if (result.status == 200){
          self.change_section('login');
          if(result.data.account.isVerified != '1')
           notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_VERIFICATION_EMAIL_NOTSENT,'error');
        }else
          notificationService.topbarNotify(notificationService.message.LOGIN_WARNING_WENT_WRONG,'error');

    });
  }
}

function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.href);
  return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function validateRedirectUri(value) {
  var string = value;
  var firstChar = string.charAt(0);

  var regex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\?]+/);
  if (regex.test(firstChar)) {
    string = string.replace(regex, '/');
  } else if (firstChar != '/') {
    string = '/' + string
  }
  string = string.replaceAll('.', '');
  return string;
}

angular
  .module('bridestoryApp.newLoginApp')
  .controller('LoginController', [ '$rootScope', '$scope', '$location', 'LoginService', 'LoginSocialMediaService', 'cookieServices', 'notificationService', 'AnalyticService', 'GeoLocationService', 'CountryServices', 'userManager', 'resourceManager', 'vendormetaManager', 'MenuService',LoginController ]);
