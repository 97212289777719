function YahooServices($rootScope, $q, $http, LoginSocialMediaFactory) {
  return {
		openOauth: function() {
			var dfd = $q.defer();

			OAuth.popup('yahoo')
					.done(function (response) {
							dfd.resolve(response);
					})
					.fail(function (error) {
						console.log(error);	
					});

			return dfd.promise;
		},
		getUID: function(data) {
			var dfd = $q.defer();

			data.get('https://social.yahooapis.com/v1/me/guid?format=json')
					.done(function (response) {
							data.get('https://social.yahooapis.com/v1/user/' + response.guid.value + '/profile?format=json')
									.done(function (response) {
											dfd.resolve(response);
									})
									.fail(function (error) {
											
									});
					})
					.fail(function (error) {
						console.log(error);		
					});

			return dfd.promise;
		},
		loginUser: function(data) {
			var dfd = $q.defer();
			dfd.resolve(data);
			return dfd.promise;
		}
  }
}

angular
  .module('bridestoryApp.newLoginApp')
  .service('YahooServices', [ '$rootScope', '$q', '$http', 'LoginSocialMediaFactory', YahooServices ]);