function MenuFactory($http, cookieServices) {
    return {
        counterMessageNotification: function(_uuid,sid) {
            var data = {
                'query' : 'query UnreadCount($subscribeKey: String!, $suSubscribeKey: String!, $tokpedSubscribeKey: String!, $uuid: String!)  { unreadCount(subscribeKey: $subscribeKey, uuid: $uuid) specialUserUnreadCount: unreadCount(subscribeKey: $suSubscribeKey, uuid: $uuid) tokpedUnreadCount: unreadCount(subscribeKey: $tokpedSubscribeKey, uuid: $uuid) }',
                'variables': {
                    subscribeKey: Bridestory.chat_subs_key,
                    suSubscribeKey: Bridestory.chat_special_user_subs_key,
                    tokpedSubscribeKey: Bridestory.chat_tokped_subs_key,
                    uuid: _uuid
                },
                operationName: 'UnreadCount'
            };
            return $http({
                method  : 'POST',
                url     : Bridestory.chat_api_url + '/securegraphql',
                data    : JSON.stringify(data),
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': cookieServices.getCookie('BSID')
                }
            });
        },
        counterNotification: function(accountId, sid) {
            var data = {
                'query': "query get_unread_count($applicationId: Int!, $accountId: Int!){unreadCount(applicationId: $applicationId, targetId: $accountId)}",
                'variables': {
                    applicationId: Bridestory.activity_stream_id,
                    accountId: accountId
                },
                operationName: 'get_unread_count'
            };
            return $http({
                method  : 'POST',
                url     : Bridestory.activity_stream_url + '/graphql',
                data    : JSON.stringify(data),
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': 'BS ' + btoa(Bridestory.activity_stream_id+':'+cookieServices.getCookie('BSID'))
                }
            });
        },
        updateLastSeen: function(_uuid,_id,sid) {
            return $http({
                method  : 'POST',
                url     : Bridestory.api + '/v2/accounts/last_seen/' + _uuid +'/'+ _id,
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': sid
                }
            });
        },
        getBridestoryPoints: function(_sid) {
            return $http({
                method: 'GET',
                url: Bridestory.api +'/ms/rewards/v1/me/rewards',
                headers: { 'Session-Id' : cookieServices.getCookie('BSID') }
            });
        }
    }
}

angular
  .module('bridestoryApp.menuApp')
  .service('MenuFactory', [ '$http', 'cookieServices', MenuFactory ]);
