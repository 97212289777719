function LoginSocialMediaFactory($http, cookieServices) {
  this.data = {};
  var self = this;


  this.login_social_media = function(id, email, name, picture, type, country_id, promotional_newsletter, newAccount,token_for_business) {


    angular.extend(self.data ,{
      'id'           : id,
      'email'        : email,
      'full_name'    : name,
      'picture'      : picture,
      'type'         : type,
      'mobile_phone' : '',
      'country_id'   : country_id,
      'business_id' : token_for_business,
      'promotional_newsletter' : (promotional_newsletter ? 1 : 0),
      'new_socmed_account' : (newAccount ? 'yes' : 'no')
    });

    return $http({
      method  : 'POST',
      url     : Bridestory.direct_api + '/v2/connect?include=socmed',
      data    : $.param(self.data),
      headers : {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Session-id' : cookieServices.getCookie('BSID')
      }
    });
  };

  this.extendToken = function(accountId, token) {
    return $http.post(Bridestory.default_url + '/facebook/extendAccessToken/' + accountId, {'access_token' : token});
  };
}

angular
  .module('bridestoryApp.newLoginApp')
  .service('LoginSocialMediaFactory', [ '$http', 'cookieServices', LoginSocialMediaFactory ]);