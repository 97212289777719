function pageCounterServices(pageCounterFactory) {
    return {
        setCount: function (number) {
            pageCounterFactory.setCount(number);
        }
    }
}


angular
    .module('bridestoryApp.pageCounterApp')
    .service('pageCounterServices', [ 'pageCounterFactory', pageCounterServices ]);
    