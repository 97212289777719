function AnalyticFactoryGA() {
	return {
    pageTrack: function() {
      ga('send', 'pageview');
      ga('detailedTracker.send', 'pageview');
    },
		pageTrackVirtual: function(uri) {
			ga('send', 'pageview', uri);
      ga('detailedTracker.send', 'pageview', uri);
		},
    eventTrack : function (eventName, properties) {
      ga('send', {
        'hitType': 'event',
        'eventCategory': properties.category,
        'eventAction': eventName,
        'eventLabel': properties.label
      });
      ga('detailedTracker.send', {
        'hitType': 'event',
        'eventCategory': properties.category,
        'eventAction': eventName,
        'eventLabel': properties.label
      });
    },
    loadECommerce: function() {
      ga('require', 'ecommerce');
      ga('detailedTracker.require', 'ecommerce');
    },
    addECommerceTransaction: function(transactionId, affiliation, revenue, shipping, tax, currency) {
      var transaction = {
        'id': transactionId,
        'affiliation': affiliation,
        'revenue': revenue,
        'shipping': shipping,
        'tax': tax,
        'currency': currency ? currency : 'IDR'
      };

      ga('ecommerce:addTransaction', transaction);
      ga('detailedTracker.ecommerce:addTransaction', transaction);
    },
    addECommerceItem: function(transactionId, name, sku, category, price, quantity) {
      var item = {
        'id': transactionId,
        'name': name,
        'sku': sku,
        'category': category,
        'price': price,
        'quantity': quantity
      };

      ga('ecommerce:addItem', item);
      ga('detailedTracker.ecommerce:addItem', item);
    },
    sendECommerce: function() {
      ga('ecommerce:send');
      ga('detailedTracker.ecommerce:send');
    },
    clearECommerce: function() {
      ga('ecommerce:clear');
      ga('detailedTracker.ecommerce:clear');
    }
	}
}

angular
	.module('bridestoryApp.analyticApp')
	.factory('AnalyticFactoryGA', [ AnalyticFactoryGA ]);