function autoCompleteServices($http, $filter) {

	var me = this;

	this.options = {
		addVendor : false,
        excludeMe : false
	};

    this.searchVendor = function(name, sid) {
        return $http({
            method  : 'GET',
            url     : Bridestory.api + '/v1/vendors/search/' + name,
            data    : '',
            headers : {
              'Content-Type': 'application/json',
              'Session-Id': sid,
              'bs-localization-bucket': (Bridestory.localized_bucket == 'default') ? 'none' : Bridestory.localized_bucket
            }
        }).then(function(response){
            if(me.options.addVendor == true) {
                response.data.vendors.push({
                    location: null,
                    key : name,
                    nid: null,
                    photo: null,
                    title: BridestoryTrans[language].projects.NOTFOUND_VENDOR,
                    url: null
                })
            }
            if(me.options.excludeMe == true){
                response.data.vendors = $filter('filter')(response.data.vendors, {nid: '!'+response.data.meta.currentVendorId});
            }
            return response.data.vendors;
        });
    }
}

angular
    .module('bridestoryApp.autoCompleteApp')
    .service('autoCompleteServices', [ "$http", "$filter", autoCompleteServices ]);
