function accountMetaService(accountMetaFactory) {

	this.retrieveMeta = function(sid){
		return accountMetaFactory.getMeta(sid).then(function(response) {
                return response;
            }, function(error) {
                return error;
            }
        );
	}

    this.retrieveUserMeta = function(sid){
        return accountMetaFactory.getUserMeta(sid).then(function(response) {
                return response;
            }, function(error) {
                return error;
            }
        );
    }
}

angular
    .module('bridestoryApp.accountMetaApp')
    .service('accountMetaService', [ "accountMetaFactory", accountMetaService ]);