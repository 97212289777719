function accountMetaFactory($http) {
    return {
        getMeta: function (sid) {
            return $http({
                method  : 'GET',
                url     : Bridestory.api + '/v1/vendors/meta',
                headers : {
                    'Content-Type': 'application/json',
                    'Session-Id': sid
                }
            });
        },
        getUserMeta: function (sid) {
            return $http({
                method  : 'GET',
                url     : Bridestory.api + '/v1/users/meta',
                headers : {
                    'Content-Type': 'application/json',
                    'Session-Id': sid
                }
            });
        }
    }
}

angular
    .module('bridestoryApp.accountMetaApp')
    .factory('accountMetaFactory', [ '$http', accountMetaFactory ]);