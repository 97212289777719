function InstagramService($rootScope, $q, $http, LoginSocialMediaFactory,cookieServices) {
  return {
		openOauth: function() {
			var url = "https://api.instagram.com/oauth/authorize?client_id=925283534616444&redirect_uri=" + Bridestory.default_url+ "/auth/&scope=user_profile&response_type=code"
			window.open(url,'instagram')
		},
		getMedia: function(url,after) {
			if (!url){
				url = Bridestory.api + '/ms/bs-vendors/api/v1/instagram_feeds?limit=10';
			}
			if (after){
				url = url + '?after=' + after
			}
			return $http({
				  method: 'GET',
				  headers: {
					'Session-Id' : cookieServices.getCookie('BSID')
				  },
				  url})
				.success(function(data){
					return data;
				})
		},
		followBridestory : function (access_token) {
			var bridestoryId = 612780926;
			var url = "https://api.instagram.com/v1/users/"+bridestoryId+"/relationship?access_token="+access_token;
			var data = { 'action' : 'follow'}
			return $http({
              method  : 'POST',
              url     : url,
              data : $.param(data),
              headers : {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then(
            function(response) {
                 return response;
            },
            function(error) {
                 return error;
            });
		}
  }
}

angular
  .module('bridestoryApp.newLoginApp')
  .service('InstagramService', [ '$rootScope', '$q', '$http', 'LoginSocialMediaFactory','cookieServices', InstagramService ]);
