angular
	.module('bridestoryApp.decorative')
	.directive('cldHidpi', [function() {
		return {
			restrict: 'C',
			link: function(scope, element, attrs) {
				element.cloudinary_update();
				attrs.$observe('src', function(n) {
					element.removeClass('cld-hidpi');
					element.removeData("src");
					element.show();
				});
			}
		}
	}]);