angular
.module('bridestoryApp.suddenPopupApp')
.controller('suddenPopupController',['$scope', '$modal', 'pageCounterFactory', 'AnalyticService', 'LoginService',function($scope, $modal, pageCounterFactory, AnalyticService,LoginService){

  var modal;

  $scope.naviLogin = function() {
		if (location.pathname != "/" && location.pathname != "/login") {
			window.location.href = redirect_url + '/login' + '?redirect_uri=' + location.pathname+location.hash;
    } else {
			window.location.href = redirect_url + '/login';
    }
		return false;
	};

	function checkCount(count) {
		/*switch(count) {
			case 5 :
				modal = $modal.open({
					templateUrl : Bridestory.default_url + '/popup/sudden/welcome',
					windowClass : 'popup',
					scope : $scope
				});

        modal.opened.then(function (data) {
          AnalyticService.pageTrackVirtual('popup/sudden/welcome', 'GA');
        });
			break;
			case 12 :
				modal = $modal.open({
					templateUrl : Bridestory.default_url + '/popup/sudden/enjoyed',
					windowClass : 'popup',
					scope : $scope
				});

        modal.opened.then(function (data) {
          AnalyticService.pageTrackVirtual('popup/sudden/enjoyed', 'GA');
        });
			break;
		}*/
		if(count > 9) {
			// modal = $modal.open({
			// 	templateUrl : 'modal-authenticate-user.html',
			// 	windowClass : 'popup',
			// 	scope : $scope,
			// 	backdrop : 'static',
			// 	keyboard : false
			// });

   //    modal.opened.then(function (data) {
   //      AnalyticService.pageTrackVirtual('popup/sudden/login-to-continue', 'GA');
   //    });
			LoginService.openLogin();
		}
	}

	$scope.$on('PageCounter.increased',function(){
		checkCount(pageCounterFactory.count());
	})

	checkCount(pageCounterFactory.count());
}]);