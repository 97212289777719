function freetextVendorSearchController($scope, vendorSearchByServices, $filter) {
  this.vendors = [];
  this.currentPage = 2;
  var self = this;

  this.init = function (keywords) {
    self.keywords = keywords;
  };

  this.search = function () {
    vendorSearchByServices.searchByName(self.keywords, 1);
  };

  this.nextPage = function() {
    if(self.isLoadingNextPage || self.isEndOfPage) return;

    self.isLoadingNextPage = true;
    vendorSearchByServices.searchByName(self.keywords, self.currentPage++)
      .success(function(response) {
        if(response.vendors.length === 0) self.isEndOfPage = true;
        self.vendors = self.vendors.concat(response.vendors);

        angular.forEach(self.vendors, function (vendor) {
          vendor.rating = angular.isDefined(vendor.reviewsSummary) ? $filter('roundRate')(vendor.reviewsSummary.data.averageRating) : 0;
        });
        
        self.isLoadingNextPage = false;
      })
      .error(function(){
        self.isLoadingNextPage = false;
      });

  };
}

angular.module('bridestoryApp.vendorSearchApp')
  .controller('freetextVendorSearchController', ['$scope', 'vendorSearchByServices', '$filter', freetextVendorSearchController]);