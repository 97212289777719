function AutoCompleteController ($scope, autoCompleteServices) {
	var self = this;

	self.searchVendors = function(name) {
		return autoCompleteServices.searchVendor(name).then(function(result) {
			return result;
		});
	}

	self.onSelectVendor = function(item, model, label, element)
	{
		if (angular.isDefined(item)) {
			$scope.vendorSelected = label;
			// window.location.href = item.url;
		}else{
			var form_id = jQuery(element).closest('form').attr('id');
			document.getElementById(form_id).submit();
		}
	};

}

angular
	.module('bridestoryApp.autoCompleteApp')
	.controller('AutoCompleteController', ['$scope', 'autoCompleteServices', AutoCompleteController]);