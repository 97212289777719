angular
  .module('bridestoryApp.pageCounterApp')
  .controller('pageCounterController', ['pageCounterFactory', '$scope', function (pageCounterFactory, scope) {
    this.count = function () {
      return pageCounterFactory.count();
    };

    this.addCount = function () {
      // return pageCounterFactory.addCount();
    };

    this.setCount = function (number) {
      return pageCounterFactory.setCount(number);
    };

    scope.$on("logout", function (event, args) {
  		pageCounterFactory.removeCount();
  	});

    scope.$on("login_popup", function (event, args) {
      pageCounterFactory.removeCount();
    });

  }]);