function AnalyticFactoryGAdwords() {
	return {
		trackGoogleAdwords: function() {
			jQuery("body").append("<iframe height='1' width='1' src='http://"+location.host+"/tracking-adwords' style='display:none;'></iframe>");
		},
	    trackFacebookAds : function () {
	    	if (Bridestory.localized_bucket == 'AU') {
				var imageBridestoryAU = new Image(1, 1);
					imageBridestoryAU.src = 'https://www.facebook.com/tr?ev=6027182698465&cd[value]=0.00&cd[currency]=USD&noscript=1';

				var image = new Image(1, 1);
					image.src = 'https://www.facebook.com/tr?ev=6019801259703&cd[value]=0.00&cd[currency]=USD&noscript=1';
			} else if (Bridestory.localized_bucket == 'MY') {
				var imageBridestoryMY = new Image(1, 1);
					imageBridestoryMY.src = 'https://www.facebook.com/tr?ev=6026397345446&cd[value]=0.00&cd[currency]=USD&noscript=1';
			
				var image = new Image(1, 1);
					image.src = 'https://www.facebook.com/tr?ev=6019801259703&cd[value]=0.00&cd[currency]=USD&noscript=1';
			} else if (Bridestory.localized_bucket == 'PH') {
				var imageBridestoryPH = new Image(1, 1);
					imageBridestoryPH.src = 'https://www.facebook.com/tr?ev=6025532527633&cd[value]=0.00&cd[currency]=USD&noscript=1';
			} else if (Bridestory.localized_bucket == 'SG') {
				var imageBridestorySG = new Image(1, 1);
					imageBridestorySG.src = 'https://www.facebook.com/tr?ev=6035412215196&cd[value]=0.00&cd[currency]=USD&noscript=1';
			} else {
				var image = new Image(1, 1);
					image.src = 'https://www.facebook.com/tr?ev=6019801259703&cd[value]=0.00&cd[currency]=USD&noscript=1';
				
				var imageBridestoryAU = new Image(1, 1);
					imageBridestoryAU.src = 'https://www.facebook.com/tr?ev=6027182698465&cd[value]=0.00&cd[currency]=USD&noscript=1';
				var imageBridestoryMY = new Image(1, 1);
					imageBridestoryMY.src = 'https://www.facebook.com/tr?ev=6026397345446&cd[value]=0.00&cd[currency]=USD&noscript=1';
			}
	    },
	    trackYahooConversion : function () {
	    	jQuery("body").append("<iframe height='1' width='1' src='http://"+location.host+"/tracking-yahoo' style='display:none;'></iframe>");
	    },
	    trackSociomanticConversion : function () {
	    	if (typeof(sociomantic) != "undefined" && sociomantic != null) {
		    	sociomantic.sonar.adv['bridestory-id'].clear();
		        window.lead = {
		            transaction : new Date().getTime().toString() // unique lead Id on each click        
		        };
			    sociomantic.sonar.adv['bridestory-id'].track();
			}
	    }
	}
}

angular
	.module('bridestoryApp.analyticApp')
	.factory('AnalyticFactoryGAdwords', [ AnalyticFactoryGAdwords ]);